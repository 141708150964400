import { Route } from "vue-router";
import { postTrackingCode } from "../../api/func";
import withRouterBase from "../../shared/lib/url/withRouterBase";
import { TRACKING_CODE_NAME } from "../../shared/utils/consts";

/**
 * 链接追踪，记录 pv。
 */
export default function (to: Route) {
  const params = to.query || {};
  if (!params[TRACKING_CODE_NAME]) {
    return;
  }

  let vueFullPath = withRouterBase(to.fullPath);
  if (vueFullPath[0] !== "/") {
    vueFullPath = `/${vueFullPath}`;
  }

  const L = window.location;

  const fullUrl = `${L.protocol}//${L.host}${vueFullPath}`;

  postTrackingCode(params[TRACKING_CODE_NAME] as string, fullUrl);
}
