//
import lang from "@dqjs/libvue2/lib/i18n/index/index";
import Qxjs from "@dqjs/libvue2/lib/index";
import Vue from "vue";

Vue.use(Qxjs);

const qxjs = new Qxjs({
  icons: {
    type: "unicode",
    prefix: "icon-",
    values: {
      zuojiantou: "&#xe64a;",
      // arrow down.
      hricon39: "&#xe646;",
      // arrow up.
      hricon40: "&#xe645;",
      gou: "&#xe63c;",
      qx_rili: "qx_rili",
      search: "&#xe649;",
      cancelCircleFill: "&#xe653;",
      rightArrow: "&#xe64b;",
    },
  },
  rem: {
    rootFontSize: 14,
  },
  lang: {
    locales: lang,
    current: "zh",
  },
});

export default qxjs;
