<template>
  <float-menu
    :left="left"
    :top="top"
    :position="position"
    :align-target="alignTarget"
    :distance="distance"
    :bottom="bottom"
    :z-index="zIndex"
  >
    <div class="MobotFloatMenu" @click.stop.prevent="onClick">
      <div class="MobotFloatMenu__img"></div>
    </div>
  </float-menu>
</template>

<script>
import FloatMenu from "@dqjs/libvue2/lib/components/FloatMenu/FloatMenu";

/**
 * 纯组件。
 *
 * @emits click - 点击时触发。
 */
export default {
  components: {
    FloatMenu,
  },

  props: {
    left: [String, Number],
    position: [String, Number],
    bottom: [String, Number],
    alignTarget: String,
    top: [String, Number],
    distance: Number,
    zIndex: [String, Number],
  },

  data() {
    return {};
  },

  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/helper";
@import "@/styles/modules/mobot-image-bg";

@include block(MobotFloatMenu) {
  width: rem(45px);
  height: rem(45px);
  position: relative;
  border: rem(0px) solid rgba(54, 54, 71, 0.1);
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 2px 2px rgba(120, 126, 133, 0.23);

  @include element("img") {
    @extend %mobot-image-bg;
  }
}
</style>
