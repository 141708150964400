import http, { unwrap } from "@/shared/http";
import boolcheck from "@/shared/utils/boolcheck";
import { cross, getWechatId, urlWithoutCross } from "@/shared/utils/url";
import changeQuery from "@dqjs/libcore/lib/changeQuery";
import assign from "lodash/assign";
import {
  ApiSceneArg,
  PHONE_BIND_REASON,
  WechatQrcodeInfo,
} from "../shared/models/app";
import { SmsCodeItem } from "../shared/models/option";
import { LooseObject } from "../shared/types/value.t";
import { TRACKING_CODE_NAME } from "../shared/utils/consts";
import { PrivacyData } from "../store/privacyModule/model";
import getXsrf from "@/shared/utils/getXsrf";
import getConfig from "@/shared/utils/getConfig";

export const API_REGISTER_CODE = cross("/api/cellphone/register");
export const IS_MOBILE_BINDED = cross("/api/mobilebinded");
export const CAPTCHA_URL = cross("/api/captcha");
export const API_DICT_SMS_COUNTRY_CODE = cross("/api/dict/smscountrycode");
const API_VOICE_REG = cross("/api/cellphone/voice_register");
const CHECK_MOBILE_STATUS = cross("/api/cellphone/account_status");
const API_WECHAT = cross("/api/func/wechat");
const API_WECHAT_QX = cross("/api/func/qx/wechat");
const API_SWITCH_POPUP = cross("/api/switch/popup_window");
const SUBSCRIBE_STATUS = cross("refresh/subscribe/status");
const API_AGREE_PRIVACY_POLICY = cross("/api/privacy/agree");
const API_CHECK_AGREE_PRIVACY = cross("/api/privacy/is_agree");
const API_ACTION_PREPARE = cross("/api/action/prepare");
const API_SHORT_LINK = cross("/api/short_link");
const API_PROFILE_STATUS = cross("/api/profile/completeness");
const API_TRACKING_CODE = cross("/api/tracking/record");
const API_MOBOT_ENTRY_SWITCH = cross("/api/mobot/multipage/switch");
const API_TEAM_CASCADE_DATA = cross("/api/v2/search/depart");
export const API_ATTACHMENT_UPLOAD = cross("/api/profile/attachment/upload");

export async function fetchMobotEntrySwitch() {
  try {
    const { data } = await unwrap<{
      mobot_multi_page_switch: boolean;
      mobot_url?: string;
    }>(http.get(API_MOBOT_ENTRY_SWITCH));
    return data || {};
  } catch (err) {
    return {
      mobot_multi_page_switch: false,
    };
  }
}

/**
 * 操作前发送此接口，后端回做一些事情。如果返回200，则操作正常执行。
 */
export async function doActionPrepare(args?: any & Partial<ApiSceneArg>) {
  return await unwrap<any>(
    http.get(API_ACTION_PREPARE, {
      params: args,
    }),
  );
}

/**
 * 当前用户的手机号是否已绑定.
 */
export async function isCurrentUserMobileBinded() {
  const mobileVerfied = getConfig("user.mobileVerfied");
  if (typeof mobileVerfied === "boolean") {
    return mobileVerfied;
  }

  const serverData = await unwrap<boolean>(http.post(IS_MOBILE_BINDED));
  const data = serverData.data;
  return boolcheck.t(data);
}

export function genCaptchaImageUrl(): string {
  return urlWithoutCross(CAPTCHA_URL, {
    ts: new Date().getTime(),
  });
}

export async function fetchSmsCountryCodes() {
  const serverData = await unwrap<SmsCodeItem[]>(
    http.get(API_DICT_SMS_COUNTRY_CODE),
  );
  return serverData.data || [];
}

export async function fetchVoiceRegister(data: LooseObject) {
  return await unwrap(
    http.get(API_VOICE_REG, {
      params: data,
    }),
  );
}

export async function checkMobileStatus(args: LooseObject) {
  return await unwrap<PHONE_BIND_REASON>(
    http.get(CHECK_MOBILE_STATUS, {
      params: args,
    }),
  );
}

export async function fetchQxWechat(args: {
  str_code: string | number;
  [key: string]: any;
}) {
  const { data } = await unwrap<WechatQrcodeInfo>(
    http.get(API_WECHAT_QX, {
      params: args,
    }),
  );
  return data;
}

/**
 * 获取仟寻的公众号.
 */
export async function fetchQxWechatInPage(
  pageId: string | number,
  args?: Record<string, any>,
) {
  return fetchQxWechat({
    ...args,
    str_code: pageId,
  });
}

export async function fetchWechat(scene?: string | number, params?: object) {
  if (typeof scene != "number" && typeof scene !== "string") {
    // 场景默认值.
    scene = 99;
  }

  let data = assign(
    {
      scene,
    },
    params,
  );

  return await unwrap<WechatQrcodeInfo>(
    http.get(API_WECHAT, {
      params: data,
    }),
  );
}

/**
 * 保存用户对jd弹窗的配置.
 */
export async function postSwitchPopup(args: any) {
  return await http.post(API_SWITCH_POPUP, args);
}

export async function getFollowStatus() {
  return await unwrap<{ is_subscribe: 0 | 1 | boolean }>(
    http.put(SUBSCRIBE_STATUS),
  );
}

export async function fetchPrivacyData() {
  return await unwrap<PrivacyData>(
    http.get(API_CHECK_AGREE_PRIVACY, {
      params: {
        _ts: new Date().getTime(),
      },
    }),
  );
}

export async function agreePrivacyPolicy(
  agree = 1 as number,
  fieldName: string,
) {
  return await http.post(API_AGREE_PRIVACY_POLICY, {
    [fieldName]: agree,
  });
}

/**
 * 返回短链接的id.
 */
export async function shortLinkId(url: string, args: any) {
  if (!url) {
    throw new Error("invalid url");
  }

  const sd = await unwrap<string>(
    http.post(API_SHORT_LINK, {
      ...args,
      url: url,
    }),
  );

  return sd.data;
}

/**
 * 返回短链接的绝对地址.
 */
export async function shortLink(url: string, args: any) {
  const linkId = await shortLinkId(url, args);

  const location = window.location;
  const host = location.host;
  const protocol = location.protocol;

  const origin = `${protocol}//${host}`;
  let newUrl = cross(`/sl/${linkId}`);

  let absLink = origin + (newUrl[0] === "/" ? newUrl : "/" + newUrl);

  // add wechat_signature
  return changeQuery(absLink, {
    wechat_signature: getWechatId(),
  });
}

/**
 * 获取用户简历状态，完整度（0-100），是否存在简历。
 */
export async function fetchProfileState() {
  try {
    const sd = await unwrap<{
      exist: boolean;
      value: number;
    }>(http.get(API_PROFILE_STATUS));

    return sd.data;
  } catch (err) {
    return {
      exist: false,
      value: 0,
    };
  }
}

export async function postTrackingCode(code: string, url: string) {
  return await http.post(API_TRACKING_CODE, {
    track_url: url,
    [TRACKING_CODE_NAME]: code,
  });
}

export async function uploadFile(fileData: { data: string; fileName: string }) {
  const form = new FormData();
  form.append("vfile", fileData.data);
  form.append("_xsrf", getXsrf());
  form.append("source", "1");
  form.append("scene_id", "4");
  form.append("filename", fileData.fileName);

  const sd = await unwrap<{
    url: string;
  }>(http.post(API_ATTACHMENT_UPLOAD, form));

  return sd.data.url;
}

export async function fetchTeamList(parentCode?: string | number) {
  try {
    const sd = await unwrap<
      Array<{
        id: number;
        is_parent: boolean;
        department_code: number | string;
        name: string;
      }>
    >(
      http.get(API_TEAM_CASCADE_DATA, {
        params: {
          parent_id: parentCode,
        },
      }),
    );

    return sd.data;
  } catch (err) {
    return [];
  }
}
