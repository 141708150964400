import { MutationTree } from "vuex";
import types from "../mut-types";
import { UserState } from "./model";

const mutations: MutationTree<UserState> = {
  [types.SET_GLOBAL](state, payload = {}) {
    state.account = payload.account || {};
    state.wechat = payload.wechat || {};
    state.lastGlobalUpdate = new Date().getTime();
  },
  [types.SET_COMPANY](state, payload) {
    state.company = {
      ...payload,
      scaleName: payload.scale_name,
      stale: true,
    };
  },
};

export default mutations;
