import types from "../mut-types";
import { CommentState } from "./model";
import State from "../State";
import { fetchComments } from "../../api/comment";

import { ActionContext } from "vuex";

type AC = ActionContext<CommentState, State>;

/**
 * careestory页面-精选、全部、参与留言列表
 */
export async function fetchMoreComments({ state, commit }: AC, args: object) {
  commit(types.COMMENT_SET_LOADING, true);

  try {
    const query = { ...args, page_no: state.pageNo, page_size: state.pageSize };
    const serverData = await fetchComments(query);
    const list = serverData.get("list", []) as [];
    const total = serverData.get("total", 0) as number;
    const prevPageNo = state.pageNo;
    const totalPages = Math.ceil(total / state.pageSize);

    commit(types.COMMENT_SET_LOADING, false);

    if (!list.length || prevPageNo > totalPages) {
      commit(types.COMMENT_SET_EXHUASTED, true);
    }

    if (!list.length) {
      return;
    }

    commit(types.COMMENT_INC_PAGENO);
    commit(types.COMMENT_SET_LIST, list);
    commit(types.COMMENT_SET_TOTAL, total);
  } catch (Err) {
    commit(types.COMMENT_SET_LOADING, false);
    throw Err;
  }
}

export async function reloadCommentList(
  { commit, dispatch }: AC,
  args: object,
) {
  commit(types.COMMENT_RESET);
  await dispatch("fetchMoreComments", args);
}

export function updateCommentList({ commit }: AC, list: []) {
  commit(types.COMMENT_UPDATE_LIST, list);
}

/**
 * 写留言弹框-我的留言列表
 */
// export async function fetchMoreMyComments({ state, commit }: AC, args: object) {
//   commit(types.COMMENT_SET_MYLOADING, true);

//   try {
//     const query = {
//       ...args,
//       page_no: state.myPageNo,
//       page_size: state.myPageSize,
//     };
//     const serverData = await fetchComments(query);
//     const list = serverData.get("list", []) as [];
//     const total = serverData.get("total", 0) as number;
//     const prevPageNo = state.myPageNo;
//     const totalPages = Math.ceil(total / state.myPageSize);

//     commit(types.COMMENT_SET_MYLOADING, false);

//     if (!list.length || prevPageNo > totalPages) {
//       commit(types.COMMENT_SET_MYEXHUASTED, true);
//     }

//     if (!list.length) {
//       return;
//     }

//     commit(types.COMMENT_INC_MYPAGENO);
//     commit(types.COMMENT_SET_MYLIST, list);
//     commit(types.COMMENT_SET_MYTOTAL, total);
//   } catch (Err) {
//     commit(types.COMMENT_SET_MYLOADING, false);
//     throw Err;
//   }
// }

// export async function reloadMyCommentList(
//   { commit, dispatch }: AC,
//   args: object,
// ) {
//   commit(types.MY_COMMENT_RESET);
//   await dispatch("fetchMoreMyComments", args);
// }

// export function updateMyCommentList({ commit }: AC, myList: []) {
//   commit(types.COMMENT_UPDATE_MYLIST, myList);
// }
