import { isWeixinMemo } from "@/shared/utils/isWeixin";
import sentry from "@/shared/utils/sentry";
import url from "@/shared/utils/url";
import store from "@/store";
import get from "lodash/get";
import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/position",
    component: () =>
      import(/* webpackChunkName: "JobIndex" */ "@/views/JobIndex"),
    meta: {
      tabbar: true,
      auth: false,
    },
    children: [
      {
        path: "",
        name: "job.home",
        component: () =>
          import(
            /* webpackChunkName: "JobIndexList" */ "@/views/JobIndex/components/JobIndexList.vue"
          ),
        meta: {
          tabbar: true,
          auth: isWeixinMemo(),
          scrollBehavior: "jobindex",
          privacy: false,
        },
      },
      {
        path: "page/recommend",
        name: "job.airecommend",
        component: () =>
          import(
            /* webpackChunkName: "JobRecommend" */ "@/views/JobIndex/components/JobRecomList.vue"
          ),
        meta: {
          description: "智能推荐",
          tabbar: true,
          /** see INDEX_TAB */
          tabkey: 2,
          scrollBehavior: "jobindex",
          auth: isWeixinMemo(),
        },
      },
      {
        path: "page/internal",
        name: "job.internal",
        component: () =>
          import(
            /* webpackChunkName: 'JobInternal' */ "@/views/JobIndex/components/JobInternalList.vue"
          ),
        meta: {
          tabbar: true,
          /** see INDEX_TAB */
          tabkey: 4,
          scrollBehavior: "jobindex",
        },
      },
    ],
  },

  {
    path: "/position/search",
    name: "job.search",
    component: () =>
      import(
        /* webpackChunkName: "JobSearch" */ "@/views/common/JobSearch.vue"
      ),
    meta: {
      auth: false,
    },
  },
  {
    path: "/position/share",
    name: "job.bulkShare",
    component: () =>
      import(/* webpackChunkName: "BulkShare" */ "@/views/JobBulkShare"),
    meta: {
      auth: false,
    },
  },

  {
    path: "/position/recom",
    name: "job.recom",
    component: () =>
      import(/* webpackChunkName: 'JobRecom' */ "@/views/JobRecom"),
    meta: {
      scrollBehavior: "jobrecom",
    },
    beforeEnter(to, from, next) {
      const isEmployee = get(
        store.state,
        "appModule.pageconst.user.isEmployee",
      );

      if (typeof isEmployee !== "boolean") {
        sentry.captureMessage("isEmployee is not boolean value");
      }

      if (isEmployee) {
        return next();
      }
      window.location.href = url("/employee/bind");
    },
  },

  {
    path: "/position/:id",
    name: "job.detail",
    component: () =>
      import(/* webpackChunkName: "JobDetail" */ "@/views/JobDetail"),
    meta: {
      auth: isWeixinMemo(),
      privacy: false,
    },
  },
];

export default routes;
