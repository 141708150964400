import { fetchLandingPage } from "@/api/page";
import types from "../mut-types";
import { PageState } from "./model";
import State from "../State";
import { ActionContext } from "vuex";

type AC = ActionContext<PageState, State>;

export async function getLandingPage({ commit, state }: AC) {
  if (state.landing.stale) {
    return state.landing;
  }

  const { data } = await fetchLandingPage();
  commit(types.PAGE_SET_LANDING_DATA, data);
}
