import getHtmlMetaValue from "../lib/dom/getHtmlMetaValue";
import { isTest } from "./env";

let cache = null as string | null;

export default function () {
  if (cache) {
    return cache;
  }

  if (isTest) {
    return "__test__";
  }

  cache = getHtmlMetaValue("wechat-signature") || "";

  return cache;
}
