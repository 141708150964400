/**
 * @description
 *
 * 每页都要检查协议的逻辑。
 * 如果没同意自定义协议，则显示自定义协议。
 * 如果没有同意仟寻新协议，则显示仟寻新的协议。
 * 如果没同意自定义个人信息保护协议，则不能进入到认证等关键页面。
 */

import { ActionContext } from "vuex";
import { agreePrivacyPolicy, fetchPrivacyData } from "../../api/func";
import { USER_MODULE, isDev } from "../../shared/utils/consts";
import State from "../State";
import types from "../mut-types";
import {
  CUSTOM_PRIVACY_AGREED_VAL,
  CUSTOM_PRIVACY_POPUP_KEY,
  PRIVACY_AGREED_VAL,
  PRIVACY_ALL_POPUP_KEY,
  PrivacyState,
  PrivacyVisibleMode,
  QX_PRIVACY_POPUP_KEY,
} from "./model";

type AC = ActionContext<PrivacyState, State>;
/**
 * 兼容 buck 项目记录的 key.
 */
const AGREE_POLICY_TS_KEY = "_lspts";

export async function getPrivacy({ commit }: AC) {
  const sd = await fetchPrivacyData();
  commit(types.PRIVACY_SET_DATA, sd.data);

  return sd.data;
}

export function isPrivacyIn24HoursOld() {
  let v = localStorage.getItem(AGREE_POLICY_TS_KEY) as string | number | null;
  if (!v) {
    return false;
  }

  v = parseInt(v as string, 10);
  if (!v) {
    return false;
  }

  let now = new Date().getTime();

  let passedHrs = (now - v) / 1000 / 60 / 60;

  if (passedHrs > 24 || passedHrs < 0) {
    return false;
  }

  return true;
}

export function isPrivacyIn24Hours({ state }: AC): boolean {
  const lastTs = Number(state.lastTs);

  if (!lastTs || isDev) {
    return isPrivacyIn24HoursOld();
  }

  const now = new Date().getTime();
  const passedHrs = (now - lastTs) / 1000 / 60 / 60;
  if (passedHrs > 24 || passedHrs < 0) {
    return isPrivacyIn24HoursOld();
  }
  return true;
}

/**
 * 在路由控制器里，调用此方法进行检查，然后弹窗.
 * 产品逻辑是，在一次操作中先检查自定义协议，然后在下次操作
 * 中检查仟寻协议.
 * make sure call
 */
export async function runPrivacyCheck(
  { getters, dispatch, commit, rootGetters, state }: AC,
  payload = {} as { force?: boolean },
) {
  payload = Object.assign({}, payload);

  if (state.privacyData.stale !== true) {
    await dispatch("getPrivacy");
  }

  const isQxPrivacyChecked = getters.isQxPrivacyChecked;
  const isCustomPrivacyChecked = getters.isCustomPrivacyChecked;
  const loggin = rootGetters[`${USER_MODULE}/hasLogin`];

  // If all is agreed, then return ok.
  if ((isQxPrivacyChecked && isCustomPrivacyChecked) || !loggin) {
    return;
  }

  const isIn24Hours = await dispatch("isPrivacyIn24Hours");
  if (isIn24Hours && !payload.force) {
    // 不弹窗，24小时后再弹窗.
    return;
  }

  try {
    return await dispatch("runPrivacyCheck_");
  } catch (err) {
    // record last disagree ts.
    const ts = new Date().getTime();
    localStorage.setItem(AGREE_POLICY_TS_KEY, ts + "");
    commit(types.PRIVACY_SET_LASTTS, ts);
    throw err;
  }
}

export async function runPrivacyCheck_({ commit, getters }: AC) {
  const mode = getters.privacyMode;
  const isCustomPrivacyChecked = getters.isCustomPrivacyChecked;
  const isQxPrivacyChecked = getters.isQxPrivacyChecked;

  if (mode === PrivacyVisibleMode.CUSTOM_ONLY && isCustomPrivacyChecked) {
    return;
  }
  if (mode === PrivacyVisibleMode.QX_ONLY && isQxPrivacyChecked) {
    return;
  }

  return new Promise((resolve, reject) => {
    commit(
      types.PRIVACY_TOGGLE_POPUP,
      togglePrivacyPopupPayload(PRIVACY_ALL_POPUP_KEY, true, (err) => {
        if (err) {
          return reject(err);
        }

        return resolve(undefined);
      }),
    );
  });
}

function togglePrivacyPopupPayload(
  key: string,
  value: boolean,
  callback?: (err: Error) => void,
) {
  return {
    key: key,
    value: value,
    callback: callback,
  };
}

/**
 * Used by components to close the popup.
 */
export function togglePrivacyPopup(
  { commit }: AC,
  { key, value }: { key: string; value: boolean },
) {
  commit(types.PRIVACY_TOGGLE_POPUP, togglePrivacyPopupPayload(key, value));
}

export function removePrivacyPendingMode({ commit }: AC) {
  commit(types.PRIVACY_SET_PENDING_MODE, null);
}

/**
 * @deprecated
 */
export async function runCustomPrivacyCheck({ commit, getters }: AC) {
  if (getters.isCustomPrivacyChecked) {
    return;
  }

  return new Promise((resolve, reject) => {
    commit(
      types.PRIVACY_TOGGLE_POPUP,
      togglePrivacyPopupPayload(CUSTOM_PRIVACY_POPUP_KEY, true, (err) => {
        if (err) {
          return reject(err);
        }

        return resolve(undefined);
      }),
    );
  });
}

/**
 * @deprecated
 *
 * 运行仟寻个人信息保护协议检查.
 */
export async function runQxPrivacyCheck({ commit, getters }: AC) {
  if (getters.isQxPrivacyChecked) {
    return;
  }

  return new Promise((resolve, reject) => {
    commit(
      types.PRIVACY_TOGGLE_POPUP,
      togglePrivacyPopupPayload(QX_PRIVACY_POPUP_KEY, true, (err) => {
        if (err) {
          return reject(err);
        }

        return resolve(undefined);
      }),
    );
  });
}

export async function confirmPrivacy({ commit }: AC, mode: PrivacyVisibleMode) {
  const agree = 1;
  const pending: any[] = [];
  const isAll = mode === PrivacyVisibleMode.ALL;

  if (typeof mode !== "number") {
    throw new Error("invalid mode arg, expect number value, got: " + mode);
  }

  commit(types.PRIVACY_SET_PENDING_MODE, mode);

  if (mode === PrivacyVisibleMode.CUSTOM_ONLY || isAll) {
    pending.push(
      new Promise((r) => {
        agreePrivacyPolicy(agree, CUSTOM_PRIVACY_POPUP_KEY)
          .then(() => {
            commit(types.PRIVACY_UPDATE_AGREE, {
              key: CUSTOM_PRIVACY_POPUP_KEY,
              agree: CUSTOM_PRIVACY_AGREED_VAL,
            });
          })
          .then(r);
      }),
    );
  }
  if (mode === PrivacyVisibleMode.QX_ONLY || isAll) {
    pending.push(
      new Promise((r) => {
        agreePrivacyPolicy(agree, QX_PRIVACY_POPUP_KEY)
          .then(() => {
            commit(types.PRIVACY_UPDATE_AGREE, {
              key: QX_PRIVACY_POPUP_KEY,
              agree: PRIVACY_AGREED_VAL,
            });
          })
          .then(r);
      }),
    );
  }

  await Promise.all(pending);
}
