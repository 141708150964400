import { getSdkSignData } from "@/api/sdk";
import { WXWORK_SDK_URL, WX_SDK_URL } from "@/shared/utils/consts";
import events from "@/shared/utils/events";
import getConfig from "@/shared/utils/getConfig";
import importScript from "@dqjs/libcore/lib/importScript";
import { imageMogr2 } from "@dqjs/libcore/lib/qiniu";
import { isAndroid, isWxwork } from "@dqjs/libcore/lib/ua";
import Sdk from "@dqjs/qxjs/lib/modules/vuewx/Sdk";
import { SdkProviderCtor } from "@dqjs/qxjs/lib/modules/vuewx/util";
import { WX_MENU_SOCIAL } from "@dqjs/qxjs/lib/modules/vuewx/wechat";
import CoreProcessor from "@dqjs/qxjs/lib/modules/vuewx/wechat/processors/Core";
import ShareProcessor from "@dqjs/qxjs/lib/modules/vuewx/wechat/processors/Share";
import { WxShareData } from "../models/app";
import { SDK_ID } from "../utils/consts";

const wechatDirectiveProcessors = [new ShareProcessor(), new CoreProcessor()];

// this should get from template.
let sdkId = getConfig("sdkId");

export interface SdkDriver {
  is(type: SDK_ID): boolean;
  setEnvName(name: string): void;
}

export function mapShareData(
  data: WxShareData,
  options_?: {
    miniProgram?: boolean;
  },
): any {
  const options = options_ || {};
  switch (sdkId - 0) {
    case SDK_ID.JOYWOK:
      return {
        title: data.title,
        link: data.link,
      };
    default:
      return {
        title: data.title,
        link: data.link,
        imgUrl: imageMogr2(data.cover, "/size-limit/29k!"),
        desc: data.description,
        // post share info to mini app if inside miniapp.
        miniProgram: options.miniProgram
          ? {
              title: data.title,
              link: data.link,
              imgUrl: imageMogr2(data.cover, "/size-limit/29k!"),
            }
          : undefined,
      };
  }
}

const Driver = {
  is(type: SDK_ID, sdkId_?: SDK_ID) {
    sdkId_ = sdkId_ || sdkId;
    switch (sdkId_ + "") {
      case "1":
        return SDK_ID.WECHAT === type;
      case "3":
        return SDK_ID.JOYWOK === type;
      case "4":
        return SDK_ID.WXWORK === type;
      case "5":
        // mini app, use wechat
        return SDK_ID.WECHAT === type;
    }

    return false;
  },

  setSdkId(name: string | number) {
    sdkId = name + "";
  },
};

export default Driver;

export const sdk = new Sdk({
  onload(sdk: Sdk) {
    sdk.run("hideMenuItems", {
      menuList: WX_MENU_SOCIAL,
    });
    console.warn("hide option menu on load");
  },
  configUrlGetter() {
    const iosUrl = window.sessionStorage
      ? window.sessionStorage.getItem("iosPageUrl")
      : null;
    if (iosUrl && !isAndroid && !isWxwork) {
      return iosUrl;
    }

    return window.location.href;
  },
  configGetter(targetUrl: string): Promise<any> {
    return getSdkSignData(
      {
        target: targetUrl,
      },
      sdkId,
    ).catch((err) => {
      events.$emit("vuewx/directive/share-data-fail", err);
      throw err;
    });
  },
  async importSdk() {
    const sdkUrl = Driver.is(SDK_ID.WXWORK) ? WXWORK_SDK_URL : WX_SDK_URL;
    return importScript(sdkUrl).then(() => {
      return import(
        "@dqjs/qxjs/lib/modules/vuewx/wechat/WechatSdk"
      ) as unknown as SdkProviderCtor<unknown>;
    });
  },
  directiveProcessors: wechatDirectiveProcessors,
  events: events,
});
