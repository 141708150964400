import { MutationTree } from "vuex";
import types from "../mut-types";
import {
  CUSTOM_PRIVACY_POPUP_KEY,
  PrivacyState,
  PrivacyVisibleMode,
  QX_PRIVACY_POPUP_KEY,
} from "./model";

const mutations: MutationTree<PrivacyState> = {
  [types.PRIVACY_SET_DATA](state, payload) {
    state.privacyData = {
      ...payload,
      custom_privacy: {
        ...state.privacyData.custom_privacy,
        ...payload.custom_privacy,
      },
      stale: true,
    };
  },
  [types.PRIVACY_SET_PENDING_MODE](state, payload: PrivacyVisibleMode | null) {
    state.pendingMode = payload;
  },
  [types.PRIVACY_TOGGLE_POPUP](
    state,
    payload: { key: string; value: boolean; callback?: (err?: Error) => void },
  ) {
    state.popup = {
      key: payload.key,
      visible: payload.value,
      callback: payload.callback || null,
    };
  },
  [types.PRIVACY_SET_LASTTS](state, payload) {
    state.lastTs = payload;
  },
  [types.PRIVACY_UPDATE_AGREE](state, payload: { key: string; agree: number }) {
    const { key, agree } = payload;

    if (key === QX_PRIVACY_POPUP_KEY) {
      state.privacyData.qx_privacy = agree;
    } else if (key === CUSTOM_PRIVACY_POPUP_KEY) {
      state.privacyData.custom_privacy.agree = agree;
    }
  },
};

export default mutations;
