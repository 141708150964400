import { Module } from "vuex";
import State from "../State";
import * as actions from "./actions";
import getters from "./getters";
import { AppState } from "./model";
import mutations from "./mutations";

const appModule: Module<AppState, State> = {
  namespaced: true,

  state: {
    dialog: {
      type: null,
      ts: 0,
      options: null,
      resolve: null,
      reject: null,
    },
    isTabbarHide: false,
    nextUrl: "",
    locks: {},
    switchs: {
      aiRecom: null,
      poster: null,
      radar: null,
      chat: null,
      showChat: null,
    },
    pageconst: {
      wechatId: "",
      cid: "",
      cname: "",
      clogo: "",
      user: {
        id: "",
        nickname: "",
        name: "",
        headimg: "",
        bindAgentName: "",
        isEmployee: false,
        employeeId: 0,
      },
      sdkId: "",
      wechatSignature: "",
      switch: {
        salaryDisplay: true,
        employeeReferral: true,
        hideTeam: false,
        internalJob: false,
        empCanApplyPublicJob: true,
        mapWxSdkUseFreely: false,
      },
      isv2: true,
    },
    smsCountryCodes: [],
    lbsOn: null,
    routeHistory: [],
    modalStore: {
      id: 1,
      list: [],
    },
    isMiniApp: false,
  },
  mutations,
  actions,
  getters,
};

export default appModule;
