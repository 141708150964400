import SafeArea from "@/components/foundation/SafeArea.vue";
import { env } from "@/shared/utils/env";
import { registerFilters } from "@/shared/vue/filters";
import gaPlug from "@/shared/vue/plugins/gaPlug";
import qxjs from "@/shared/vue/plugins/qxjs";
import useUrlPlugin from "@/shared/vue/plugins/urlPlug";
import installVuewx from "@/shared/vue/plugins/vuewx";
import registerGlobalComponents from "@/shared/vue/registerGlobalComponents";
// import setupSentry from "@/shared/vue/setupSentry";
import "@/styles/common.scss";
// styles.
import "@/styles/tailwind.css";
import { isWxwork } from "@dqjs/libcore/lib/ua";
import PortalVue from "portal-vue";
import Vue from "vue";
import VueMeta from "vue-meta";
import { sync } from "vuex-router-sync";
import App from "./App.vue";
import registerVeeValidate from "./registerVeeValidate";
import router from "./router";
import client from "./shared/http";
import configHttpInterceptor from "./shared/http/interceptors";
import i18n from "./shared/i18n";
import store from "./store";

Vue.config.devtools = process.env.NODE_ENV === "development";

// setup the interceptor for the Axios instance.
configHttpInterceptor(client);
registerFilters(Vue);
registerGlobalComponents(Vue);
registerVeeValidate(i18n);
installVuewx(Vue);
useUrlPlugin(Vue);
// ga make wechat load very slow, even with defer/async.
gaPlug(Vue, router, "UA-75507547-1", isWxwork || true);
// setupSentry(Vue, router);

/// setup third-party
// 用于单页应用 title, meta 的管理, https://vue-meta.nuxtjs.org/
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(PortalVue);
// sync route to vuex.
const unsync = sync(store, router, { moduleName: "routeModule" });
Vue.component("safe-area", SafeArea);

new Vue({
  i18n,
  qxjs,
  router,
  store,
  beforeDestroy() {
    unsync();
  },
  render: (h) => h(App),
}).$mount("#app");

// log the current version.
setTimeout(() => {
  // eslint-disable-next-line
  console.log(`>>>>>>>>>>> version:`);
  console.log(`>>>> ${env.APP_VERSION}`);
}, 1000);
