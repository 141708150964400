//
import { mapHandle, runOnMap } from "@/shared/utils/tencent-map";
import assign from "lodash/assign";
import { ActionContext } from "vuex";
import State from "../State";
import types from "../mut-types";
import { MapState } from "./model";

type AC = ActionContext<MapState, State>;

export const fetchDistance = async (
  _context: AC,
  { lhsPosition, rhsPosition }: { lhsPosition: any; rhsPosition: any },
) => {
  let v = await runOnMap(
    "getMapDistance",
    {
      lhsCoord: lhsPosition,
      rhsCoord: rhsPosition,
    },
    mapHandle,
  );
  return v;
};

export const getCurrentLocation = async (
  { commit }: AC,
  options: {
    /**
     * 获取全部详尽的信息.
     */
    full?: boolean;
  },
) => {
  options = assign(
    {
      full: false,
    },
    options,
  );

  const commitFail = () => {
    commit(types.SET_FECTH_LOCATION_FAIL, true);
    commit(types.SET_FETCHING_CURRENT_LOCATION, false);
  };

  commit(types.SET_FECTH_LOCATION_FAIL, false);
  commit(types.SET_FETCHING_CURRENT_LOCATION, true);

  try {
    const partialResult = await runOnMap(
      "getCurrentLocation",
      options,
      mapHandle,
    );
    commit(types.SET_CURRENT_LOCATION, partialResult);
    commit(types.SET_USER_CURRENT_LOCATION, partialResult);
    commit(types.SET_FETCHING_CURRENT_LOCATION, false);
  } catch (err) {
    commitFail();
    throw err;
  }
};

/**
 * Update current location from city name.
 */
export const updateCurrentLocationByCityText = async (
  { commit, state }: AC,
  options: {
    address?: string;
    city: string;
  },
) => {
  const commitFail = () => {
    commit(types.SET_FECTH_LOCATION_FAIL, true);
    commit(types.SET_FETCHING_CURRENT_LOCATION, false);
  };

  commit(types.SET_FECTH_LOCATION_FAIL, false);
  commit(types.SET_FETCHING_CURRENT_LOCATION, true);

  if (
    !options.address &&
    isCityEqualCurrentCity(options.city, {
      province: state.currentLocation?.addressComponent?.province,
      city: state.currentLocation?.addressComponent?.city,
      address: state.currentLocation?.address,
    }) &&
    state.userCurrentLocation.coord?.lat !== 0
  ) {
    // eslint-disable-next-line
    console.log("⚒ use user location bc city is same");
    // get location by user location.
    commit(types.SET_CURRENT_LOCATION, state.userCurrentLocation);
    commit(types.SET_FETCHING_CURRENT_LOCATION, false);

    return Promise.resolve();
  }

  try {
    const res = await runOnMap("getLocationByAddress", options, mapHandle);
    commit(types.SET_CURRENT_LOCATION, res);
  } catch (err) {
    commitFail();
    throw err;
  }
};

const CITY_SUFFIX = "市";

/**
 * 给定一个城市名，判断是否是当前用户的城市.
 */
function isCityEqualCurrentCity(
  cityText: string,
  addressStruct: {
    address?: string;
    city?: string;
    province?: string;
  },
) {
  cityText = cityText || "";
  if (!cityText) {
    return false;
  }

  let cityName =
    cityText[cityText.length - 1] == CITY_SUFFIX
      ? cityText.slice(0, cityText.length - 1)
      : cityText;

  let address = addressStruct.address || "";
  let city = addressStruct.city || "";
  let province = addressStruct.province;
  let compareText = `${address}-${city}-${province}`;

  if (compareText.indexOf(cityName) !== -1) {
    return true;
  }

  return false;
}
