import { Vue, Component, Watch } from "vue-property-decorator";
import { mapState } from "vuex";
import { APP_MODULE } from "../../../shared/utils/consts";
import { AppState } from "../../../store/appModule/model";

/**
 * 处理全局的alert，confirm，info等.
 */
@Component({
  computed: {
    ...mapState<AppState>(APP_MODULE, {
      ts: (state: AppState) => state.dialog.ts,
      options_: (state: AppState) => state.dialog.options,
      type: (state: AppState) => state.dialog.type,
      resolve: (state: AppState) => state.dialog.resolve,
      reject: (state: AppState) => state.dialog.reject,
    }),
  },
})
export default class Dialog extends Vue {
  isShown = false;

  type!: "confirm" | "alert";
  options_!: any;
  resolve!: (args: any) => void;
  reject!: (err?: Error) => void;

  @Watch("ts")
  onDialogStateChange(newVal: number) {
    setTimeout(() => {
      if (newVal === 0) {
        // close dialog.
        this.$dialog.close();
        this.isShown = false;
        return;
      }

      if (this.isShown) {
        this.$dialog.close();
      }

      const type = this.type;

      this.$dialog[type]({
        ...this.options_,
      }).then(
        (val: any) => {
          // on close
          this.isShown = false;
          this.resolve ? this.resolve(val) : null;
        },
        (err) => {
          this.isShown = false;
          this.reject && this.reject(err);
        },
      );
      this.isShown = true;
    }, 60);
  }

  render() {
    return null;
  }
}
