import OurVue from "vue";
import assign from "lodash/assign";
import url, {
  LocationFuncOptions,
  mergeQueryWithWhitelist,
  ParsedQuery,
  UrlOptions,
} from "@/shared/utils/url";
import { QxLocation } from "../../types/lib.t";

function install(Vue: typeof OurVue) {
  if ((install as any).installed) return;
  (install as any).installed = true;

  if (OurVue !== Vue) {
    // eslint-disable-next-line
    console.error("Multiple instances of Vue detected");
  }

  /**
   * @see `@/shared/utils/url.ts`
   */
  Vue.prototype.$url = function (
    path: string,
    query?: { [key: string]: string | number | undefined | null } | null,
    options?: UrlOptions,
  ): string {
    options = options || {};

    if (this.$route && this.$route.query) {
      options.queryGetter = (): ParsedQuery => {
        return this.$route.query;
      };
    }

    return url(path, query, options);
  };

  /**
   * @example
   *
   * ```
   * this.$router.push(this.$location({
   *  name: "job.index",
   *  query: {
   *    pid: 123,
   *  }
   * }))
   * ```
   */
  Vue.prototype.$location = function (
    location: QxLocation,
    options?: LocationFuncOptions,
  ): QxLocation {
    options = assign({}, options);

    const query = location.query || {};
    const currentQuery = this.$route.query || {};
    const merged = mergeQueryWithWhitelist(currentQuery, query, options);

    return {
      ...location,
      query: merged,
    };
  };
}

export default function (Vue: typeof OurVue) {
  Vue.use({
    install,
  });
}
