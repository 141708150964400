export interface PrivacyData {
  /**
   * 仟寻的个人信息保护协议.
   */
  qx_privacy: number | boolean;
  /**
   * 自定义个人信息保护协议.
   */
  custom_privacy: {
    agree: number | boolean;
    id: number;
    title: string;
    etitle: string;
    content: string;
    econtent: string;
    tips_content: string;
    tick_options: string[] | null;
    en_tips_content: string;
    en_tick_options: string[] | null;
    /**
     * 是否显示勾选全部选项。
     */
    tick_all: boolean | 1 | 0;

    /**
     * @see https://www.tapd.cn/43037250/prong/stories/view/1143037250001016872
     *
     * 是否只显示自定义协议。
     */
    custom_only?: boolean | number;
  };

  stale?: boolean;
}

export interface PrivacyState {
  /**
   * 控制弹窗的显示.
   */
  popup: {
    key: string;
    visible: boolean;
    /**
     * 弹窗确认回调.
     */
    callback?: null | ((err?: Error) => void);
  };
  pendingMode: PrivacyVisibleMode | null;
  lastTs: number;
  privacyData: PrivacyData;
}

/**
 * 0:同意了最新的版本的个人信息保护协议; 1:未同意过任何版本的个人信息保护协议;  2:同意了老版本的个人信息保护协议;
 */
export const PRIVACY_AGREED_VAL = 0;
export const PRIVACY_AGREED_OLD_VAL = 2;
export const CUSTOM_PRIVACY_AGREED_VAL = 1;
export const QX_PRIVACY_POPUP_KEY = "qx_agree";
export const CUSTOM_PRIVACY_POPUP_KEY = "custom_agree";
export const PRIVACY_ALL_POPUP_KEY = "privacy_all";

/**
 * 隐私协议展示的模式。
 */
export enum PrivacyVisibleMode {
  /**
   * 只展示仟寻的。
   */
  QX_ONLY = 1,
  /**
   * 只展示自定义的。
   */
  CUSTOM_ONLY,
  /**
   * 都展示。
   */
  ALL,
}
