/* eslint-disable @typescript-eslint/ban-ts-comment */
import { env } from "@/shared/utils/env";
import {
  increaseNextPageTickToken,
  nextPageTick,
} from "@/shared/utils/nextPageTick";
import Vue from "vue";
import VueRouter from "vue-router";
import authGuardGroup from "./guards/authGuardGroup";
import privacyGuard from "./guards/privacyGuard";
import tracking from "./guards/tracking";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: env.VUE_APP_ROUTER_BASE || "/",
  routes,
  // @ts-ignore
  scrollBehavior(to, from, savedPosition) {
    increaseNextPageTickToken();
    return new Promise((resolve) => {
      if (savedPosition) {
        // @ts-ignore
        return resolve({
          ...savedPosition,
          behavior: "instant",
        });
      }

      if (to.path === from.path) {
        return resolve(undefined);
      }

      // only scroll to top when new page's transition is complete
      nextPageTick(() => {
        // @ts-ignore
        return resolve({ x: 0, y: 0, behavior: "instant" });
      });
    });
  },
});

// add middlewares.
router.beforeEach(authGuardGroup);
router.afterEach(privacyGuard);
router.afterEach(tracking);

export default router;
