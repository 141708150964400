import {
  StoreItem,
  storeItemToQxMapCoord,
} from "@/shared/utils/storeItemToQxMapCoord";
import { ActionContext, Module } from "vuex";
import { fetchJobStores } from "../../api/lbs";
import { DEF_MAP_RADIUS } from "../../shared/utils/consts";
import State from "../State";
import types from "../mut-types";
import { JdState } from "./model";

/**
 *
 * 用用户反馈 微信的地址授权弹窗频繁，因此有需求，需要：
 * 1. 如果是 lbs 场景，则保持请求微信地图sdk获取精准当前位置。
 * 2. 如果不是，则，如果检查业务开关是开启的，检查用户是否拒绝过，拒绝过则一段时间内不弹窗。
 * 3. 如果业务开关是关闭的，则保持线上逻辑（即可能会经常弹窗要权限)。
 */
const getMapWxSdkAuthDebounceValue = (
  preset: any,
  useMapSdkFreely: boolean | null,
) => {
  if (typeof preset === "boolean") {
    return preset;
  }

  if (useMapSdkFreely) {
    return false;
  } else if (!useMapSdkFreely) {
    // 进行授权合规检查
    return true;
  }
  return false;
};

type AC = ActionContext<JdState, State>;

const jdModule: Module<JdState, State> = {
  namespaced: true,

  state: {
    currentStore: {},
    distance: null,
    radius: DEF_MAP_RADIUS,
    stores: [],
  },

  actions: {
    setRadius({ commit }: AC, radius) {
      commit(types.JD_SET_RADIUS, radius);
    },

    fetchCurrentLocation({ dispatch, rootState }: AC, options) {
      const locationAuthDebounce = getMapWxSdkAuthDebounceValue(
        options?.locationAuthDebounce,
        rootState.appModule.pageconst.switch.mapWxSdkUseFreely,
      );

      return new Promise((resolve, reject) => {
        dispatch(
          "mapModule/getCurrentLocation",
          {
            ...options,
            locationAuthDebounce,
          },
          { root: true },
        ).then(resolve, (err) => {
          // eslint-disable-next-line
          console.log("get current location", err);
          reject(err);
        });
      });
    },

    changeCurrentStore({ commit, state }: AC, store = {}) {
      if (state.currentStore && store.id === state.currentStore.id) {
        return;
      }
      commit(types.JD_SET_CURRENT_STORE, store || {});
    },

    async getCurrentDistance({ commit, dispatch, state, rootState }: AC) {
      return new Promise((resolve, reject) => {
        const currentStore = state.currentStore;
        const userLoction = rootState.mapModule.currentLocation;

        if (!currentStore || !userLoction) {
          return reject(new Error("Invalid call"));
        }

        const lhsPosition = storeItemToQxMapCoord(currentStore as StoreItem);
        const rhsPosition = userLoction.coord;
        if (!rhsPosition) {
          return reject(new Error("Invalid call"));
        }

        return new Promise((resolve, reject) => {
          dispatch(
            "mapModule/fetchDistance",
            {
              lhsPosition,
              rhsPosition,
            },
            { root: true },
          ).then((distance) => {
            commit(types.JD_SET_CURRENT_DISTANCE, distance);
            resolve(distance);
          }, reject);
        });
      });
    },

    async getJobStores(
      { commit }: AC,
      args: { pid: number | string; lng: number; lat: number; radius: number },
    ) {
      const serverData = await fetchJobStores(args);

      const stores = serverData.data.stores || [];
      commit(types.JD_SET_JOB_STORES, stores);

      return serverData.data;
    },
  },

  mutations: {
    [types.JD_SET_CURRENT_STORE](state, payload) {
      state.currentStore = payload;
    },
    [types.JD_SET_CURRENT_DISTANCE](state, payload) {
      state.distance = payload;
    },
    [types.JD_SET_RADIUS](state, payload) {
      state.radius = payload;
    },
    [types.JD_SET_JOB_STORES](state, payload) {
      state.stores = payload;
    },
  },
};

export default jdModule;
