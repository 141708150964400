<template>
  <root-portal :disabled="!fixed">
    <div
      class="g-moseeker-seal js-plink MoseekerSeal"
      :class="{ 'MoseekerSeal--fixed': fixed }"
      @click="onShowQrcode_"
    >
      <p class="s-line"
        >Powered by <i class="g-icon-moseeker-light g-icon">MoSeeker</i></p
      >
      <p v-if="!oneline" class="s-line">Made in Shanghai with Love</p>
      <p v-if="!oneline" class="s-heart s-line g-icon-love g-icon">:)</p>

      <qrcode-popup
        class="MoseekerSeal-popup"
        :title="$t('qxqrcode_title')"
        :qrcode="wechat.qrcode"
        closeable
        :visible.sync="showQxQrcode"
      >
        <template #bottomText>
          <p class="MoseekerSeal-seal s-line"
            >Powered by <i class="g-icon-moseeker-light g-icon">MoSeeker</i></p
          >
        </template>
      </qrcode-popup>
    </div>
  </root-portal>
</template>

<script>
import QrcodePopup from "@/components/job/QrcodePopup.vue";
import { fetchWechat } from "@/api/func";
import { APP_MODULE, QX_TRAFFIC_STR_CODE } from "@/shared/utils/consts";
import { QX_WECHAT_ID } from "@/shared/utils/consts";
import { mapState } from "vuex";
import { isRouteSassMode } from "../../../router/utils";

export default {
  i18n: {
    messages: {
      zh: {
        qxqrcode_title: "长按关注仟寻，发现更多求职机会",
      },

      en: {
        qxqrcode_title: "Long press to follow MoSeeker's official account",
      },
    },
  },

  components: {
    QrcodePopup,
  },

  computed: {
    ...mapState(APP_MODULE, {
      isEmployee: (state) => state.pageconst.user.isEmployee,
    }),
  },

  props: {
    fixed: Boolean,
    /**
     * 是否只显示1行.
     */
    oneline: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showQxQrcode: false,
      wechat: {},
    };
  },

  mounted() {
    if (this.isEmployee) {
      return;
    }
    // 会影响页面主要业务接口的调用
    setTimeout(() => {
      this.fetchWechat();
    }, 600);
  },

  methods: {
    async fetchWechat() {
      if (!isRouteSassMode(this.$route)) {
        return;
      }

      const sd = await fetchWechat(null, {
        str_scene: "OFFICIAL_ACCOUNT_TRAFFIC",
        str_code: QX_TRAFFIC_STR_CODE.QRCODE_POWER_BY_MOSEEKER,
        wechat_id: QX_WECHAT_ID,
      });
      const data = sd.data;
      this.wechat = data;
    },

    onShowQrcode_() {
      if (this.isEmployee) {
        return;
      }
      if (!this.wechat.subscribed) {
        this.showQxQrcode = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/helper";

.MoseekerSeal {
  &--fixed {
    position: fixed;
    // TODO: need fix.
    bottom: rem($bottom-bar-height + 20px);
  }

  &-popup {
    z-index: 9999 !important;
    .QrcodePopup__title {
      color: #787e85;
      font-size: rem(14px);
      // margin-bottom: rem(50px);
    }
  }

  &-seal {
    visibility: visible;
    font-size: rem(14px);
    font-weight: 300;
    font-family: "PingFang SC", "Myriad Pro", "Roboto Light", "Hiragino Sans GB",
      "Microsoft Yahei", "微软雅黑", sans-serif;
    color: #d0d4d7;
    text-align: center;
    line-height: 1;
    transition: all 0.5s ease;
    margin-top: rem(40px);
  }
}
</style>
