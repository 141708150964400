import http, { unwrap } from "@/shared/http";
import get from "lodash/get";
import { cross } from "@/shared/utils/url";
import { EmployeeMineData } from "../shared/models/employee";
import { PosterApiPayload } from "../shared/models/poster";

/**
 * 员工姓名隐匿.
 */
const API_EMP_ANON = cross("/api/switch/emp-anon");
const API_POSTER_EMPLOYEE_INFO = cross("/api/poster/employee");
export const API_RADAR_SWITCH = cross("/api/switch/radar");
export const API_UPLOAD_LIMIT = cross("/api/referral/upload_limit");
const API_MINIRESUME_APPLICATION_SYNC = cross("/api/profile/exist");
const API_MINIRESUME_SYNC = cross("/api/resume/upload/complete");
const API_POSTER_SWITCH = cross("/api/switch/poster");
const API_POSTER_BGS = cross("/api/poster/bg-pics");
const API_EMPLOYEE_BIND = cross("/api/employee/bind");
export const API_EMPLOYEE_MINE = cross("/api/usercenter/mine");
const API_EMPLOYEE_PROFILE_EXIST = cross("/api/usercenter/personal_file/exist");
const API_EMP_USERCENT_SWITCH = cross("/api/usercenter/config/switch");

export interface UploadLimitType {
  invalid: boolean;
  days: number;
  times: number;
}

export type SyncResult = { finished: boolean; fileId?: string } | boolean;

export async function isRadarEnable() {
  const sd = await unwrap<1 | 0>(http.get(API_RADAR_SWITCH));

  if (sd.data === 1) {
    return true;
  }

  throw new Error("radar is not enabled");
}

export async function isRadarEnableValue() {
  try {
    return isRadarEnable();
  } catch (err) {
    return false;
  }
}

export async function fetchUploadLimit() {
  return await unwrap<UploadLimitType>(http.get(API_UPLOAD_LIMIT));
}

export function extractTargetFromScene(
  sceneToken: string | number | null,
): string {
  if (typeof sceneToken === "number") {
    return sceneToken + "";
  }

  if (typeof sceneToken !== "string") {
    return "";
  }

  let spts = sceneToken.split("_");
  return spts[spts.length - 1] || "";
}

/**
 * 轮询小程序上传文件状态.
 */
export async function miniResumeSync(params: {
  syncId: string | number | null;
  [key: string]: any;
}) {
  const scene = extractTargetFromScene(params.syncId);
  // 如果是候选人投递简历，查询是否有简历的接口不需要syncId作为入参
  if (scene === "3") {
    return await unwrap<SyncResult>(http.get(API_MINIRESUME_APPLICATION_SYNC));
  }

  return await unwrap<SyncResult>(
    http.get(API_MINIRESUME_SYNC, {
      params,
    }),
  );
}

export async function getPosterSwitch(): Promise<boolean> {
  try {
    const sd = await unwrap<boolean>(http.get(API_POSTER_SWITCH));
    return sd.data;
  } catch (err) {
    return false;
  }
}

export async function getPosterBgs(cid?: string | number): Promise<
  Array<{
    id: number;
    url: string;
    name: string;
    setting: {
      add_company_name: 1 | 0;
      add_company_logo: 1 | 0;
    };
  }>
> {
  try {
    const sd = await unwrap<
      Array<{
        id: number;
        url: string;
        name: string;
        setting: {
          add_company_name: 1 | 0;
          add_company_logo: 1 | 0;
        };
      }>
    >(
      http.get(API_POSTER_BGS, {
        params: {
          did: cid,
        },
      }),
    );
    return sd.data || [];
  } catch (err) {
    return [];
  }
}

export function getPosterBgByIndex(payload: {
  index: number;
  bgs: PosterApiPayload["bgs"];
}): {
  poster: string;
  posterCardColor?: string;
} {
  const firstPosterBg = get(payload, ["bgs", payload.index, "url"]);
  const posterCardColor = get(payload, ["bgs", payload.index, "theme"]);

  return {
    poster: firstPosterBg,
    posterCardColor,
  };
}

/**
 * 返回 recom 参数，recom 参数是用于追踪链路的。
 * @returns recom参数数据
 */
export async function getRecomParam(): Promise<{
  is_employee: boolean;
  recom: string;
  user_id: number;
}> {
  const sd = await unwrap<{
    is_employee: boolean;
    recom: string;
    user_id: number;
  }>(http.get(cross("/api/employee/recom")));
  return sd.data || {};
}

export async function fetchPosterEmployeeInfo() {
  const sd = await unwrap<{ cname: string }>(
    http.get(API_POSTER_EMPLOYEE_INFO),
  );

  return sd.data || {};
}

/**
 * 是否隐匿员工信息（姓名）.
 *
 * @returns true: 是, false: 否.
 */
export async function fetchEmpAnonSwitch() {
  const sd = await unwrap<boolean>(http.get(API_EMP_ANON));
  return sd.data;
}

export async function bindEmployee(data: any) {
  return await unwrap(http.post(API_EMPLOYEE_BIND, data));
}

export async function fetchEmployeeMinData() {
  const { data } = await unwrap<EmployeeMineData>(
    http.get(API_EMPLOYEE_MINE, {
      params: {
        // no cache.
        _ts: new Date().getTime(),
      },
    }),
  );
  return data;
}

export async function fetchEmployeeProfileExistState() {
  const { data } = await unwrap<{
    is_has_workexps: boolean;
    is_has_educations: boolean;
    is_recommend_position: boolean;
  }>(http.get(API_EMPLOYEE_PROFILE_EXIST));
  return data;
}

export async function fetchEmpUsercenterSwitchs() {
  const { data } = await unwrap<{
    integrals_mall_display: 1 | 0;
    bonus_redpacket_display: 1 | 0;
    integrals_rank_display: 1 | 0;
  }>(http.get(API_EMP_USERCENT_SWITCH));
  return data;
}
