import { EmployeeMineData } from "../../shared/models/employee";

export interface EmployeeState {
  missingInfo: MissingInfo;
  employeeMineData: EmployeeMineData;
}

export enum MissingInfoItemKind {
  None = 0,
  /**
   * 简历.
   */
  Profile = 1,
  /**
   * 内推职位
   */
  ReferralJobs = 2,
}

export interface MissingInfoItem {
  kind: MissingInfoItemKind;
  /**
   * 是否忽略这条数据
   */
  ignore: boolean;
}

export interface MissingInfoVmItem {
  path: string;
  title: string;
  info: string;
  kind: MissingInfoItemKind;
}

export interface MissingInfo {
  items: MissingInfoItem[];
}
