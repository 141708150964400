import http, { unwrap } from "@/shared/http";
import { cross } from "@/shared/utils/url";
import changeQuery from "@dqjs/libcore/lib/changeQuery";
import reduce from "lodash/reduce";
import {
  AdvanceSearchSeq,
  BulkShareResponse,
  JdData,
  JobPopupData,
  JobRepostVisitorInfo,
} from "../shared/models/job";
import { ApiPayload } from "../shared/types/http.t";
import { Dict, LooseObject } from "../shared/types/value.t";

const API_POSITION_STAR = (query?: any) =>
  cross(changeQuery("/api/position/star", query));
const API_POSITION_LIST = cross("/api/position/list");
const API_JOB_SHARE_ID = cross("/api/position/share");
const API_SEARCH_HISTORY = cross("/api/position/search/history");
const API_JOB_SUG = cross("/api/position/list/sug");
const API_ADVANCE_SEARCH = cross("/api/v2/search_seq");
const API_JOB_DETAIL = (pid: number | string) =>
  cross(`/api/v2/position/${pid}`);
const API_JOB_REPOST_VISITOR_INFO = cross("/api/position/repost/visitor_info");
const API_REFRESH_SHARECHAIN = (pid?: string | number) =>
  cross(`/api/refresh/sharechain/${pid}`);
const API_AI_JOB_LIST = cross("/api/recommend/position/list");
const API_AI_SWITCH = cross("/api/recommend/switch");
const API_POSITION_POPUP = cross("/api/position/popup");
const API_JOB_ENGAGE_USERS = cross("/api/position/engage/users");
const API_REFERRAL_RULE = cross("/api/switch/referral_rule");
const API_MASS_JOB_LIST = cross("/api/gather/position/list");

/**
 * 获取聚合的职位列表.
 */
export async function fetchMassJobs(args: object) {
  return await unwrap(
    http.get(API_MASS_JOB_LIST, {
      params: args,
    }),
  );
}

export async function fetchJobs(args: object) {
  return await unwrap(
    http.get(API_POSITION_LIST, {
      params: args,
    }),
  );
}

export async function fetchAiJobs(args: object) {
  return await unwrap(
    http.get(API_AI_JOB_LIST, {
      params: args,
    }),
  );
}

export async function fetchAiSwitch() {
  try {
    const sd = await unwrap<{ switch: boolean }>(http.get(API_AI_SWITCH));
    return sd.data.switch;
  } catch (err) {
    return false;
  }
}

export async function fetchJobShareId(
  body: any,
): ApiPayload<BulkShareResponse> {
  return await unwrap(http.post(API_JOB_SHARE_ID, body));
}

export async function fetchBulkShareData() {
  return await unwrap<{
    employee_name: string;
    employee_icon: string;
    is_referral: boolean;
  }>(http.get(API_JOB_SHARE_ID));
}

export async function fetchSearchHistory(): ApiPayload<string[]> {
  return await unwrap(http.get(API_SEARCH_HISTORY));
}

export async function clearSearchHistory() {
  return await unwrap(http.patch(API_SEARCH_HISTORY));
}

export async function fetchJobSug(args: any): ApiPayload<{ list: string[] }> {
  return await unwrap(
    http.get(API_JOB_SUG, {
      params: {
        ...args,
      },
    }),
  );
}

export async function fetchAdvanceSeq(
  args?: any,
): ApiPayload<AdvanceSearchSeq> {
  return await unwrap(
    http.get(API_ADVANCE_SEARCH, {
      params: args,
    }),
  );
}

export async function fetchJobDetail(pid: number | string, args?: LooseObject) {
  return await unwrap<JdData>(
    http.get(API_JOB_DETAIL(pid), {
      params: {
        ...args,
        _ts: new Date().getTime(),
      },
    }),
  );
}

/**
 * 收藏职位操作.
 */
export async function favPosition(args: {
  pid: number | string;
  star: 0 | 1;
  employee_id?: number | string;
  ai_recom?: string;
  algorithm_name?: string;
}) {
  return await http.post(
    API_POSITION_STAR({
      ai_recom: args.ai_recom,
      algorithm_name: args.algorithm_name,
    }),
    {
      pid: args.pid,
      star: args.star,
      employee_id: args.employee_id,
    },
  );
}

export async function fetchRepostVisitorInfo(params: LooseObject = {}) {
  return unwrap<JobRepostVisitorInfo>(
    http.get(API_JOB_REPOST_VISITOR_INFO, {
      params: params,
    }),
  );
}

export async function refreshSharechain(pid: number | string) {
  if (!pid) {
    return undefined;
  }

  try {
    const sd = await unwrap<{ psc: string }>(
      http.post(API_REFRESH_SHARECHAIN(pid)),
    );

    return {
      ...sd.data,
      psc: sd.data.psc,
    };
  } catch (err) {
    return undefined;
  }
}

/**
 * 获取jd弹窗的一些配置数据.
 */
export async function getPopupFeature(pid: string | number) {
  return await unwrap<JobPopupData>(
    http.get(API_POSITION_POPUP, {
      params: {
        position_id: pid,
      },
    }),
  );
}

interface ReferralRule {
  config_switch: boolean;
  type: number;
}
export async function fetchReferralRuleSwitch() {
  const { data } = await unwrap<ReferralRule[]>(http.get(API_REFERRAL_RULE));

  return reduce<ReferralRule, Dict<boolean>>(
    data,
    (acc, item) => {
      acc[item.type] = !!item.config_switch;
      return acc;
    },
    {},
  );
}

/**
 * 获取职位内推员工或者候选人列表。
 *
 * 内推员工列表：哪些员工在内推这个职位。
 * 候选人列表：哪些候选人查看了该职位。
 */
export async function fetchJobEngageUsers(
  pid: string | number,
  /**
   * 1: 给员工看的（候选人内推成员列表）。
   * 2: 给候选人看的（员工内推成员列表）。
   */
  kind: "1" | "2",
) {
  const { data } = await unwrap<{
    total_rows: number;
    page_size: number;
    current_page: number;
    current_page_data: Array<{
      nickname: string;
      name: string;
      active_score: number;
      user_id: number;
      headimg: string;
      last_active_time: string;
    }>;
  }>(
    http.get(API_JOB_ENGAGE_USERS, {
      params: {
        pid,
        kind: kind,
      },
    }),
  );

  return {
    list: data.current_page_data,
    page: data.current_page,
    page_size: data.page_size,
    total: data.total_rows,
  };
}
