import { Module } from "vuex";
import State from "../State";
import * as actions from "./actions";
import getters from "./getters";
import {
  // CUSTOM_PRIVACY_POPUP_KEY,
  PrivacyState,
} from "./model";
import mutations from "./mutations";

const privacyModule: Module<PrivacyState, State> = {
  namespaced: true,

  state: {
    popup: {
      key: "",
      visible: false,
      callback: null,
    },
    lastTs: 0,
    /**
     * 在多个post接口请求期间，需要设置一下这个pendingMode，否则，因为接口的不一致性，会导致 getters 中的数据有临时的问题。
     */
    pendingMode: null,
    privacyData: {
      qx_privacy: 0,
      custom_privacy: {
        agree: true,
        id: 0,
        title: "",
        etitle: "",
        content: "",
        econtent: "",
        tips_content: "",
        tick_options: [],
        tick_all: false,
        en_tips_content: "",
        en_tick_options: [],
      },
      stale: false,
    },
  },

  actions,
  getters,
  mutations,
};

export default privacyModule;
