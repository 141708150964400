import { Module } from "vuex";
import State from "../State";
import * as actions from "./actions";
import { PageState } from "./model";
import mutations from "./mutations";

const pageModule: Module<PageState, State> = {
  namespaced: true,
  state: {
    landing: {
      title: "",
      title_en: "",
      background: "",
      buttons: [],
      stale: false,
      show_company_desc: 1,
      style_type: 0,
    },
  },

  mutations,
  actions,
};

export default pageModule;
