/**
 * 和员工相关的服务数据。
 */
import { Module } from "vuex";
import { IM_SWITCH, isHrChatOn, isReferChatOn } from "../../shared/models/chat";
import { BlockKeys } from "../../views/Mine/modules/model";
import State from "../State";
import * as actions from "./actions";
import { EmployeeState } from "./model";
import mutations from "./mutations";

const employeeModule: Module<EmployeeState, State> = {
  namespaced: true,

  state: {
    missingInfo: {
      items: [],
    },
    employeeMineData: {
      headimg: "",
      unread_praise: 0,
      seek_recommend_unread: 0,
      name: "",
      bind_status: 0,
      isLoading: true,
      department_name: "",
      job: "",
    },
  },
  getters: {
    switchs(state, getters, rootState) {
      const switchs = rootState.appModule.switchs;
      const switchsFromPageConst = rootState.appModule.pageconst.switch;
      const { chat, showChat } = switchs;
      /**
       * 为了兼容老接口做判断，oms配置关闭聊天优先，否则按照老接口来
       */
      const ChatVal =
        showChat === false
          ? false
          : isReferChatOn(chat as IM_SWITCH) && isHrChatOn(chat as IM_SWITCH);

      // 值为 true， 则代表开关是开的，功能是开的。
      return {
        [BlockKeys.internalPolicy]: !!switchsFromPageConst.internalJob,
        [BlockKeys.radar]: switchs.radar,
        [BlockKeys.poster]: switchs.poster,
        [BlockKeys.chat]: ChatVal,
      };
    },
  },
  mutations,
  actions,
};

export default employeeModule;
