
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AppLayout extends Vue {
  /**
   * 是否展示底部的 powered by moseeker.
   */
  @Prop({
    type: Boolean,
    default: true,
  })
  seal!: boolean;
}
