import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";
import { APP_MODULE } from "../../shared/utils/consts";

/**
 * Manage the popup etc.
 * 需要解决的问题：
 * 1. 多个弹窗重叠问题.
 * 2. z-index问题.
 */
@Component({
  methods: {
    ...mapActions(APP_MODULE, ["pushModal", "popModal"]),
  },
})
export default class QxPopup extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop(String) name!: string;
  @Prop({ type: Boolean, default: true }) closeOnClickOverlay!: boolean;
  @Prop(String) position!: "top" | "bottom" | "right" | "left";
  @Prop(Boolean) closeable!: boolean;
  @Prop({ type: Boolean, default: true }) round!: boolean;
  @Prop({
    type: [String, Function],
    default: "body",
  })
  getContainer!: string;

  pushModal!: (args: { run: () => void }) => Promise<number>;
  popModal!: (id: number) => Promise<void>;

  // visible.
  lazyValue = this.value || false;
  modalId = 0;

  @Watch("value", { immediate: true })
  async onValueChange(newVal: boolean) {
    await this.closeModal();

    if (!newVal) {
      this.lazyValue = false;
      return;
    }

    this.modalId = await this.pushModal({
      run: () => {
        this.lazyValue = newVal;
      },
    });
  }

  async closeModal() {
    if (this.modalId) {
      await this.popModal(this.modalId);
    }
    this.modalId = 0;
  }

  render() {
    return this.$createElement(
      "van-popup",
      {
        props: {
          value: this.lazyValue,
          closeable: this.closeable,
          position: this.position,
          closeOnClickOverlay: this.closeOnClickOverlay,
          round: this.round,
          getContainer: this.getContainer,
        },

        on: {
          ...this.$listeners,
          input: (val: boolean) => {
            this.$emit("input", val);
          },
          "click-overlay": () => {
            if (this.closeOnClickOverlay) {
              this.closeModal();
              this.$emit("close");
            }

            this.$emit("click-overlay");
          },

          closed: (...args: any[]) => {
            this.closeModal();
            this.$emit("closed", ...args);
          },
        },
      },
      [this.$scopedSlots.default ? this.$scopedSlots.default({}) : null],
    );
  }
}
