import { MutationTree } from "vuex";
import { CommentState } from "./model";
import types from "../mut-types";
import { defaultPageNo } from "./utils";

const mutations: MutationTree<CommentState> = {
  /**
   * careestory页面-精选、全部、参与留言列表
   */
  [types.COMMENT_SET_LOADING](state, payload) {
    state.loading = payload;
  },
  [types.COMMENT_SET_TOTAL](state, payload) {
    state.total = payload;
  },
  [types.COMMENT_SET_LIST](state, payload) {
    state.list = state.list.concat(payload || []);
  },
  [types.COMMENT_SET_EXHUASTED](state, payload) {
    state.exhuasted = payload;
  },
  [types.COMMENT_INC_PAGENO](state) {
    state.pageNo += 1;
  },
  [types.COMMENT_RESET](state) {
    state.list = [];
    state.exhuasted = false;
    state.loading = true;
    state.pageNo = defaultPageNo;
    state.total = 0;
  },
  [types.COMMENT_UPDATE_LIST](state, payload) {
    state.list = (payload || []).concat(state.list);
  },
  /**
   * 写留言弹框-我的留言列表
   */
  // [types.COMMENT_SET_MYLOADING](state, payload) {
  //   state.myLoading = payload;
  // },
  // [types.COMMENT_SET_MYTOTAL](state, payload) {
  //   state.myTotal = payload;
  // },
  // [types.COMMENT_SET_MYLIST](state, payload) {
  //   state.myList = state.myList.concat(payload || []);
  // },
  // [types.COMMENT_SET_MYEXHUASTED](state, payload) {
  //   state.myExhuasted = payload;
  // },
  // [types.COMMENT_INC_MYPAGENO](state) {
  //   state.myPageNo += 1;
  // },
  // [types.MY_COMMENT_RESET](state) {
  //   state.myList = [];
  //   state.myExhuasted = false;
  //   state.myLoading = true;
  //   state.myPageNo = defaultPageNo;
  //   state.myTotal = 0;
  // },
  // [types.COMMENT_UPDATE_MYLIST](state, payload) {
  //   state.myList = (payload || []).concat(state.myList);
  // },
};

export default mutations;
