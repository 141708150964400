import map from "lodash/map";
import each from "lodash/each";
import { GetterTree } from "vuex";
import { ListTab } from "../../shared/models/job";
import { SHARE_ID } from "../../shared/utils/consts";
import State from "../State";
import { JobState } from "./model";
import { isDev, isSandbox } from "../../shared/utils/env";

const getters: GetterTree<JobState, State> = {
  isJobIndexEmpty(state) {
    return state.index.exhuasted && !state.index.list.length;
  },
  isAiJobEmpty(state) {
    return state.airecom.exhuasted && !state.airecom.list.length;
  },
  isInternalJobEmpty(state) {
    return state.internal.exhuasted && !state.internal.list.length;
  },
  isEmpRecomEmpty(state) {
    return state.emprecom.exhuasted && !state.emprecom.list.length;
  },
  /**
   * 如果没有数据，且在Loading，显示骨架屏.
   */
  isJobIndexSkeletonShow(state) {
    return !state.index.list.length && !state.index.exhuasted;
  },
  isEmpRecomSkeletonShow(state) {
    return !state.emprecom.list.length && !state.emprecom.exhuasted;
  },
  isAiJobSkeletonShow(state) {
    return !state.airecom.list.length && !state.airecom.exhuasted;
  },
  isInternalJobSkeletonShow(state) {
    return !state.internal.list.length && !state.internal.exhuasted;
  },
  searchParams(state, getters, rootState) {
    // const searchParams = state.index.searchParams;

    return {
      ...rootState.routeModule.query,
      // ...searchParams,
      count: state.index.pageNo,
      pageSize: state.index.pageSize,
    };
  },
  empJobSearchParams(state, getters, rootState) {
    return {
      ...rootState.routeModule.query,
      // ...searchParams,
      count: state.emprecom.pageNo,
      pageSize: state.emprecom.pageSize,
    };
  },
  aiJobSearchParams(state, getters, rootState) {
    return {
      ...rootState.routeModule.query,
      // ...searchParams,
      page_no: state.airecom.pageNo,
      page_size: state.airecom.pageSize,
    };
  },
  internalJobSearchParams(state, getters, rootState) {
    return {
      ...rootState.routeModule.query,
      // ...searchParams,
      count: state.internal.pageNo,
      pageSize: state.internal.pageSize,
    };
  },

  selectedMap(state) {
    const map: { [key: number]: boolean } = {};

    each(state.bulkshare.pids, (id) => {
      map[id] = true;
    });

    return map;
  },

  selectedCount(state) {
    return state.bulkshare.pids.length;
  },

  bulkShareEditMode(state, getters, rootState) {
    const currentShareId = (rootState.routeModule.query || {})[
      SHARE_ID
    ] as string;
    const shareIds = state.bulkshare.shareIds || [];

    return shareIds.indexOf(currentShareId) !== -1;
  },

  advanceSearchTabs(state): ListTab[] {
    if (!state.adsearch.datasource) {
      return [];
    }

    const fieldName = state.adsearch.datasource.field_name || [];
    const fieldKeys = state.adsearch.datasource.field_form_name || [];
    const tabs: ListTab[] = map(fieldKeys, (name, index) => {
      return {
        text: fieldName[index],
        key: index,
      };
    });

    return tabs;
  },

  /**
   * 目前只有中外运。
   * 其他公司的不使用，因为当前逻辑有问题，
   * 在团队数据量很多的时候。
   */
  enableTeamTreePicker(state, getters, rootState) {
    const cid = rootState.appModule.pageconst.cid + "";

    const idsStr = ["2495737", "601320"];
    if (isDev) {
      idsStr.push("fake_123");
    }
    if (isSandbox()) {
      // idsStr.push("39978");
    }

    return idsStr.indexOf(cid) !== -1;
  },
};

export default getters;
