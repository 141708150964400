import { ActionContext } from "vuex";
import {
  fetchEmployeeMinData,
  fetchEmployeeProfileExistState,
} from "../../api/employee";
import mutTypes from "../mut-types";
import State from "../State";
import { EmployeeState, MissingInfoItem, MissingInfoItemKind } from "./model";

type AC = ActionContext<EmployeeState, State>;

/**
 * 获取员工主页需要的开关数据。
 * 这些请求都有缓存的。
 */
export async function getEmployeeMeSwitchs({ dispatch }: AC) {
  await Promise.allSettled([
    dispatch("appModule/getAiSwitch", undefined, { root: true }),
    dispatch("appModule/getPosterSwitch", undefined, { root: true }),
    dispatch("appModule/getRadarSwitch", undefined, { root: true }),
    dispatch("appModule/getChatSwitch", undefined, { root: true }),
    dispatch("appModule/getShowChatSwitch", undefined, { root: true }),
  ]);
}

export async function getShowChatSwitch({ dispatch }: AC) {
  await dispatch("appModule/getShowChatSwitch", undefined, { root: true });
}

export async function getMissingInfo({ commit, state }: AC) {
  const old = state.missingInfo;

  const data = await fetchEmployeeProfileExistState();
  const hasProfile = data.is_has_educations || data.is_has_workexps;
  const items = [] as MissingInfoItem[];
  items.push({
    kind: MissingInfoItemKind.Profile,
    ignore: hasProfile,
  });
  items.push({
    kind: MissingInfoItemKind.ReferralJobs,
    ignore: data.is_recommend_position,
  });

  const oldItems = old.items || [];
  for (let i = 0; i < oldItems.length; i++) {
    for (let j = 0; j < items.length; j++) {
      if (items[j].kind == oldItems[i].kind) {
        items[j].ignore = oldItems[i].ignore ? true : items[j].ignore;
      }
    }
  }

  commit(mutTypes.EMPLOYEE_SET_MISSING_INFO_ITEMS, items || []);
}

export function ignoreMissingInfoItem(
  { commit, state }: AC,
  kind: MissingInfoItemKind,
) {
  const items = state.missingInfo.items;
  const newItems = items.map((item) => {
    if (item.kind === kind) {
      return {
        ...item,
        ignore: true,
      };
    }
    return item;
  });
  commit(mutTypes.EMPLOYEE_SET_MISSING_INFO_ITEMS, newItems || []);
}

export async function getEmployeeMineData({ commit }: AC) {
  commit(mutTypes.EMPLOYEE_SET_EMPLOYEE_MINE_DATA, { isLoading: true });
  try {
    const data = await fetchEmployeeMinData();
    commit(mutTypes.EMPLOYEE_SET_EMPLOYEE_MINE_DATA, {
      ...data,
      isLoading: false,
    });
  } catch (err) {
    commit(mutTypes.EMPLOYEE_SET_EMPLOYEE_MINE_DATA, {
      isLoading: false,
    });
    throw err;
  }
}
