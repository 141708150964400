import { CreateElement } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class RootPortal extends Vue {
  @Prop({ type: String, default: "div" }) tag!: string;
  @Prop(Boolean) disabled!: boolean;
  @Prop({ type: Number }) order!: number;
  @Prop({ type: Boolean }) slim!: boolean;

  activated_ = true;

  activated() {
    this.activated_ = true;
  }

  deactivated() {
    this.activated_ = false;
  }

  render(h: CreateElement) {
    if (!this.activated_) {
      return null;
    }

    return h(
      "portal",
      {
        props: {
          to: "global",
          tag: this.tag,
          disabled: this.disabled,
          order: this.order,
          slim: this.slim,
        },
      },
      this.$slots.default,
    );
  }
}
