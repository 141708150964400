//
import { MutationTree } from "vuex";
import types from "../mut-types";
import { MapLocation, MapState } from "./model";

const mutations: MutationTree<MapState> = {
  [types.SET_USER_CURRENT_LOCATION](state, args: MapLocation) {
    state.userCurrentLocation = {
      // @ts-ignore 2783
      addressComponent: {},
      ...args,
    };
  },

  [types.SET_CURRENT_LOCATION](state, args: MapLocation) {
    state.currentLocation = {
      // @ts-ignore 2783
      addressComponent: {},
      ...args,
    };
  },

  [types.SET_FETCHING_CURRENT_LOCATION](state, payload) {
    state.isFetchingCurrentLocation = !!payload;
  },

  [types.SET_FECTH_LOCATION_FAIL](state, payload) {
    state.fetchLocationFail = !!payload;
  },
};

export default mutations;
