import { fetchCompany as fetchCompany_, fetchCompanyInfo } from "@/api/company";
import { fetchGlobal } from "@/api/user";
import i18n from "@/shared/i18n";
import url from "@/shared/utils/url";
import { inBrowser } from "@dqjs/libcore/lib/ua";
import assign from "lodash/assign";
import { ActionContext } from "vuex";
import { AUTH_URL, isDev } from "../../shared/utils/consts";
import types from "../mut-types";
import State from "../State";
import { UserState } from "./model";
// import isNil from "lodash/isNil";

type AC = ActionContext<UserState, State>;

export async function getGlobal(
  { commit, rootState }: AC,
  payload: Partial<{ requireAuth: boolean; isSassMode: boolean }> = {},
): Promise<void> {
  if (payload.isSassMode === false) {
    return;
  }

  payload = assign({}, payload);

  const serverData = await fetchGlobal(
    {
      ...rootState.routeModule.query,
    },
    null,
    payload.requireAuth,
  );
  commit(types.SET_GLOBAL, serverData.data || {});
}

/**
 * 授权登录.
 * 如果要本地模拟授权登录，URL 加上参数 mockAuth=1 刷新页面即可。
 */
export async function oauth(
  { dispatch, rootState }: AC,
  payload: {
    nextUrl?: string;
    confirm?: boolean;
  } = {},
) {
  const LOCK_NAME = "oauth";

  if (rootState.appModule.locks[LOCK_NAME]) {
    return;
  }

  dispatch("appModule/lock", LOCK_NAME, { root: true });

  const unlock = () => {
    setTimeout(() => {
      dispatch("appModule/unlock", LOCK_NAME, { root: true });
    }, 26);
  };

  const unencodedNextUrl = payload.nextUrl
    ? payload.nextUrl
    : getNextUrlFromLocation();
  dispatch("appModule/setNextUrl", unencodedNextUrl, { root: true });

  /**
   * 需要弹窗用户确认跳转授权
   */
  if (payload.confirm) {
    const isConfirmContinue =
      (await dispatch(
        "appModule/confirm",
        {
          title: i18n.t("core.notLogin"),
          message: i18n.t("core.needLoginToContinue"),
          confirmButtonText: i18n.t("core.login"),
          cancelButtonText: i18n.t("core.cancel"),
        },
        { root: true },
      )) === "confirm";
    if (!isConfirmContinue) {
      unlock();
      return;
    }
  }
  unlock();

  const redirectUrl = url(AUTH_URL, {
    next_url: unencodedNextUrl,
  });

  window.location.href = redirectUrl;
}

function getNextUrlFromLocation(): string {
  if (!inBrowser) {
    return "";
  }

  const location = window.location;
  const pathname = location.pathname;
  const search = location.search;
  const hash = location.hash;

  if (isDev) {
    search.replace("mockAuth=1", "mockAuth=2");
  }

  return `${pathname}${search}${hash}`;
}

/**
 * 第一版的，暂放这里.
 */
export async function fetchCompany({ commit, state }: AC) {
  if (state.company.stale) {
    return;
  }
  const sd = await fetchCompany_();
  commit(types.SET_COMPANY, sd.data);
}

/**
 * 确定当前正在获取的公司id，如果请求结果到达，id变了，则不要修改数据.
 */
let isFetchingCopmanyInfoId_ = -1 as any;

/**
 * 获取公司信息，无id则是默认母公司.
 * 这个接口会在每次路由变化的情况下调用一次，以确保可以根据路由上的did参数更新公司信息 in realtime.
 */
export async function fetchCompanyInfoById(
  { commit, state, rootState }: AC,
  id?: string | number,
) {
  // 如果公司id一样，已经获取过了，则不再获取.
  if (state.company.id == id && state.company.stale) {
    return;
  }

  if (isFetchingCopmanyInfoId_ == id && state.company.stale) {
    return;
  }

  try {
    isFetchingCopmanyInfoId_ = id;
    const sd = await fetchCompanyInfo(id);
    if (isFetchingCopmanyInfoId_ != id) {
      return;
    }
    commit(types.SET_COMPANY, sd.data);
  } catch (err) {
    // 如果有接口报错，则取页面上的数据或者忽略此错误。
    // 不能因为此错误影响后面的页面代码。

    const pageconst = rootState.appModule.pageconst || {};
    const defaultCompany = {
      id: pageconst.cid,
      abbreviation: pageconst.cname,
      logo: pageconst.clogo,
    };
    commit(types.SET_COMPANY, defaultCompany);
    return;
  }
}
