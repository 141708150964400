import { LooseObject } from "../types/value.t";
import { Option } from "./option";

/**
 * TODO(towry): import from qxjs.
 */
export interface ListTab {
  text: string;
  key?: string | number;
  count?: number;
  href?: string;
  to?: string | object;
}

export interface JobSearchOption extends Option {}
export type JobSearchColumn = JobSearchOption[];

export interface JobItem {
  title: string;
  priority: string | number;
  visitnum: number;
  job_desription: string;
  experience: string;
  degree: string;
  job_need: string;
  id: number;
  is_referral: boolean;
  can_apply: boolean;
  has_point_reward: boolean;
  city: string;
  has_reward: boolean;
  salary: string;
  is_starred: boolean;
  publish_date: string;
  update_time: string;
  bonus: number | string;
  is_applied: boolean;
  distance: number;
  has_store: boolean;
  department: string;
  team_name: string;
  candidate_source: number;
  /**
   * 当前浏览过的候选人数量。
   */
  candidate_count?: number;
  /**
   * 当前正在内推的员工数量。
   */
  rec_employee_count?: number;
  company_abbr?: string;
  suppress_apply?: {
    is_suppress_apply: boolean;
    suppress_apply_data: any;
  };
  /**
   * 职位所属的公司的 signature
   */
  signature?: string;
  /**
   * 公司规模，用在聚合号.
   */
  company_scale_name?: string;
  [key: string]: any;
}

/**
 * 不要轻易更改顺序，因为这些值都会放到链接里，可能被用户分享出去了的.
 */
export enum INDEX_TAB {
  /**
   * 全部职位，首页.
   */
  ALL = 1,
  /**
   * AI 推荐.
   */
  RECOM = 2,
  NEARBY = 3,
  /**
   * 内部职位，内部招聘.
   */
  INTERNAL = 4,
}

export interface BulkShareResponse {
  recom: string;
  share_id: string;
}

export interface AdvanceSearchSeqValueItem {
  en?: string;
  text?: string;
  value?: string;
}

export interface AdvanceSearchSeq {
  /**
   * @example
   *
   * ```js
   * [ 'salary', 'candidate_source' ]
   * ```
   */
  field_form_name: string[];
  /**
   * @example
   *
   * ```js
   * [ '薪资', '招聘类型' ]
   * ```
   */
  field_name: string[];
  values: AdvanceSearchSeqValueItem[];
  support_condition: string[];
}

export interface JdData {
  meta: {
    title: string;
    /**
     * 是否是内退.
     */
    is_referral: boolean;
    /**
     * 子公司id.
     */
    did: number;
    city: string;
    experience: string;
    degree: string;
    /**
     * 是否有积分奖励.
     */
    has_point_reward: boolean;
    endorse: {
      publisher: number;
      avatar: string;
      department: string;
      name: string;
      company: string;
      is_hr: boolean;
    };
    has_store: boolean;
    salary: string;
    recruit_number: number;
    status: number;
    star?: number | boolean;
    appid?: string | number;
    submitted?: boolean;
    reward_point?: number;
    candidate_source?: number;
    can_apply?: boolean;
  };
  has_team: boolean;
  /**
   * 阻止投递的定制化.
   * customization for stopping apply the job.
   */
  suppress_apply: {
    is_suppress_apply: boolean;
    suppress_apply_data: LooseObject;
  };

  // modules.
  module_feature: {
    data: unknown[];
  };
  module_team: {
    title?: string;
    sub_type?: string;
    expandable?: boolean;
    more_link?: string;
    data?: unknown[];
  };
  module_team_position: {
    title: string;
    data: JobItem[];
  };
  module_job_attr: { data: unknown[] };
  module_job_description: { data: unknown[] };
  module_job_need: { data: unknown[] };
  module_position_recommend: { title: string; data: JobItem[] };
  module_intell_recommend: { title: string; data: JobItem[] };
  module_company_info: LooseObject;
  module_job_require: { data: unknown[] };
}

export function createEmptyJdData(): JdData {
  return {
    meta: {
      title: "",
      is_referral: false,
      did: 0,
      city: "",
      experience: "",
      degree: "",
      has_point_reward: false,
      endorse: {
        publisher: 0,
        avatar: "",
        department: "",
        name: "",
        company: "",
        is_hr: false,
      },
      status: 0,
      has_store: false,
      recruit_number: 0,
      salary: "",
    },
    has_team: false,
    suppress_apply: { is_suppress_apply: false, suppress_apply_data: {} },
    module_feature: { data: [] },
    module_job_attr: { data: [] },
    module_job_description: { data: [] },
    module_position_recommend: { title: "", data: [] },
    module_intell_recommend: {
      title: "",
      data: [],
    },
    module_company_info: {},
    module_job_need: { data: [] },
    module_job_require: { data: [] },
    module_team: {
      title: "",
      data: [],
    },
    module_team_position: {
      title: "",
      data: [],
    },
  };
}

export enum JobStatus {
  NORMAL = 0, // 在招.
  DELETE = 1, // 删除.
  DISABLE = 2, // 职位撤下.
}

export interface JobRepostVisitorInfo {
  is_employee: boolean;
  employee_name: string;
  employee_icon: string;
  employee_id: number;
  employee_user_id: number;
}

/*!
使用二进制运算符来计算.

默认环境是 ClientRoleValue.default。
如果侦测到 wechat 环境，则是  ClientRoleValue.default &  ClientRoleValue.wechat。

假如有一个菜单列表，默认环境下全部菜单可见，某些菜单只能在 wechat 环境，
某些菜单只能在 jw 环境下可见。

我们可以这样处理。

const menus = [
	{
		title: '菜单1',
		value: ClientRoleValue.wechat, // 只能微信下可见.
	},

	{
		title: '菜单2',
		value: ClientRoleValue.wechat | ClientRoleValue.jw, // 微信或者jw都可见.
	}
]

const currentEnvValue = ClientRoleValue.wechat;

// 重点, 可以快速的一遍过滤.
filterMenus = menus.filter(v => {
	return v.value & currentEnvValue;
})
 */
export const ClientRoleValue = {
  def: 1 << 0,
  wechat: 1 << 1,
  jw: 1 << 2,
  wxwork: 1 << 3,
};

export interface JobPopupData {
  switch: {
    /**
     * 1：开通  0：关闭.
     */
    recom_info_switch: number;
    /**
     * 1：用户已关闭，qrcode不再提示  0：用户未关闭
     */
    df_pv_qrcode: number;
    /**
     * 1：用户已关闭，简历完善不再提醒  0：用户未关闭
     */
    df_pv_profile: number;
  };
  /**
   * 80% 的简历完整度.
   */
  profile_completeness: number;
  /**
   * 访问当前职位页的次数.
   */
  pv: number;
  /**
   * 不同职位的浏览次数.
   */
  df_pv: number;
}

export enum CandidateSource {
  SOCIAL = 0,
  /**
   * 校招.
   */
  COLLEGE = 1,
  /**
   * 内部招聘职位.
   */
  INTERNAL = 3,
}

export const JOB_MAX_SELECT = 60;
