import { GetterTree } from "vuex";
import State from "../State";
import { MassJobState } from "./model";

const getters: GetterTree<MassJobState, State> = {
  massJobListParams(state, getters, rootState) {
    const keyword = rootState.routeModule.query.keyword;
    const pageNo = state.index.pageNo;
    const pageSize = state.index.pageSize;

    return {
      ...rootState.routeModule.query,
      keyword,
      count: pageNo,
      pageSize,
    };
  },
  isMassJobIndexEmpty(state) {
    return state.index.exhuasted && !state.index.list.length;
  },
  isMassJobIndexSkeletonShow(state) {
    return !state.index.exhuasted && !state.index.list.length;
  },
};

export default getters;
