import getHtmlMetaValue from "../lib/dom/getHtmlMetaValue";

export default function () {
  const xsrfCookie = getCookie("_xsrf");
  if (xsrfCookie) {
    return xsrfCookie;
  }

  const cache = getHtmlMetaValue("csrf-token") || "";
  return cache;
}

/**
 * 获取cookie
 *
 * @todo migrate code to qxjs.
 */
function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`) || [];
  if (parts.length === 2) return (parts.pop() || "").split(";").shift();
}
