import { HttpClient } from "@/shared/types/http.t";
import { urlWithoutCross } from "@/shared/utils/url";
import { AxiosRequestConfig } from "axios";
import getQuery from "../../lib/url/getQuery";
import getXsrf from "../../utils/getXsrf";
import { WechatIdKey } from "../../utils/consts";
import getSignature from "../../utils/getSignature";

/**
 * 会对请求参数处理，其中：
 *
 * 1. 请求链接上会加上一些参数白名单，如果不想添加这些白名单，可以在请求 config
 * 中配置，`withRouteParams: false`.
 */
function configRequestInterceptor(
  config: AxiosRequestConfig & { withRouteParams?: boolean },
): AxiosRequestConfig {
  // 需要经过我们的URL处理下，添加必须的参数.
  let url = config.url || "";
  let params = {} as any;

  if (config.withRouteParams !== false) {
    url = urlWithoutCross(config.url || "");
    params = (getQuery(url) as object) || {};
  } else {
    params[WechatIdKey] = getSignature();
  }

  // @see https://github.com/axios/axios/issues/4193
  // @ts-ignore
  config.headers.common["X-XSRFToken"] = getXsrf();
  // @ts-ignore
  config.headers.common["Accept"] = "application/json";
  // @ts-ignore
  config.headers.common["Content-Type"] = "application/json";
  // @ts-ignore
  config.headers.common["X-Requested-With"] = "XMLHttpRequest";
  config.params = {
    ...params,
    ...config.params,
  };
  // already added the signature by urlWIthoutCross.
  // config.params[WechatIdKey] = getSignature();

  // https://github.com/axios/axios/issues/755
  if (typeof config.data === "undefined") {
    config.data = null;
  }
  return config;
}

export default function interceptor(client: HttpClient) {
  client.interceptors.request.use(configRequestInterceptor);
}
