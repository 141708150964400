<template>
  <qx-popup
    v-model="visibleLazy"
    :closeable="closeable"
    class="QrcodePopup"
    name="QrcodePopup"
    @close="$emit('close')"
  >
    <div class="QrcodePopup-content">
      <slot name="title">
        <h2 class="QrcodePopup__title">
          <slot name="title"
            ><span>{{
              title || $t("follow_account_to_get_recommendation")
            }}</span></slot
          >
        </h2>
      </slot>
      <div class="follow-qrcode-modal">
        <img
          style="-webkit-touch-callout: none"
          class="scope-qrcode-image"
          :src="qrcode"
          alt="qrcode"
        />
      </div>
      <slot name="bottomText" :accountName="name"
        ><span class="scope-bottom-text">{{
          $t("core.qrcodeHoldDown", { company_name: name })
        }}</span></slot
      >

      <slot name="bottomOuter"></slot>
    </div>
  </qx-popup>
</template>

<script>
export default {
  i18n: {
    messages: {
      en: {
        follow_account_to_get_recommendation:
          "Follow us ! Don't miss any job opportunities",
      },
      zh: {
        follow_account_to_get_recommendation: "关注一下，别错过工作机会",
      },
    },
  },

  props: {
    title: String,
    visible: Boolean,
    name: String,
    qrcode: String,
    closeable: Boolean,
  },

  computed: {
    visibleLazy: {
      get() {
        return this.visible;
      },

      set(val) {
        if (!val) {
          this.$emit("close");
        }

        this.$emit("update:visible", val);
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/helper";

@include block(QrcodePopup) {
  border-radius: rem(8px) !important;

  @include element(title) {
    font-size: rem(16px);
    color: var(--qx-theme-1, $clr-primary);
    font-weight: normal;
    margin-top: rem(26px);
    margin-bottom: rem(26px);
  }

  .follow-qrcode-modal {
    position: relative;
  }

  .scope-qrcode-image {
    width: 100%;
    height: auto;
    max-width: 160px;
    box-shadow: 0 2px 7px #ededf3;
  }

  .scope-bottom-text {
    color: #495060;
    font-size: rem(14px);
    margin-top: rem(14px);
    display: inline-block;
  }

  &-content {
    min-width: rem(250px);
    padding-top: rem(24px);
    margin-left: rem(24px);
    margin-right: rem(24px);
    padding-bottom: rem(20px);
    text-align: center;
  }

  .van-popup__close-icon {
    background-color: #f6f6fa;
    color: #b8c1cf;
    border-radius: rem(4px);
    padding: rem(2px);
  }
}
</style>
