import { Module } from "vuex";

import { CommentState } from "./model";
import State from "../State";

import mutations from "./mutations";
import * as actions from "./actions";
import getters from "./getters";
import { DEF_PAGE_SIZE } from "../../shared/utils/consts";
import { defaultPageNo } from "./utils";

const commentModule: Module<CommentState, State> = {
  namespaced: true,
  state: {
    /**
     * careestory页面-精选、全部、参与留言列表
     */
    list: [],
    pageNo: defaultPageNo,
    total: 0,
    pageSize: DEF_PAGE_SIZE,
    exhuasted: false,
    loading: false,
    /**
     * 写留言弹框-我的留言列表
     */
    // myList: [],
    // myPageNo: defaultPageNo,
    // myTotal: 0,
    // myPageSize: DEF_PAGE_SIZE,
    // myExhuasted: false,
    // myLoading: false,
  },

  mutations,
  actions,
  getters,
};

export default commentModule;
