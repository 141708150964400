import { NavigationGuard } from "vue-router";
import { DID } from "../../shared/utils/consts";
import { isRouteNeedAuth, isRouteSassMode } from "../utils";
import { GuardParams } from "./types";

export default function authGuard({ store }: GuardParams): NavigationGuard {
  if (!store) {
    throw new Error("store is required");
  }

  return async (to, from, next) => {
    const goNext = async () => {
      const cid = to.query[DID];
      // 这里路由还没有跳转，所以当前的路由参数还是之前的参数。
      if (isRouteSassMode(to)) {
        await store.dispatch("userModule/fetchCompanyInfoById", cid);
      }
    };

    if (store.getters["userModule/isGlobalExpired"]) {
      // fetch global again, if global return need login, we need do oauth.
      try {
        await store.dispatch("userModule/getGlobal", {
          /**
           * 如果需要授权，则会在失败的时候跳转到授权地址.
           */
          requireAuth: isRouteNeedAuth(to),
          isSassMode: isRouteSassMode(to),
        });
        await goNext();
        // continue the route.
        return next();
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
        // 🧹continue the following logic.
      }
    }

    if (!isRouteNeedAuth(to)) {
      await goNext();
      return next();
    }

    if (store.getters["userModule/hasLogin"]) {
      await goNext();
      return next();
    }

    // store.dispatch("appModule/setNextUrl", to.fullPath);
    store.dispatch("userModule/oauth", {
      nextUrl: to.fullPath,
      confirm: false,
    });
    // abort route.
    next(false);
    // setTimeout(() => {
    //   next({
    //     name: "user.authCallback",
    //   });
    // }, 600);
  };
}
