import sdkDriver, { SdkDriver } from "@/shared/drivers/sdkDriver";
import { SDK_ID } from "./consts";
import isWeixin from "./isWeixin";

const SdkFeatures = {
  getLocation: contextDecorator((sdk: SdkDriver) => {
    return (sdk.is(SDK_ID.WECHAT) && isWeixin()) || sdk.is(SDK_ID.WXWORK);
  }),
};

function contextDecorator(feature: (...args: (any | SdkDriver)[]) => boolean) {
  return function (...args: any[]) {
    let last = args[args.length - 1];

    if (last !== sdkDriver) {
      args.push(sdkDriver);
    }

    // eslint-disable-next-line
    return feature.apply(SdkFeatures, args);
  };
}

export default SdkFeatures;
