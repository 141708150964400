import noop from "lodash/noop";
import Vue from "vue";
import Vuex from "vuex";
// @ts-ignore
import createLogger from "vuex/dist/logger";
import State from "./State";
import appModule from "./appModule";
import commentModule from "./commentModule";
import employeeModule from "./employeeModule";
import jdModule from "./jdModule";
import jobModule from "./jobModule";
import mapModule from "./mapModule";
import massJobModule from "./massJobModule";
import pageModule from "./pageModule";
import vuexPersitPlugin from "./plugins/vuePersist";
import privacyModule from "./privacyModule";
import userModule from "./userModule";

const isDev = process.env.NODE_ENV === "development";
const useLogger = false && isDev;

Vue.config.devtools = isDev;

Vue.use(Vuex);

const store = new Vuex.Store<State>({
  // please add `Module` suffix.
  modules: {
    userModule,
    appModule,
    jobModule,
    mapModule,
    jdModule,
    privacyModule,
    pageModule,
    commentModule,
    employeeModule,
    massJobModule,
  },
  plugins: [vuexPersitPlugin.plugin, useLogger ? createLogger() : noop],
});

export default store;
