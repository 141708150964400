export default function (name: string): string | undefined | null {
  let cache;
  let metas = [] as HTMLElement[];
  let head = window.document.head;
  if (head) {
    metas = Array.prototype.slice.call(head.getElementsByTagName("meta"));
  }

  if (metas && metas.length) {
    for (let i = 0, meta; i < metas.length; i++) {
      meta = metas[i];
      if (meta.getAttribute("name") === name) {
        cache = meta.getAttribute("content");
        return cache;
      }
    }
  }

  return cache;
}
