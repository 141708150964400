import { GetterTree } from "vuex";
import State from "../State";
import { AppState } from "./model";

const getters: GetterTree<AppState, State> = {
  sdkId(state) {
    return Number(state.pageconst.sdkId);
  },

  /**
   * 上一个路由.
   */
  lastAppRoute(state) {
    return state.routeHistory[state.routeHistory.length - 2];
  },

  posterSwitch(state) {
    return state.switchs.poster && state.pageconst.user.isEmployee;
  },

  /**
   * 如果开启了内部招聘，并且是员工的话，需要进入内部招聘场景。
   */
  internalJobMode(state) {
    return (
      state.pageconst.switch.internalJob && state.pageconst.user.isEmployee
    );
  },
  notInternalJobModeDueToNotIsEmployee(state) {
    return !state.pageconst.user.isEmployee;
  },
  notInternalJobModeDueToClosed(state) {
    return !state.pageconst.switch.internalJob;
  },
};

export default getters;
