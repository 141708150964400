import {
  HttpClient,
  HttpResponse,
  HttpResponseData,
} from "@/shared/types/http.t";
import ServerData from "@/shared/http/ServerData";

/**
 * This interceptor wraps a ServerData object
 * @param httpResponse The response returned from Axios-like framework.
 * @example
 * ```js
 * http.get('/user').then((response) => {
 *  // data is of type ServerData.
 *  // response is of type HttpResponse.
 *
 *  const serverData = response.data;
 *  const userId = serverData.get('user.id');
 *  return userId
 * })).then(userId => {
 *  console.log(userId);
 * })
 * ```
 */
function parseResponseInterceptor(
  httpResponse: HttpResponse<HttpResponseData>,
): Promise<HttpResponse<HttpResponseData>> {
  const sd = new ServerData(httpResponse.data);
  httpResponse.data = sd;
  return Promise.resolve(httpResponse);
}

export default function interceptor(client: HttpClient) {
  client.interceptors.response.use(parseResponseInterceptor);
}
