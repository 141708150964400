import keymirror from "keymirror";
import uniqueId from "lodash/uniqueId";
import { Route } from "vue-router";
import { URLS } from "../../../shared/utils/consts";
import url from "../../../shared/utils/url";
/**
 * 块的类型，增强块的功能。
 */
export enum BlockType {
  Notifier = "Notifier",
  Reddot = "Reddot",
  Miniapp = "Miniapp",
  Poster = "Poster",
}
/**
 * 一些特殊的 block 的key，这样可以方便获取这些block，进行修改。
 */
export const BlockKeys = keymirror({
  profile: null,
  /**
   * 人脉雷达
   */
  radar: null,
  /**
   * 海报
   */
  poster: null,
  /**
   * 内推浏览
   */
  referralHistory: null,
  /**
   * 内推进度
   */
  referralProgress: null,
  /**
   * 聊天
   */
  chat: null,
  /**
   * 内推职位.
   */
  referralJobs: null,
  /**
   * 积分排行榜
   */
  ladder: null,
  /**
   * 直达“求推荐”页面。
   */
  requestReferral: null,
  /**
   * 内部招聘政策.
   */
  internalPolicy: null,
  internalEntry: null,
});
export interface Block {
  iconUnicode: string;
  /**
   * 国际化内容见 GridItemMixin.ts
   */
  title_i18n: string;
  link?: string;
  to?: Route;
  id: number | string;
  /**
   * 一些特殊的 block 的key，这样可以方便获取这些block，进行修改。
   */
  key: string;
  color?: string;
  /**
   * 按钮类型，比如小程序，带消息通知的。
   */
  type?: BlockType;
  hide?: boolean;
  params?: { [key: string]: string | number | null | undefined };
}
export interface Group {
  id: string | number;
  title_i18n: string;
  color?: string;
  blocks: Partial<Block>[];
}

export interface BlockContext {
  dot?: boolean;
  badge?: string | number;
  /**
   * 小程序路径
   */
  path?: string;
  /**
   * 按钮的页面地址，动态的。
   */
  link?: string;
}

const i18nkey = (title: string) => `employee.${title}`;
const blockfn = (block: Partial<Block>) => ({
  ...block,
  id: uniqueId(),
});

/**
 * 组的key。
 */
export const GroupPk = {
  /**
   * 基础设置
   */
  primary: "primary",
  /**
   * 内推设置
   */
  referral: "referral",
  /**
   * 奖励&政策
   */
  promote: "promote",
};

/**
 * 分组.
 */
export const groups = [
  {
    id: GroupPk.referral,
    title_i18n: i18nkey(GroupPk.referral),
    blocks: [
      blockfn({
        iconUnicode: "&#xe7ca;",
        title_i18n: i18nkey(BlockKeys.referralProgress),
        key: BlockKeys.referralProgress,
        link: "/employee/referral/progress",
      }),
      blockfn({
        iconUnicode: "&#xe7cd;",
        title_i18n: i18nkey(BlockKeys.radar),
        key: BlockKeys.radar,
        link: URLS.RADAR,
      }),
      blockfn({
        iconUnicode: "&#xe7d5;",
        title_i18n: i18nkey(BlockKeys.referralHistory),
        key: BlockKeys.referralHistory,
        link: URLS.REFERRAL_VIEW,
      }),
      blockfn({
        iconUnicode: "&#xe7ce;",
        title_i18n: i18nkey(BlockKeys.chat),
        key: BlockKeys.chat,
        type: BlockType.Miniapp,
      }),
      blockfn({
        iconUnicode: "&#xe7d4;",
        title_i18n: i18nkey(BlockKeys.poster),
        key: BlockKeys.poster,
        type: BlockType.Poster,
      }),
      blockfn({
        iconUnicode: "&#xe7d8;",
        title_i18n: i18nkey(BlockKeys.referralJobs),
        key: BlockKeys.referralJobs,
        type: BlockType.Miniapp,
      }),
      blockfn({
        iconUnicode: "&#xea0a;",
        title_i18n: i18nkey("requestReferral"),
        key: BlockKeys.requestReferral,
        link: url("/employee/referral/radar_cards/seek_recom"),
      }),
      blockfn({
        iconUnicode: "&#xe848;",
        title_i18n: i18nkey("internalEntry"),
        link: URLS.INTERNAL_JOB,
        key: BlockKeys.internalEntry,
      }),
    ],
  },
  {
    id: GroupPk.promote,
    title_i18n: i18nkey(GroupPk.promote),
    color: "#FFD060",
    blocks: [
      blockfn({
        iconUnicode: "&#xe7d7;",
        title_i18n: i18nkey("pointRewards"),
        link: URLS.POINTS_MALL_INDEX,
        key: "POINTS_MALL",
      }),
      blockfn({
        iconUnicode: "&#xe7d3;",
        title_i18n: i18nkey("awardBonus"),
        link: URLS.AWARD_BONUS,
        key: "AWARD_BONUS",
      }),
      blockfn({
        iconUnicode: "&#xe7d2;",
        title_i18n: i18nkey("ladder"),
        link: URLS.LADDER,
        key: BlockKeys.ladder,
      }),
      blockfn({
        iconUnicode: "&#xe7d6;",
        title_i18n: i18nkey("referralRules"),
        link: URLS.REFERRAL_POLICY,
      }),
      blockfn({
        iconUnicode: "&#xe848;",
        title_i18n: i18nkey("internalPolicy"),
        link: URLS.INTERNAL_POLICY,
        key: BlockKeys.internalPolicy,
      }),
    ],
  },
  {
    id: GroupPk.primary,
    title_i18n: i18nkey(GroupPk.primary),
    color: "#FFD060",
    blocks: [
      // 个人档案
      blockfn({
        iconUnicode: "&#xe7d1;",
        title_i18n: i18nkey("myProfile"),
        link: URLS.PROFILE,
        key: BlockKeys.profile,
        type: BlockType.Miniapp,
      }),
      // 账号设置
      blockfn({
        iconUnicode: "&#xe7cf;",
        title_i18n: i18nkey("accountSetting"),
        link: URLS.ACCOUNT_SETTING,
      }),
    ],
  },
];
