// import map from "lodash/map";
import { INDEX_TAB_KEY, TEAM_NAME } from "@/shared/utils/consts";
import each from "lodash/each";
import isNil from "lodash/isNil";
import { ActionContext } from "vuex";
import {
  fetchAdvanceSeq,
  fetchAiJobs,
  fetchJobs as fetchJobs_,
  fetchJobShareId,
} from "../../api/job";
import { INDEX_TAB, JOB_MAX_SELECT } from "../../shared/models/job";
import { collectValueFromQuery } from "../../shared/modules/dynamic-search/util";
// import { SALARY } from "../../shared/utils/consts";
import { LooseObject } from "../../shared/types/value.t";
import types from "../mut-types";
import State from "../State";
import { JobState } from "./model";

type AC = ActionContext<JobState, State>;

function limitAdvanceDataItems<T>(items: Array<T>): Array<T> {
  if (!items) {
    return items;
  }
  return items.slice(0, 4);
}

export function changeTabKey({ commit }: AC, value: INDEX_TAB) {
  commit(types.JOB_CHANGE_TAB_KEY, value);
}

export async function fetchEmpRecomJobs({ state, commit, getters }: AC) {
  commit(types.EMP_JOB_SET_LOADING, true);

  try {
    const args = getters.empJobSearchParams;
    const serverData = await fetchJobs_({
      ...args,
      is_referral: 1,
    });
    const list = serverData.get("list", []) as [];
    const total = serverData.get("total_count", 0) as number;
    const prevPageNo = state.emprecom.pageNo;
    const totalPages = Math.ceil(total / state.emprecom.pageSize);

    commit(types.EMP_JOB_INC_PAGENO);
    commit(types.EMP_JOB_SET_LOADING, false);

    if (!list.length || prevPageNo > totalPages) {
      commit(types.EMP_JOB_SET_EXHUASTED, true);
    }

    if (!list.length) {
      return;
    }

    commit(types.EMP_JOB_SET_LIST, list);
    commit(types.EMP_JOB_SET_TOTAL, total);
  } catch (Err) {
    commit(types.EMP_JOB_SET_LOADING, false);
    throw Err;
  }
}

export async function fetchMoreAiJobs({ state, commit, getters }: AC) {
  commit(types.AI_JOB_SET_LOADING, true);
  commit(types.AI_JOB_INC_PAGENO); // page_no从1开始

  try {
    const args = getters.aiJobSearchParams;
    const serverData = await fetchAiJobs(args);
    const list = serverData.get("list", []) as [];
    const total = serverData.get("total", 0) as number;
    const prevPageNo = state.airecom.pageNo;
    const totalPages = Math.ceil(total / state.airecom.pageSize);

    commit(types.AI_JOB_SET_LOADING, false);

    if (!list.length || prevPageNo > totalPages) {
      commit(types.AI_JOB_SET_EXHUASTED, true);
    }

    if (!list.length) {
      return;
    }

    commit(types.AI_JOB_INC_PAGENO);
    commit(types.AI_JOB_SET_LIST, list);
    commit(types.AI_JOB_SET_TOTAL, total);
  } catch (Err) {
    commit(types.AI_JOB_SET_LOADING, false);
    throw Err;
  }
}

export async function fetchMoreInternalJobs({ state, commit, getters }: AC) {
  commit(types.INT_JOB_SET_LOADING, true);

  try {
    const args = getters.internalJobSearchParams;
    const serverData = await fetchJobs_({
      ...args,
      internal_position: true,
    });
    const list = serverData.get("list", []) as [];
    const total = serverData.get("total", 0) as number;
    const prevPageNo = state.internal.pageNo;
    const totalPages = Math.ceil(total / state.internal.pageSize);

    commit(types.INT_JOB_SET_LOADING, false);

    if (!list.length || prevPageNo > totalPages) {
      commit(types.INT_JOB_SET_EXHUASTED, true);
    }

    if (!list.length) {
      return;
    }

    commit(types.INT_JOB_INC_PAGENO);
    commit(types.INT_JOB_SET_LIST, list);
    commit(types.INT_JOB_SET_TOTAL, total);
  } catch (Err) {
    commit(types.INT_JOB_SET_LOADING, false);
    throw Err;
  }
}

export function incJobIndexFetchingKey({ commit }: AC) {
  commit(types.JOB_INC_FETCHING_KEY);
}

export async function fetchMoreJobs({ state, commit, getters }: AC) {
  commit(types.JOB_SET_INDEX_LOADING, true);

  const fetchingKey = state.index.fetchingKey;

  try {
    const args = getters.searchParams;
    const serverData = await fetchJobs_(args);
    const list = serverData.get("list", []) as [];
    const total = serverData.get("total_count", 0) as number;
    const prevPageNo = state.index.pageNo;

    if (fetchingKey !== state.index.fetchingKey) {
      return;
    }

    commit(types.JOB_INC_INDEX_PAGENO);
    commit(types.JOB_SET_INDEX_LOADING, false);

    const totalPages = Math.ceil(total / state.index.pageSize);

    if (!list.length || prevPageNo > totalPages) {
      // no more job items.
      commit(types.JOB_SET_INDEX_EXHUASTED, true);
    }

    if (!list.length) {
      return;
    }

    commit(types.JOB_SET_INDEX_LIST, list);
    commit(types.JOB_SET_INDEX_TOTAL, total);
  } catch (err) {
    commit(types.JOB_SET_INDEX_LOADING, false);
    throw err;
  }
}

export async function reloadJobIndexList({ commit, dispatch }: AC) {
  commit(types.JOB_INDEX_RESET);
  await dispatch("fetchMoreJobs");
  commit(types.JOB_REFRESH_LAYOUT_TICK);
}

export async function reloadEmpJobList({ commit, dispatch }: AC) {
  commit(types.EMP_JOB_RESET);
  await dispatch("fetchEmpRecomJobs");
  commit(types.JOB_REFRESH_LAYOUT_TICK);
}

export async function reloadAiJobList({ commit, dispatch }: AC) {
  commit(types.AI_JOB_RESET);
  await dispatch("fetchMoreAiJobs");
  commit(types.JOB_REFRESH_LAYOUT_TICK);
}
export async function reloadInternalJobList({ commit, dispatch }: AC) {
  commit(types.INT_JOB_RESET);
  await dispatch("fetchMoreInternalJobs");
  commit(types.JOB_REFRESH_LAYOUT_TICK);
}

export async function toggleBulkShareItem({ commit, state }: AC, pid: number) {
  const MAX = JOB_MAX_SELECT;
  const isAdd = state.bulkshare.pids.indexOf(pid) === -1;

  if (state.bulkshare.pids.length >= MAX && isAdd) {
    return false;
  }
  commit(types.BULK_SHARE_TOGGLE_ITEM, pid);
  return true;
}
export function toggleJobIndexSelectable({ commit }: AC, payload: boolean) {
  commit(types.JOB_TOGGLE_SELECTABLE, payload);
}

export function toggleEmpJobRecomSelectable({ commit }: AC, payload: boolean) {
  commit(types.EMP_TOGGLE_SELECTABLE, payload);
}

//#region  bulk share
export function bulkShareClear({ commit }: AC) {
  commit(types.BULK_SHARE_CLEAR);
}

export async function fetchJobShareData({ commit }: AC, pids: number[]) {
  if (!pids) {
    return;
  }

  const serverData = await fetchJobShareId({
    pids: pids,
  });

  const shareData = serverData.data;

  commit(types.BULK_SHARE_SET_SHARE_ID, shareData.share_id);

  return shareData;
}

export async function fetchBulkShareJobList({ commit }: AC, args: object) {
  commit(types.BULK_SHARE_SET_LOADING, true);

  try {
    const serverData = await fetchJobs_({
      ...args,
      count: 0,
      pageSize: 10,
    });
    commit(types.BULK_SHARE_SET_LOADING, false);
    // if user enter edit mode, do not use remote data.

    const list = serverData.get("list", []) as Array<any>;
    const pids = list.map((job) => job.id) || [];

    /**
     * pids 和 list (包含详细的职位信息的列表)必须保持同步.
     */
    commit(types.BULK_SHARE_SET_PIDS, pids);
    commit(types.BULK_SHARE_SET_LIST, list);
  } catch (err) {
    commit(types.BULK_SHARE_SET_LOADING, false);
    throw err;
  }
}

//#endregion bulk share

//#region advance search sequences
export async function fetchAdvanceSearchSeq(
  { commit, state, rootState }: AC,
  args?: any,
) {
  if (state.adsearch.datasource) {
    return state.adsearch.datasource;
  }

  const q = rootState.routeModule.query;

  const serverData = await fetchAdvanceSeq({
    [INDEX_TAB_KEY]: state.tabKey,
    ...q,
    ...args,
  });
  const data = serverData.data;

  // https://www.tapd.cn/43037250/prong/stories/view/1143037250001021884
  // 升级到 UI2.0后，选项数据可能还是大于目前可以展示的数目，所以需要截取。
  data.field_name = limitAdvanceDataItems(data.field_name);
  data.field_form_name = limitAdvanceDataItems(data.field_form_name);

  /** 保存原数据，后面用到 */
  commit(types.AS_SET_DATASOURCE, data);

  return data;
}

export function notifyAdavanceSearchChange({ commit }: AC) {
  commit(types.AS_CHANGE_TS);
}

/**
 * 初始化高级搜索的选项值，这些选项值会在链接上带来带去，如果链接上有，需要初始化。
 */
export function initAdvanceSearchModel({
  commit,
  rootState,
  state,
  getters,
}: AC) {
  if (!state.adsearch.datasource) {
    // eslint-disable-next-line
    console.error(new Error("please fetch the advance search seq first"));
    return;
  }

  const fieldKeys = state.adsearch.datasource?.field_form_name || [];
  const fieldValues = state.adsearch.datasource?.values || [];
  const query = rootState.routeModule.query || {};
  const whitelist = (state.adsearch.datasource?.support_condition || []).concat(
    [
      // 团队已经不和其他字段联动了，所以团队的值要单独存储.
      // 团队的文本值用 team_name 来表示(在support_condition 里面)
      "team_name_id",
    ],
  );
  let model = collectValueFromQuery(fieldKeys, query, whitelist);

  // if (model[SALARY]) {
  //   model[SALARY] = getExistSalary(
  //     model[SALARY],
  //     fieldValues,
  //     fieldKeys,
  //     SALARY,
  //   );
  // }

  const unlinkKeys: string[] = [];
  if (getters.enableTeamTreePicker) {
    unlinkKeys.push(TEAM_NAME);
  }
  model = cleanupInvalidField(model, fieldValues, fieldKeys, unlinkKeys);
  commit(types.AS_CHANGE_TS, 0);
  commit(types.AS_SET_MODEL, model);
}

export function updateAdvanceSearchModel(
  { commit, state }: AC,
  payload: { key: string; value: string },
) {
  const model = {
    ...state.adsearch.model,
    ...payload,
  };

  commit(types.AS_SET_MODEL, model);
}

/**
 * 如果链接上有参数 position_field_1=A，但是后台设置里，已经把 A 这个选项值给删了。我们说 position_field_1=A 这个参数是无效的，
 * 要从 model 里给删除，这样跳过去后就没这个无效的key=value了。
 * @param {Array<Array<object>>} fieldValues
 */
function cleanupInvalidField(
  model: LooseObject,
  fieldValues: any[],
  fieldKeys: any[],
  unlinkKeys: (string | number)[],
): LooseObject {
  unlinkKeys = unlinkKeys || [];

  each(fieldKeys, (k, index) => {
    const mv = model[k];
    if (mv === "" || isNil(mv)) {
      return;
    }

    // 团队已经脱离了高级搜索的关联逻辑，数据源来自
    // 另外的单独接口，暂时无法做有效值的校验。
    if (unlinkKeys.indexOf(k) !== -1) {
      return;
    }

    let isValidVal = false;
    each(fieldValues, (column) => {
      const item = column[index];

      if (item && item.value == mv) {
        isValidVal = true;
        return;
      }
    });
    if (!isValidVal) {
      model[k] = undefined;
    }
  });

  return model;
}
//#endregion
