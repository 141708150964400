import { GetterTree } from "vuex";
import State from "../State";
import { CommentState } from "./model";

const getters: GetterTree<CommentState, State> = {
  /**
   * careestory页面-精选、全部、参与留言列表
   */
  isCommentEmpty(state) {
    return state.exhuasted && !state.list.length;
  },
  isCommentSkeletonShow(state) {
    return !state.list.length && !state.exhuasted;
  },

  /**
   * 写留言弹框-我的留言列表
   */
  // isMyCommentEmpty(state) {
  //   return state.myExhuasted && !state.myList.length;
  // },
  // isMyCommentSkeletonShow(state) {
  //   return !state.myList.length && !state.myExhuasted;
  // },
};

export default getters;
