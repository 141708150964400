import safeJson from "@dqjs/libcore/lib/safeJson";
import { sdk } from "@/shared/drivers/sdkDriver";
import { runOnMap } from "@dqjs/mapcore/lib/api/tencent";
import { getAddressByLocation } from "@dqjs/mapcore/lib/impl/tencent";
import store from "@/store";
import {
  InjectableApi,
  MapGpsType,
  QxLocationRequestResult,
} from "@dqjs/mapcore/lib/shared";
import TencentMapHandle from "@dqjs/mapcore/lib/shared/TencentMapHandle";
import { TencentApiKey } from "@dqjs/mapcore/lib/shared/key";
import SdkFeatures from "../../shared/utils/SdkFeatures";

/**
 *
 * @returns 48h 内是否取消过获取位置
 */
const isUserCanceledBefore = () => {
  const storeValue =
    safeJson<{
      value: string;
      timestamp: number;
    }>(localStorage.getItem("wxMapSdkCancel")) || ({} as any);

  const { value, timestamp } = storeValue;
  const now = Date.now();
  const diff = now - timestamp;
  if (diff > 1000 * 60 * 60 * 48) {
    localStorage.removeItem("wxMapSdkCancel");
    return false;
  }
  return value == "1";
};
const userCanceledLocationRequest = () => {
  localStorage.setItem(
    "wxMapSdkCancel",
    JSON.stringify({
      value: "1",
      timestamp: Date.now(),
    }),
  );
};

export async function getLocationByWechatSdkInjector(
  args: {
    gpsFormat?: MapGpsType;
    adcode?: boolean;
    full?: boolean;
    locationAuthDebounce?: boolean;
  },
  handle: TencentMapHandle,
  caller: InjectableApi<any>,
) {
  try {
    await store.dispatch("privacyModule/runPrivacyCheck", { force: true });
  } catch (e) {
    return;
  }

  const sdkRequestFailHandler = () => {
    // this is used to uniform the fail handler
  };

  if (!SdkFeatures.getLocation()) {
    return caller(args, handle);
  }

  const userCanceledError = new Error("User canceled getLocation call");
  if (isUserCanceledBefore() && args.locationAuthDebounce) {
    return caller(args, handle);
  }

  const task = new Promise<Partial<QxLocationRequestResult>>(
    (resolve, reject) => {
      sdk
        .exec("getLocation", {
          type: args.gpsFormat || MapGpsType.Common,
          success(res: any = {}) {
            const { latitude, longitude } = res;
            return resolve({
              coord: {
                lat: latitude as unknown as number,
                lng: longitude as unknown as number,
              },
            });
          },

          fail(_err: any) {
            return reject(new Error("获取位置失败"));
          },

          cancel() {
            userCanceledLocationRequest();
            // this will be called when user click cancel button
            return reject(userCanceledError);
          },
        })
        .then(null, sdkRequestFailHandler);
    },
  );

  try {
    const res = await task;
    if (args.full === false) {
      return res;
    }

    const { coord } = res;
    return getAddressByLocation(
      {
        coord: coord!,
      },
      handle,
    );
  } catch (err) {
    if (caller) {
      return caller(args, handle);
    } else {
      throw err;
    }
  }
}

const mapHandle = new TencentMapHandle(TencentApiKey);
mapHandle.injectors.getCurrentLocation = getLocationByWechatSdkInjector;

export { TencentApiKey, mapHandle, runOnMap };
