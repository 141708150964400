/**
 * @file get query part from string.
 */
import getQuery from "@dqjs/libcore/lib/getQuery";

export default getQuery;

let cached_ = null as any;
export function params(opts = {} as any) {
  opts = opts || {};
  let noCache = opts.noCache;

  if (!noCache && cached_) {
    return cached_;
  }

  let s = window.location.search || "";
  cached_ = getQuery(s) || {};

  return cached_;
}
