import http, { unwrap } from "@/shared/http";
import { cross } from "@/shared/utils/url";

const API_USER = cross("/api/v2/user");

export async function fetchGlobal(
  args = {},
  data?: any,
  requireAuth?: boolean,
) {
  return await unwrap(
    http.get(API_USER, {
      params: args,
      data: data,
      requireAuth: requireAuth,
    }),
  );
}
