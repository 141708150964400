import { Module } from "vuex";
import { DEF_PAGE_SIZE } from "../../shared/utils/consts";
import { defaultPageNo } from "../jobModule/utils";
import State from "../State";
import * as actions from "./actions";
import getters from "./getters";
import { MassJobState } from "./model";
import mutations from "./mutations";

const massJobModule: Module<MassJobState, State> = {
  namespaced: true,
  state: {
    index: {
      list: [],
      pageNo: defaultPageNo,
      total: 0,
      pageSize: DEF_PAGE_SIZE,
      exhuasted: false,
      loading: false,
      fetchingKey: 1,
    },
  },
  mutations,
  getters,
  actions,
};

export default massJobModule;
