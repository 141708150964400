/**
 * @file Make image url absolute.
 */

import isFileUrl from "@dqjs/libcore/lib/isFileUrl";
import normalizeFileUrl from "@dqjs/libcore/lib/normalizeFileUrl";
import assetUrl from "./assetUrl";
import url from "./url";

let imageUrlRegex =
  /^(https?:)?(\/\/)?[\w\-_\d]+\.[a-zA-Z]+(\.\w+){0,4}\/.*(\.\w+)?$/;
let ip =
  /^(https?:)?(\/\/)?(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/.*)?$/;

export default function imageUrl(url: string, prefix?: string): string {
  if (isFileUrl(url)) {
    return fileUrl(url);
  }

  return encodeURI(imageUrl_(url, prefix));
}

export function imageUrl_(url: string, prefix?: string): string {
  if (!url) {
    return "";
  }

  if (url.indexOf("/m/file/preview") === 0) {
    // 避免嵌套
    return url;
  }

  // base64 image.
  if (url.indexOf("data:") === 0) {
    return url;
  }

  if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
    return url;
  }

  if (url.indexOf("//") === 0) {
    return `https:${url}`;
  }

  // If it's an absolute url.
  if (imageUrlRegex.test(url)) {
    return url;
  }

  // If it's an ip
  const toTestAsIp = url.indexOf(":") === -1 ? url : url.split(":")[0];
  if (ip.test(toTestAsIp)) {
    return url;
  }

  return assetUrl(url, prefix);
}

export function fileUrl(u: string) {
  if (!u) {
    return "";
  }

  const normalized = normalizeFileUrl(u);
  if (!normalized.url) {
    return "";
  }

  return url("/file/preview", {
    url: normalized.url,
  });
}
