import { UrlPrefix, WechatIdKey } from "@/shared/utils/consts";
import changeQuery from "@dqjs/libcore/lib/changeQuery";
import assign from "lodash/assign";
import isNil from "lodash/isNil";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import queryString, { type ParsedQuery } from "query-string";
import { Location } from "vue-router";
import getSignature from "./getSignature";
import queryWhitelist from "./queryWhitelist";

export { ParsedQuery };

const hasOwn = Object.hasOwnProperty;

let wechatId: string | null = null;

/**
 * 这里有个问题，就是 vue-router 更新 路由后，这里获取到的参数，可能是路由更新前的参数。
 * @see `src/router/authGuard.ts`
 */
function defGetUrlQuery(): ParsedQuery {
  const search = window.location.search;
  return queryString.parse(search, { decode: true });
}

export interface UrlQueryGetter {
  (): ParsedQuery;
}

export interface UrlOptions {
  queryGetter?: UrlQueryGetter;
  encode?: boolean;
  prefix?: boolean;
  // append all query in current url?
  appendAll?: boolean;
}

/**
 * 添加链接前缀.
 * 微信端链接前缀是 /m
 * @param path
 */
export function cross(path: string, prefix: string | boolean = true): string {
  if (prefix === false) {
    return path;
  }
  if (prefix === true) {
    prefix = UrlPrefix;
  }

  if (path && /^https?:\/\//.test(path)) {
    return path;
  }

  if (path === "/") {
    path = "";
  }

  if (prefix[prefix.length - 1] === "/") {
    prefix = prefix.slice(0, prefix.length - 1);
  }

  if (path.indexOf(prefix) === 0) {
    return path;
  }

  const l = prefix;
  return l + (path[0] === "/" || !path[0] ? "" : "/") + path;
}

export const urlWithoutCross = urlInternal;

export function mergeQueryWithWhitelist(
  currentQuery: any,
  incomeQuery: any,
  options?: LocationFuncOptions,
) {
  options = assign({}, options);

  const signature =
    incomeQuery[WechatIdKey] || currentQuery[WechatIdKey] || getSignature();

  if (!currentQuery) {
    return {
      ...incomeQuery,
      [WechatIdKey]: signature,
    };
  }

  let merge: any = {};

  if (options.appendAll) {
    merge = omitBy(
      {
        ...currentQuery,
        ...incomeQuery,
      },
      isUndefined,
    );
  } else {
    let value;
    for (let key of queryWhitelist) {
      value = incomeQuery[key];
      if (value === undefined) {
        value = currentQuery[key];
      }

      if (value !== undefined) {
        merge[key] = value;
      }
    }

    merge = {
      ...merge,
      ...incomeQuery,
    };
  }

  if (options.remove && options.remove.length) {
    options.remove.forEach((key) => {
      delete merge[key];
    });
  }

  merge[WechatIdKey] = signature;
  return merge;
}

export interface LocationFuncOptions {
  /**
   * 是否加上当前链接上的所有参数.
   */
  appendAll?: boolean;
  remove?: string[];
}

/**
 * Vue Router Location.
 *
 * 用于生成 vue-router 需要的 location对象. 在 vue 里，请使用
 * this.$location 方法。
 */
export function vrl(
  location: Location,
  options?: LocationFuncOptions & {
    inquery?: any;
  },
): Location {
  options = assign({}, options);

  if (typeof window === "undefined") {
    return location;
  }

  const query = location.query || {};
  const splits = window.location.href.split("?")[-1];
  const currentQuery = options.inquery
    ? options.inquery
    : queryString.parse(splits[splits.length - 1] || "", {
        decode: false,
      });
  const merged = mergeQueryWithWhitelist(currentQuery, query, options);

  if (options.remove && options.remove.length) {
    options.remove.forEach((key) => {
      delete merged[key];
    });
  }

  return {
    ...location,
    query: merged,
  };
}

/**
 *
 * @param path
 * @param query - If value is undefined, delete the query. undefined: is always deleted, null is set if in url.
 * @param options<UrlOptions>
 */
function urlInternal(
  path: string,
  query?: {
    [key: string]: undefined | null | string | number;
  } | null,
  options?: UrlOptions,
): string {
  if (!path) {
    return path;
  }

  options = assign(
    {},
    {
      encode: true,
    },
    options,
  );

  options.queryGetter = options.queryGetter || defGetUrlQuery;

  query = query || {};

  let toBuild: {
    [key: string]: any;
  } = {};
  const qs = options.queryGetter() || {};

  if (!options.appendAll) {
    queryWhitelist.forEach((item) => {
      if (!isNil(qs[item])) {
        toBuild[item] = qs[item];
      }
    });

    let valueInQuery: any = null;
    for (const name in query) {
      if (!hasOwn.call(query, name)) {
        continue;
      }

      valueInQuery = query[name];
      if (!isNil(valueInQuery)) {
        toBuild[name] = valueInQuery;
      } else if (valueInQuery === undefined && name !== WechatIdKey) {
        delete toBuild[name];
      } else if (valueInQuery === null && qs[name]) {
        toBuild[name] = qs[name];
      }
    }
  } else {
    toBuild = {
      ...qs,
      ...query,
    };
  }

  if (typeof toBuild[WechatIdKey] !== "string") {
    toBuild[WechatIdKey] = getSignature();
  }

  return changeQuery(path, toBuild, {
    encode: options.encode,
  });
}

export function uncross(path: string): string {
  if (!path) {
    return path;
  }

  if (!UrlPrefix) {
    return path;
  }

  if (path.indexOf(UrlPrefix) !== 0) {
    return path;
  }

  return path.replace(UrlPrefix, "");
}

/**
 * Get full url without host and protocol.
 */
export function getCurrentUrl(): string {
  const pathname = window.location.pathname;
  const search = window.location.search;

  return pathname + search;
}

// current relative url without router base.
export function getAppUrl(): string {
  const url = getCurrentUrl();

  return uncross(url);
}

export function getWechatId(): string {
  if (wechatId) {
    return wechatId;
  }

  wechatId = (defGetUrlQuery()[WechatIdKey] as string) || "";

  return wechatId;
}

/**
 * @returns 不带域名的相对地址链接.
 */
export default function url(
  path: string,
  query?: {
    [key: string]: undefined | null | string | number;
  } | null,
  options?: UrlOptions,
) {
  path = cross(path);

  return urlInternal(path, query, options);
}

/**
 * 返回带域名的链接。
 */
export function absUrl(link: string, query?: any, options?: any) {
  if (!link) {
    return link;
  }

  let location = window.location;
  let host = location.host;
  let protocol = location.protocol;

  let origin = `${protocol}//${host}`;
  let newUrl = url(link, query, options);

  let absLink = origin + (newUrl[0] === "/" ? newUrl : "/" + newUrl);

  return absLink;
}

export function splitUrl(link: string): {
  path: string;
  query: Record<string, any>;
} {
  if (typeof link !== "string" || !link) {
    return {
      path: "",
      query: {},
    };
  }

  const [path, qs] = link.split("?");
  return {
    path,
    query: queryString.parse(qs, {
      decode: true,
    }),
  };
}
