import KeyMirror from "keymirror";
import Vue from "vue";

const constants = KeyMirror({
  SCROLL_AT_BOTTOM: null,
  /**
   * 重新加载列表.
   */
  JOB_LIST_RELOAD: null,
});

export interface Events extends Vue {
  constants: typeof constants;
}

const events: Events = new Vue();

events.constants = constants;

export default events;
