/**
 * 在这里注册管理 过滤器.
 */
import OurVue from "vue";
// import urlFilter from "./urlFilter";

export function registerFilters(Vue: typeof OurVue) {
  // Vue.filter("url", urlFilter);
  return Vue;
}
