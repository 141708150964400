import getConfig from "@/shared/utils/getConfig";
import { isSandbox } from "@/shared/utils/env";

// @see https://www.tapd.cn/20772051/bugtrace/bugs/view?bug_id=1120772051001038474

const COMPANY_LIST = ["3325383", "601320"];
if (isSandbox()) {
  COMPANY_LIST.push("39978");
}

export default function isHideMoseekerSeal() {
  const cid = getConfig("cid") + "";
  return COMPANY_LIST.indexOf(cid) !== -1;
}
