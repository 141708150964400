import { HttpResponseData, ResponsePayloadData } from "@/shared/types/http.t";
import { ValueOf } from "@/shared/types/value.t";
import get from "lodash/get";

export default class ServerData<T = ResponsePayloadData>
  implements HttpResponseData<T>
{
  readonly _payload: Pick<HttpResponseData<T>, "data" | "message" | "status">;

  constructor(
    serverData: Pick<HttpResponseData<T>, "data" | "message" | "status">,
  ) {
    this._payload = serverData;
  }

  // business data.
  get data() {
    return this._payload.data;
  }

  // business code.
  get status() {
    return this._payload.status;
  }

  // business message.
  get message() {
    return this._payload.message;
  }

  /**
   *
   * @param path The key path.
   * @param defaultValue AnyValue
   * @example
   * ```js
   * const userId = serverData.get('user.id');
   * ```
   */
  public get<P>(path: string, defaultValue?: P): ValueOf<T> | P {
    return get(this.data, path, defaultValue) as ValueOf<T> | P;
  }

  public has(key: keyof T): boolean {
    if (typeof this.data === "undefined") {
      return false;
    }

    if (typeof this.data === "object" && this._payload !== null) {
      return this.data && typeof this.data[key] !== "undefined";
    }

    return false;
  }

  reduce<T>(data: T): ServerData<T> {
    return new ServerData({
      data: data,
      status: this.status,
      message: this.message,
    });
  }
}
