import { ActionContext } from "vuex";
import { fetchMassJobs } from "../../api/job";
import types from "../mut-types";
import State from "../State";
import { MassJobState } from "./model";

type AC = ActionContext<MassJobState, State>;

export async function fetchMoreMassJobs({ state, commit, getters }: AC) {
  commit(types.MassJobIndexSetLoading, true);

  const fetchingKey = state.index.fetchingKey;

  try {
    const args = getters.massJobListParams;
    const sd = await fetchMassJobs(args);
    const list = sd.get("list", []) as [];
    const total = sd.get("total_count", 0) as number;
    const prevPageNo = state.index.pageNo;

    if (fetchingKey !== state.index.fetchingKey) {
      return;
    }

    commit(types.MassJobIndexIncPage);
    commit(types.MassJobIndexSetLoading, false);

    const totalPages = Math.ceil(total / state.index.pageSize);

    if (!list.length || prevPageNo > totalPages) {
      // no more jobs
      commit(types.MassJobIndexSetExhuasted, true);
    }

    if (!list.length) {
      return;
    }

    commit(types.MassJobIndexSetList, list);
    // set total
  } catch (err) {
    commit(types.MassJobIndexSetLoading, false);
    throw err;
  }
}

export async function reloadMassJobList({ commit, dispatch }: AC) {
  commit(types.MassJobIndexReset);
  await dispatch("fetchMoreMassJobs");
}

export function incMassJobIndexFetchingKey({ commit }: AC) {
  commit(types.MassJobIndexIncFetchingKey);
}
