import get from "lodash/get";

/**
 * 获取页面上，以 app.config_ 开头的变量。
 *
 * @example
 * ```js
 * const uid = getConfig('user.id');
 * ```
 */
export default function getConfig(path: string) {
  const dotpath = "config_" + path;

  if (!path) {
    return null;
  }

  return get(window.app || {}, dotpath);
}
