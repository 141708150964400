import { publicRoutes as _ } from "../utils";

const NotFound = () =>
  import(/* webpackChunkName: 'NotFound' */ "../../views/common/NotFound.vue");

// public, no need to auth.

export default _([
  {
    path: "/app/auth/callback",
    name: "user.authCallback",
    component: () =>
      import(
        /* webpackChunkName: "authCallback" */ "../../views/user/AuthCallback.vue"
      ),
  },

  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
  },

  // {
  //   path: "/--debug",
  //   name: "Debug",
  //   component: () =>
  //     import(/* webpackChunkName: "debug" */ "../../views/common/Debug.vue"),
  // },

  {
    path: "*",
    component: NotFound,
  },
]);
