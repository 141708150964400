import { env } from "@/shared/utils/env";

let cdn = "";

if (env.APP_CDN_URL) {
  cdn = env.APP_CDN_URL;
} else if (env.NODE_ENV === "production") {
  cdn = "//cdn.moseeker.com";
} else if (env.NODE_ENV === "development") {
  // local
  cdn = "";
} else {
  // dev server or other.
  cdn = "//static.dqprism.com";
}

const PROTOCOL = typeof window === "undefined" ? "" : window.location.protocol;

/**
 * 必须要拼接上协议.
 * 比如微信分享的图片比需要有协议.
 *
 * @param prefix - CDN 地址.
 */
export default function assetUrl(uri: string, prefix?: string): string {
  prefix = prefix || cdn;
  if (!uri) {
    return "";
  }

  /**
   * // 开头的地址.
   */
  if (uri.indexOf("//") === 0) {
    return PROTOCOL + uri;
  }

  if (prefix.indexOf("//") === 0) {
    prefix = PROTOCOL + prefix;
  }

  return prefix + (uri[0] === "/" ? uri : "/" + uri);
}
