import { MutationTree } from "vuex";
import { defaultPageNo } from "../jobModule/utils";
import mutTypes from "../mut-types";
import { MassJobState } from "./model";

const types = mutTypes;

const mutations: MutationTree<MassJobState> = {
  [types.MassJobIndexSetList](state, payload) {
    state.index.list = state.index.list.concat(payload);
  },
  [types.MassJobIndexReset](state) {
    state.index.list = [];
    state.index.exhuasted = false;
    state.index.pageNo = defaultPageNo;
    state.index.total = 0;
  },
  [types.MassJobIndexSetExhuasted](state, payload) {
    state.index.exhuasted = !!payload;
  },
  [types.MassJobIndexIncPage](state) {
    state.index.pageNo += 1;
  },
  [types.MassJobIndexSetLoading](state, payload) {
    state.index.loading = !!payload;
  },
  [types.MassJobIndexIncFetchingKey](state) {
    state.index.fetchingKey += 1;
  },
};

export default mutations;
