import { INDEX_TAB } from "@/shared/models/job";
import { Module } from "vuex";
import { DEF_PAGE_SIZE } from "../../shared/utils/consts";
import State from "../State";
import * as actions from "./actions";
import getters from "./getters";
import { JobState } from "./model";
import mutations from "./mutations";
import { defaultPageNo } from "./utils";

const jobModule: Module<JobState, State> = {
  namespaced: true,
  state: {
    tabKey: INDEX_TAB.ALL,
    index: {
      list: [],
      pageNo: defaultPageNo,
      total: 0,
      pageSize: DEF_PAGE_SIZE,
      exhuasted: false,
      loading: false,
      selectable: false,
      fetchingKey: 0,
      // searchParams: {},
    },
    adsearch: {
      model: {},
      datasource: null,
      columns: [],
      changeTs: 0,
    },
    bulkshare: {
      list: [],
      editMode: false,
      loading: false,
      pids: [],
      shareIds: [],
    },
    airecom: {
      list: [],
      pageNo: defaultPageNo,
      total: 0,
      pageSize: DEF_PAGE_SIZE,
      exhuasted: false,
      loading: false,
    },
    internal: {
      list: [],
      pageNo: defaultPageNo,
      total: 0,
      pageSize: DEF_PAGE_SIZE,
      exhuasted: false,
      loading: false,
    },
    emprecom: {
      list: [],
      pageNo: defaultPageNo,
      total: 0,
      pageSize: DEF_PAGE_SIZE,
      exhuasted: false,
      loading: false,
      selectable: false,
      searchParams: {},
    },
    refreshLayoutTick: 0,
  },

  mutations,
  actions,
  getters,
};

export default jobModule;
