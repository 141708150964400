import raf from "@dqjs/libcore/lib/raf";

const queue = [] as [number, () => void][];
let nextPageTickToken = 0;

export function increaseNextPageTickToken() {
  nextPageTickToken++;
}

/**
 * 在页面跳转时，等待页面渲染完成后再执行某些操作
 */
export function nextPageTick(fn: () => void) {
  raf(() => {
    queue.push([nextPageTickToken, fn]);
  });
}

export function flushNextPageTick() {
  while (queue.length) {
    const [token, fn] = queue.shift()!;
    if (token === nextPageTickToken) {
      fn();
    }
  }
}
