import {
  getPosterSwitch as getPosterSwitch_,
  isRadarEnableValue,
} from "@/api/employee";
import { fetchSmsCountryCodes as fetchSmsCountryCodes_ } from "@/api/func";
import { QxLocation } from "@/shared/types/lib.t";
import getConfig from "@/shared/utils/getConfig";
import assign from "lodash/assign";
import each from "lodash/each";
// import get from "lodash/get";
import raf from "@dqjs/libcore/lib/raf";
import { isMiniApp } from "@dqjs/qxjs/lib/modules/WxOpenTag/isMiniApp";
import findIndex from "lodash/findIndex";
import isNil from "lodash/isNil";
import isPlainObject from "lodash/isPlainObject";
import { ActionContext } from "vuex";
import { fetchChatSwitch, fetchShowChatSwitch } from "../../api/chat";
import { fetchAiSwitch } from "../../api/job";
import { fetchLbsOmsSwitch } from "../../api/lbs";
import getSignature from "../../shared/utils/getSignature";
import State from "../State";
import types from "../mut-types";
import { AppState } from "./model";

type AC = ActionContext<AppState, State>;

export async function setNextUrl({ commit }: AC, inNextUrl: string) {
  commit(types.SET_NEXT_URL, inNextUrl);
}

export function toggleProgressbar({ commit }: AC, payload: boolean) {
  commit(types.TOGGLE_PROGRESS_BAR, payload);
}

export function lock({ commit }: AC, name: string) {
  commit(types.SET_LOCK, { name, value: true });
}

export function unlock({ commit }: AC, name: string) {
  commit(types.SET_LOCK, { name, value: false });
}

export function toggleTabbarVisible({ commit }: AC, payload: boolean) {
  commit(types.APP_HIDE_TABBAR, payload);
}

export async function intializePlatformEnv({ commit }: AC) {
  const isMiniAppPlat = await isMiniApp();
  commit(types.APP_SET_PLATFORM_ENV, {
    isMiniApp: isMiniAppPlat,
  });
}

/**
 * 初始化 APP 的一些数据.
 */
export function initApp({ commit, state }: AC) {
  // checkout index.prod.html for the keys.

  const keys = [
    "cid",
    "cname",
    "clogo",
    "user",
    "sdkId",
    "switch",
    "isv2",
    "wechatId",
  ] as unknown as (keyof AppState["pageconst"])[];
  let model = state.pageconst as any;

  each(keys, (key) => {
    const value = getConfig(key);

    if (isNil(value)) {
      return;
    }

    if (isPlainObject(value)) {
      model[key] = assign({}, model[key], value);
    } else {
      model[key] = value;
    }
  });

  model["wechatSignature"] = getSignature() as string;

  commit(types.INIT_APP, {
    pageconst: model,
  });
}

export async function fetchSmsCountryCodes({ state, commit }: AC) {
  if (state.smsCountryCodes.length) {
    return state.smsCountryCodes;
  }

  const codes = await fetchSmsCountryCodes_();

  commit(types.APP_SET_SMS_COUNTRY_CODES, codes);

  return codes;
}

/**
 * LBS 功能是否开启.
 */
export async function getLbsOmsSwitch({ state, commit }: AC) {
  if (state.lbsOn === null) {
    // fetch it.
    const on = await fetchLbsOmsSwitch();
    commit(types.APP_LBS_OMS, on);
    return on;
  }

  return state.lbsOn;
}

/**
 * 某些页面需要回退到上一页面，但是history接口不能准确知道前面是否有历史记录，所以我们
 * 需要自己记录数据。
 */
export function pushAppRoute(
  { commit }: AC,
  { location, meta }: { location: Partial<QxLocation>; meta?: any },
) {
  if (typeof location !== "object") {
    throw new Error("invalid argument, expected route object");
  }

  commit(types.APP_PUSH_ROUTE, {
    ...location,
    meta: meta,
  });
}

export function popAppRoute({ commit }: AC, id: string) {
  commit(types.APP_POP_ROUTE, id);
}

export function updateAppRouteMeta(
  { commit }: AC,
  { id, meta }: { id: string; meta: any },
) {
  commit(types.APP_UPDATE_ROUTE_META, {
    id: id,
    payload: meta || {},
  });
}

export async function dialog(
  { commit }: AC,
  { type, options }: { type: "confirm" | "alert"; options: any },
) {
  return new Promise((resolve, reject) => {
    commit(types.APP_SET_DIALOG, {
      type,
      options: {
        ...options,
      },
      ts: new Date().getTime(),
      resolve: resolve,
      reject: (err?: Error) => {
        reject(err);
      },
    });
  });
}

/**
 * @example
 * ```ts
 * const isConfirm = (await dispatch("confirm")) === "confirm";
 * ```
 */
export async function confirm({ dispatch }: AC, payload: any) {
  try {
    return await dispatch("dialog", {
      type: "confirm",
      options: {
        ...payload,
      },
    });
  } catch (err) {
    return null;
  }
}

export async function alert({ dispatch }: AC, payload: any) {
  return await dispatch("dialog", {
    type: "alert",
    options: payload,
  });
}

/**
 * 解决多个弹窗同时弹出的问题.
 */
export function pushModal(
  { state, commit }: AC,
  payload: {
    run: () => void;
  },
): number {
  const modals = state.modalStore.list.slice();
  const id = state.modalStore.id + 1;

  commit(types.APP_INC_MODAL_ID);

  modals.push({
    id: id,
    run: payload.run,
  });

  commit(types.APP_SET_MODALS, modals);

  if (modals.length <= 1) {
    raf(() => {
      payload.run();
    });
  }

  return id;
}

export function popModal({ state, commit }: AC, payload: number) {
  if (typeof payload !== "number") {
    throw new Error("invalid argument");
  }

  const modals = state.modalStore.list.slice();
  const modalFound = findIndex(modals, (m) => {
    return m.id === payload;
  });

  if (modalFound === -1) {
    // eslint-disable-next-line
    console.warn && console.warn("modal not found ", payload, modals);
    return;
  }

  const next = modals[modalFound + 1];
  modals.splice(modalFound, 1);

  if (next) {
    next.run();
  }

  // update modals
  commit(types.APP_SET_MODALS, modals);
}

export async function getAiSwitch({ state, commit }: AC) {
  if (typeof state.switchs.aiRecom === "boolean") {
    return state.switchs.aiRecom;
  }

  const value = await fetchAiSwitch();
  commit(types.APP_SET_SWITCH, {
    name: "aiRecom",
    value,
  });
}

export async function getPosterSwitch({ state, commit }: AC) {
  if (typeof state.switchs.poster === "boolean") {
    return state.switchs.poster;
  }
  const value = await getPosterSwitch_();
  commit(types.APP_SET_SWITCH, {
    name: "poster",
    value,
  });
}

export async function getRadarSwitch({ state, commit }: AC) {
  if (typeof state.switchs.radar === "boolean") {
    return state.switchs.radar;
  }
  const value = await isRadarEnableValue();
  commit(types.APP_SET_SWITCH, {
    name: "radar",
    value,
  });
}

export async function getChatSwitch({ state, commit }: AC) {
  if (typeof state.switchs.chat === "number") {
    return state.switchs.chat;
  }
  const value = await fetchChatSwitch();
  commit(types.APP_SET_SWITCH, {
    name: "chat",
    value,
  });
}

export async function getShowChatSwitch({ state, commit }: AC) {
  if (typeof state.switchs.showChat === "boolean") {
    return state.switchs.showChat;
  }
  const value = await fetchShowChatSwitch();
  commit(types.APP_SET_SWITCH, {
    name: "showChat",
    value,
  });
}
