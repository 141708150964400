<template>
  <mobot-float-menu
    v-if="isShow"
    :left="left"
    :top="top"
    :position="position"
    :align-target="alignTarget"
    :distance="distance"
    :bottom="bottom"
    :z-index="zIndex"
    @click="onClick"
  ></mobot-float-menu>
</template>

<script>
import { fetchMobotEntrySwitch } from "../../../api/func";
import MobotFloatMenu from "./FloatMobotMenu.vue";

/**
 * 获取接口数据，判断是否展示。
 */
export default {
  components: {
    MobotFloatMenu,
  },

  props: {
    left: [String, Number],
    position: [String, Number],
    bottom: [String, Number],
    alignTarget: {
      type: String,
      default: "mobot",
    },
    top: [String, Number],
    distance: {
      type: Number,
      default: 10,
    },
    zIndex: [String, Number],
  },

  data() {
    return {
      isShow: false,
      mobotUrl: "",
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      const data = await fetchMobotEntrySwitch();
      this.isShow = data.mobot_multi_page_switch;
      this.mobotUrl = data.mobot_url;
    },

    onClick() {
      if (!this.mobotUrl) {
        return;
      }

      window.location.href = this.mobotUrl;
    },
  },
};
</script>
