import { TRACKING_CODE_NAME } from "./consts";

/**
 * 这里的参数会一直在页面链接上携带着，http
 * 请求可能也会一直代码，如果链接上有这些参数的话。
 */
export default [
  // 链接是否来自 ai 组的页面跳转.
  "from_ai",
  // 邀请投递来源.
  "invite_from",
  // profile编辑中需要.
  // danger, id 因为比较通用所以需要换一个名字.
  // https://git.moseeker.com/wechat/buck/merge_requests/241
  "id",
  "invite_apply",
  "psc",
  "recom",
  "root_recom", // 人脉连连看，required by panlingling@moseeker.com
  "from_template_message",
  "send_time",
  "ai_recom",
  "source", // 判断是哪个入口进入的 比如 我是员工等
  "more", // meetmobot 查看更多
  "algorithm_name",
  "user_recom_porder",
  "from_employee_ai_recom",
  "redirect_when_bind_success",
  "_vconsole",
  "next_url",
  "promote",
  "contact_referral",
  "origin",
  "forward_id",
  // 这个参数一值带着可能会有问题。
  "pid",
  "did",
  // 扫码来源
  "scan_from",

  // temp, feature/happy_new_year_20181227
  "transmit_from",
  "_qxjsfrom",
  "share_time",

  "entry_type",
  // i18n
  "lang",
  "tracking_scene",
  "jsdebug",

  TRACKING_CODE_NAME,
  "mc_poster_employee_id",

  /*人才库激活埋点字段
   *人才库激活任务ID
   */
  "talent_active_record_id",
  "app_platform",
];
