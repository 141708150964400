import { BlockKeys, GroupPk } from "../../../views/Mine/modules/model";

export default {
  core: {
    title: " ",
    cancel: "Cancel",
    notFound: "404 Page Not Found",
    confirm: "Confirm",
    optionNoLimit: "All",
    submit: "Submit",
    loading: "Loading...",
    plzWaitSeconds: "Please wait a seconds",
    loadingFailed: "Loading failed, please try again",
    noMoreItems: "No more",
    notLogin: "Not logged in",
    needLoginToContinue: "Need login before continue",
    login: "Login",
    err_invalid_phone: "Invalid phone number",
    qrcodeHoldDown: "Hold Down To Follow",
    notice: "Notice",
    noDescription: "No description",
    noSearchItems: "No results",
    viewAll: "View All",
    companyHome: "About Us",
    shareDataError: "Refresh page to make share work",
    clickToCancel: "Click to cancel",
    personalHome: "Personal Home",
    plzClickUnderWechat: "Please click in WeChat app",
  },
  // 消息提示类的国际化.
  message: {},
  job: {
    vacancyClosed: "Vacancy closed!",
    vancancyNotExists: "Vacancy closed!",
    viewApplicationStatus: "Application Status",
    applyJob: "Apply",
    applyJobShort: "Apply",
    poster: "Poster",
    contactHr: "Contact HR",
    recomAward: "Referral",
    referral: "Referral",
    bonusReward: "Reward ￥{bonus}",
    pointReward: "Point Award",
    redpacket: "Red Packet",
    hiHrMoreJobs:
      "Forward this job to your colleagues, and ask them to help spread",
    apply_month_limit_notice:
      "You have reached the application limit in this month",
    apply_month_limit_notice_school:
      "You have reached the application limit in this month",
    apply_try_again_notice: "Please try in next month",
    apply_got_notice: "Ok",
    bulkshareMaxSelect: "Exceed the 60 maximum sharing limit",
  },
  employee: {
    tryReferLater: "Try Later",
    limitMsg: `You can upload at most {y} times in {x} days`,
    reachLimit: "You have reached the limit of upload",

    myProfile: "My Profile",
    accountSetting: "Account Setting",
    [BlockKeys.referralProgress]: "Referral Progress",
    [BlockKeys.radar]: "Talent Radar",
    [BlockKeys.referralHistory]: "Views",
    [BlockKeys.chat]: "Chat",
    [BlockKeys.poster]: "Poster",
    [BlockKeys.referralJobs]: "Referral Post",
    [BlockKeys.internalPolicy]: "Internal Recruitment Policy",
    [BlockKeys.internalEntry]: "Internal Jobs",
    referralJobsSetting: "Referral Post Setting",
    pointRewards: "Point Rewards",
    awardBonus: "Bonus",
    ladder: "Leader Board",
    referralRules: "Referral Rules",
    requestReferral: "Referral Request",

    [GroupPk.primary]: "Basic",
    [GroupPk.referral]: "Referral",
    [GroupPk.promote]: "Reward & Policy",
  },
  validations: {
    required: "{_field_} is required",
  },
  qrcode: {
    // qrcodeSubscribeCopyright_usercenter: "关注仟寻，获取内推消息通知",
    qrcodeSubscribeCopyright_usercenter:
      "Follow MoSeeker to receive referral notifications",
    qrcodeTitle_usercenter: "Follow MoSeeker to receive referral notifications",
  },
};
