/**
 * Put constants here.
 */

import { env } from "@/shared/utils/env";
import isSandbox from "@/shared/utils/isSandbox";
import codeJson from "../../code.json";

export const isDev = env.NODE_ENV === "development";

export const TRACKING_CODE_NAME = "moseeker_track_code";
export const NEXT_URL = "next_url";
export const AUTH_URL = env.VUE_APP_AUTH_URL || "";
const DAY_IN_SECONDS = 60 * 10;
export const GLOBAL_DATA_EXPIRE_SECONDS = isDev ? 10 * 1e3 : DAY_IN_SECONDS;
export const WechatIdKey = "wechat_signature";
export const WX_SDK_URL = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
export const WXWORK_SDK_URL = "https://res.wx.qq.com/open/js/jweixin-1.2.0.js";
export const UrlPrefix = isDev ? "" : "/m";
export const Code = codeJson;
export const DEF_PAGE_SIZE = 10;
export const INDEX_TAB_KEY = "m_tab";
export const JOB_MODULE = "jobModule";
export const MASS_JOB_MODULE = "massJobModule";
export const DID = "did";
export const ROUTE_MODULE = "routeModule";
export const APP_MODULE = "appModule";
export const EMPLOYEE_MODULE = "employeeModule";
export const USER_MODULE = "userModule";
export const JD_MODULE = "jdModule";
export const MAP_MODULE = "mapModule";
export const PAGE_MODULE = "pageModule";
export const PRIVACY_MODULE = "privacyModule";
export const COMMENT_MODULE = "commentModule";
export const SELECTED_KEY = "selected$";
export const SHARE_ID = "share_id";
export const KEYWORD = "keyword";
export const SALARY = "salary";
/**
 * 高级搜索中，团队的字段名。
 * @see https://www.tapd.cn/43037250/prong/stories/view/1143037250001019666
 */
export const TEAM_NAME = "team_name";
/**
 * team_name 是之前用的字段，用来表示团队的值(纯文本值，比如团队的具体名称)
 * 目前团队已经不和高级搜索联动了，需要分级获取数据选择，所以我们单独进行处理
 * 它的值。
 */
export const TEAM_NAME_ID = "team_name_id";
export const LANGS = {
  ZH: "zh",
  ZH_CN: "zh_cn",
  EN: "en",
};

/**
 * Trans enum for current app.
 */
export const TRANS_METHOD = {
  WALK: 3,
  DRIVE: 1,
};

/**
 * 如果链接上带有此参数，则读取 session storage 里的 勾选职位，批量分享.
 * 同时用于 session storage key.
 */
export const SELECT_KEEP_KEY = "_jdskk";
/**
 * 触发10分钟消息模板，分享链接中要不断update这个值为当前的timestamp.
 */
export const JOB_10M_TS_KEY = "share_time";

export const DEF_MAP_RADIUS = 1000;
export const NIL_COORD_VALUE = 0;
export enum SDK_TYPES {
  WECHAT = "wechat",
  WXWORK = "wxwork",
  /**
   * joywok
   */
  JW = "jw",
  MINIAPP = "miniapp",
}
export enum SDK_ID {
  WECHAT = 1,
  WXWORK = 4,
  JOYWOK = 3,
  MINIAPP = 5,
}
export const URLS = {
  NotFound: "/page/404",
  POSITION: "/position",
  COMPANY: "/company",
  LBS: "/lbs/position",
  INTELL_RECOM: "/recommend/position",
  VERIFICATION: "/employee/bind",
  PROFILE: "/profile",
  CHAT_URL: "/chat",
  ACCOUNT_SETTING: "/app/usercenter/config",
  /**
   * 人脉雷达
   */
  RADAR: "/employee/referral/radar",
  /**
   * 内推浏览
   */
  REFERRAL_VIEW: "/employee/referral/radar_cards/job_view",
  /**
   * 积分商城
   */
  POINTS_MALL_INDEX: "/employee/mall/index",
  /**
   * 积分榜单.
   */
  LADDER: "/employee/ladder",
  /**
   * 奖金红包
   */
  AWARD_BONUS: "/employee/reward",
  /**
   * 内推政策
   */
  REFERRAL_POLICY: "/employee/referral/policy",
  /**
   * 内部招聘政策.
   */
  INTERNAL_POLICY: "/employee/internal/policy",
  INTERNAL_JOB: "/position/page/internal",
  OLD_INTERNAL_JOB: "/position/internal",
};

/**
 * 手机验证码的长度.
 */
export const PHONE_VCODE_LENGTH = 4;

/**
 * 仟寻引流页面.
 * TODO: 放到前端公共库里面，因为各个项目会共用，避免值冲突。
 */
export const QX_TRAFFIC_STR_CODE = {
  /**
   * 投递完成后 + 申请进度详情页
   */
  QRCODE_APPLICATION_STATUS_DETAIL: "17",
  /**
   * 申请记录列表页
   */
  QRCODE_APPLICATION_RECORD_LIST: "18",
  /**
   * 职位收藏列表页
   */
  QRCODE_POSITION_FAV_LIST: "19",
  /**
   *  POWER_BY_MOSEEKER
   */
  QRCODE_POWER_BY_MOSEEKER: "20",
  /**
   *  员工个人中心
   */
  QRCODE_USERCENTER: "7",
  /**
   *  高级搜索
   */
  QRCODE_ADVANCED_SEARCH: "21",
  /**
   * 侧边
   */
  QRCODE_SIDEBAR: "15",
  QRCODE_JOB_SEARCH_PAGE: 8,
};

export const QX_WECHAT_ID = isSandbox() ? 276 : 42;
