import { HttpClient } from "@/shared/types/http.t";

/*
  import addXsrfInterceptor from "./addXsrfInterceptor";
  // in func `configHttpInterceptor`:
  addXsrfInterceptor(client);
*/

import parseResponseInterceptor from "./parseResponseInterceptor";
import responseErrorInterceptor from "./responseErrorInterceptor";
import configRequestInterceptor from "./configRequestInterceptor";
import requireAuthInterceptor from "./requireAuthInterceptor";

// please handle your interceptor here.
export default function configHttpInterceptor(client: HttpClient): HttpClient {
  parseResponseInterceptor(client);
  responseErrorInterceptor(client);
  configRequestInterceptor(client);
  requireAuthInterceptor(client);
  return client;
}
