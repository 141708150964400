<template>
  <div id="app" :class="[{ 'is-no-seal': isHideMoseekerSeal }]">
    <transition :name="transitionName" @after-enter="onTransitionEnd">
      <keep-alive :include="/Cache$/">
        <router-view />
      </keep-alive>
    </transition>
    <app-tabbar
      v-if="tabbarVisible && isMounted_"
      v-float-menu="'mobot'"
      :active="activeTabbar"
      @change="onTabChange"
    />
    <portal-target name="global" multiple></portal-target>
    <!-- 不记得写这个 van-dialog 是做什么的了，感觉是没用的。 -->
    <van-dialog />
    <privacy-agreement-popup />
  </div>
</template>

<script>
import { sdk } from "@/shared/drivers/sdkDriver";
import { initLocale } from "@/shared/i18n/init";
import isHideMoseekerSeal from "@/shared/modules/custom-services/isHideMoseekerSeal";
import isWeixin from "@/shared/utils/isWeixin";
import Dialog from "@/views/common/components/Dialog";
import { mapActions, mapState } from "vuex";
import { APP_MODULE, ROUTE_MODULE, USER_MODULE } from "./shared/utils/consts";
import imageUrl from "./shared/utils/imageUrl";
import url from "./shared/utils/url";
// import { setupContext, setupSentryUserId } from "./shared/vue/setupSentry";
import { flushNextPageTick } from "@/shared/utils/nextPageTick";
import sentry from "@/shared/utils/sentry";

export default {
  name: "App",
  components: {
    AppTabbar: () =>
      import(
        /* webpackChunkName: 'AppBar' */ "@/views/common/components/AppTabbar.vue"
      ),
    VanDialog: Dialog,
    PrivacyAgreementPopup: () =>
      import(
        /* webpackChunkName: 'privacy-agreement-popup' */ "@/views/common/components/PrivacyAgreementPopup.vue"
      ),
  },

  provide: {
    imageUrl(url) {
      return imageUrl(url);
    },
    urlProvider(u) {
      const v = url(u);
      return v;
    },
    enableImgPreview() {
      return true;
    },
  },

  computed: {
    tabbarVisible() {
      const { meta } = this.$route;
      return meta && !!meta.tabbar && !this.isTabbarHide;
    },
    activeTabbar() {
      return (this.$route.name || "").split(".")[0];
    },
    ...mapState(APP_MODULE, ["isTabbarHide", "isMiniApp"]),
    ...mapState(ROUTE_MODULE, {
      did: (state) => state.query.did,
    }),
    ...mapState(USER_MODULE, {
      userId: (state) => state.account.id,
    }),
  },

  // eslint-disable-next-line vue/order-in-components
  data() {
    return {
      transitionName: "app-slide-left",
      isMounted_: false,
      isHideMoseekerSeal: isHideMoseekerSeal(),
    };
  },

  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName =
        toDepth < fromDepth ? "app-slide-right" : "app-slide-left";
    },

    did(newval, old) {
      if (newval === old) {
        return;
      }

      this.fetchCompanyInfoById(newval);
    },
  },

  metaInfo() {
    const isMiniApp = this.isMiniApp;

    return {
      title: this.$t("core.title"),
      titleTemplate: "%s",
      bodyAttrs: {
        class: isMiniApp ? "is-platform-miniapp" : "",
      },
    };
  },

  async created() {
    this.intializePlatformEnv();
    initLocale(this.$i18n);
    // init qxjs locale.
    this.$qxjs.lang.current = this.$i18n.locale;
    this.initApp();
  },

  mounted() {
    // setupSentryUserId(this.$store.state);
    // setupContext("appConfig", window.app);

    const isInWeixin = isWeixin();
    const tagId = this.$ga("hook:getTagId");
    this.$ga("config", tagId, {
      user_id: this.userId,
    });
    this.$ga("hook:init");

    sdk.events.$on("vuewx/directive/share", (result = {}) => {
      if (result && result.phase === "reject" && isInWeixin) {
        sentry.captureException(new Error("share fail"));

        this.$ga("event", "share_fail", {
          event_category: "share",
          event_label: "share",
          value: 1,
        });
      }
    });
    sdk.events.$on("vuewx/directive/share-data-fail", (err) => {
      isInWeixin &&
        this.$toast.fail({
          duration: 4000,
          message: this.$t("core.shareDataError"),
          closeOnClick: true,
          closeOnClickOverlay: true,
        });

      // eslint-disable-next-line
      console.error && console.error(err);

      isInWeixin && sentry.captureException(new Error("share data fail"));

      this.$ga("event", "share_fail", {
        event_category: "share",
        event_label: "data",
        value: 2,
      });
    });

    sdk.events.$on("vuewx/directive/share-info-fail", () => {
      if (isInWeixin) {
        sentry.captureException(new Error("share info fail"));
        this.$ga("event", "share_fail", {
          event_category: "share",
          event_label: "info",
          value: 3,
        });
      }
    });

    const loadingEl = document.getElementById("page-loading");
    if (loadingEl) {
      loadingEl.style.display = "none";
    }
    this.isMounted_ = true;
  },

  methods: {
    ...mapActions(APP_MODULE, ["initApp", "intializePlatformEnv"]),
    ...mapActions(USER_MODULE, ["fetchCompanyInfoById"]),
    // ...mapActions(PRIVACY_MODULE, ["getPrivacy"]),

    onTabChange(tab) {
      if (tab.href) {
        return (window.location.href = tab.href);
      }

      this.$router.push(
        this.$location({
          name: tab.route || tab.key,
        }),
      );
    },

    onTransitionEnd() {
      flushNextPageTick();
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/mixins";

#app {
  // landing 页面依赖这个。
  height: 100%;

  &.is-no-seal {
    .MoseekerSeal,
    .AppLayout__seal {
      display: none;
    }
  }
}

.app-slide-left-enter-active,
.app-slide-left-leave-active,
.app-slide-right-enter-active,
.app-slide-right-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  top: 0;
}

.app-slide-left-enter,
.app-slide-right-leave-active {
  opacity: 0;
  transform: translateX(2em);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.app-slide-left-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.app-slide-left-leave-active,
.app-slide-right-enter {
  opacity: 0;
  transform: translateX(-2em);
}

/**
 * It seems there is no solution for android.
 */
@include safe-area-inset-bottom {
  body {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
</style>
