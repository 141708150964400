/**
 * 对 sentry 的封装，这样我们在后面如果要去处 sentry 或者暂定 sentry，
 * 只要改动这里就可以了。
 */
// import * as Sentry from "@sentry/vue";

const Sentry = {} as {
  [key: string]: any;
};

/**
 * 是否禁用。
 */
const disabled = true;

//#region callSentry
export function callSentry<
  T extends keyof typeof Sentry,
  F extends typeof Sentry[T] & Function,
>(methodName: T, ...args: any) {
  if (disabled) {
    return;
  }

  if (typeof Sentry[methodName] !== "function") {
    return;
  }

  const fn = Sentry[methodName] as F;

  return fn.apply(Sentry, args);
}
//#endregion

export default {
  captureException(err: Error) {
    return callSentry("captureException", err);
  },

  captureMessage(msg: string, level?: "debug" | "info") {
    return callSentry("captureMessage", msg, level);
  },
};
