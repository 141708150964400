import { sdk } from "@/shared/drivers/sdkDriver";
import http, { unwrap } from "@/shared/http";
import { cross } from "@/shared/utils/url";
import { AppSignData, WxShareData } from "../shared/models/app";
import { LooseObject } from "../shared/types/value.t";
import { SDK_ID } from "../shared/utils/consts";

const API_SHARE_INFO = (scene: number | string) =>
  cross(`/api/share/info/${scene}`);
const API_WX_CONFIG = cross("/api/config");
const API_OTHER_SDK_CONFIG = cross("/api/config/env");

/**
 * @example
 *
 * ```ts
 * import { WxSignData } from "../shared/models/app";
 * const signData = await getSdkSignData<WxSignData>({}, SDK_ID.WECHAT);
 * ```
 */
export async function getSdkSignData<T = LooseObject>(
  args: any,
  sdkId: number | string,
) {
  const API_URL =
    sdkId == SDK_ID.WECHAT || sdkId == SDK_ID.MINIAPP
      ? API_WX_CONFIG
      : API_OTHER_SDK_CONFIG;

  const serverData = await unwrap<AppSignData<T>>(
    http.get(API_URL, {
      params: {
        ...args,
        client_env: sdkId,
        _ts: new Date().getTime(),
      },
    }),
  );

  const signData = {
    ...serverData.data,
    openTagList: ["wx-open-launch-weapp"],
    debug: false,
  };

  return signData;
}

/**
 * 获取分享文案.
 */
export async function getShareInfo(scene: number | string, args?: any) {
  try {
    const serverData = await unwrap<WxShareData>(
      http.get(API_SHARE_INFO(scene), {
        params: {
          ...args,
          _ts: new Date().getTime(),
        },
      }),
    );

    const data = serverData.data;
    return data;
  } catch (err) {
    sdk.events.$emit("vuewx/directive/share-info-fail");
    return null;
  }
}
