import getConfig from "@/shared/utils/getConfig";
import getQuery from "@/shared/lib/url/getQuery";
import lang from "@/shared/lib/utils/lang";
import { isValidLocale } from "../i18n";
import VueI18n from "vue-i18n";

// let _inited = false;
let _locale = null as null | string;

function getLocale() {
  let locale = getConfig("lang");
  if (locale) {
    locale = lang.getLocalePrefix(locale);
  }

  if (!locale) {
    locale = lang.getLocale();
  }

  // get from query
  // 首先判断使用权限 - 是否开启(购买)英文版？未开启，则强制使用中文版；
  // 如已购买开启，链接lang字段优先级 > 自动检测环境
  const langFeat = getConfig("langFeat") == 0 ? false : true;
  let localeFromQuery = getQuery(window.location.search, "lang") as string;
  if (isValidLocale(localeFromQuery) && langFeat) {
    return localeFromQuery;
  }

  return locale;
}

export function initLocale(i18n: VueI18n) {
  let locale = _locale;
  if (!locale) {
    locale = getLocale();
    _locale = locale;
  }

  i18n.locale = locale as string;
}
