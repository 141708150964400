// These window.navigator contain language information
// 1. languages -> Array of preferred languages (eg ["en-US", "zh-CN", "ja-JP"]) Firefox^32, Chrome^32
// 2. language  -> Preferred language as String (eg "en-US") Firefox^5, IE^11, Safari,
//                 Chrome sends Browser UI language
// 3. browserLanguage -> UI Language of IE
// 4. userLanguage    -> Language of Windows Regional Options
// 5. systemLanguage  -> UI Language of Windows

let browserLangKeys = [
  "languages",
  "language",
  // 'browserLanguage',
  // 'userLanguage',
  // 'systemLanguage'
];

// let availableLanguages = []
let locale = "zh"; // default
let inited = false;

export function init(navigator: any) {
  let lang = getWechatLang(navigator.userAgent || "");

  if (!lang) {
    // get lang from other place.
    lang = getLangCrossPlatform(navigator);
  }

  if (lang) {
    locale = getLocalePrefix(lang);
  }

  return locale;
}

export function getLocalePrefix(lang: string) {
  return lang.substr(0, 2);
}

export function getLangCrossPlatform(navigator: any) {
  let hasOwn = Object.prototype.hasOwnProperty;
  let key = "";
  let values = [] as string[];
  let langs = [];

  for (let i = 0; i < browserLangKeys.length; i++) {
    key = browserLangKeys[i];
    if (hasOwn.call(navigator, key)) {
      langs = navigator[key];
      if (!isArray(langs) && typeof langs !== "string") {
        continue;
      }

      if (typeof langs === "string") {
        langs = [langs];
      }

      if (!langs.length) {
        continue;
      }

      values = values.concat(langs);
    }
  }

  return langs[0];
}

function isArray(o: any) {
  return Object.prototype.toString.call(o) === "[object Array]";
}

export function getWechatLang(userAgentStr: string) {
  let regex = /\bLanguage\/(\S+)\b/;
  let match = userAgentStr.match(regex);
  if (!match || match.length <= 1) {
    return "";
  }

  return match[1];
}

const Lang = {
  setAvailable(/*values*/) {
    // availableLanguages = values
  },

  getLocale() {
    if (!inited) {
      inited = true;
      init(window.navigator);
    }

    return locale;
  },
  getLocalePrefix: getLocalePrefix,
};

export default Lang;
