import { MutationTree } from "vuex";
import { PageState } from "./model";
import types from "../mut-types";

const mutations: MutationTree<PageState> = {
  [types.PAGE_SET_LANDING_DATA](state, data) {
    state.landing = {
      ...data,
      stale: true,
    };
  },
};

export default mutations;
