/**
 * 避免 webpack 编译出多份 process.env 出来。
 */
const env = process.env;

export const isDev = env.NODE_ENV === "development";
export const isTest = env.NODE_ENV === "test";
export const isProd = env.NODE_ENV === "production";
export const isSandbox: { (): boolean; cache?: boolean } = () => {
  if (isSandbox.cache) {
    return true;
  }

  isSandbox.cache = window.location.hostname.indexOf("dqprism.com") !== -1;
  return isSandbox.cache;
};

export { env };
