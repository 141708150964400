import assign from "lodash/assign";
import filter from "lodash/filter";
import get from "lodash/get";
import { MutationTree } from "vuex";
import { QxLocation } from "../../shared/types/lib.t";
import types from "../mut-types";
import { AppState } from "./model";

const mutations: MutationTree<AppState> = {
  [types.SET_NEXT_URL](state, url) {
    state.nextUrl = url;
  },

  [types.SET_LOCK](state, { name, value }: { name: string; value: boolean }) {
    state.locks = {
      ...state.locks,
      [name]: value,
    };
  },
  [types.APP_HIDE_TABBAR](state, payload: boolean) {
    state.isTabbarHide = !!payload;
  },
  [types.INIT_APP](state, payload: Partial<AppState>) {
    state.pageconst = assign({}, state.pageconst, payload.pageconst);
  },
  [types.APP_SET_SMS_COUNTRY_CODES](state, payload) {
    state.smsCountryCodes = payload || [];
  },
  [types.APP_LBS_OMS](state, payload) {
    state.lbsOn = !!payload;
  },
  [types.APP_POP_ROUTE](state, id: string) {
    let routes = state.routeHistory.slice();

    routes = filter(routes, (r) => {
      return get(r, "meta.id") != id;
    });

    state.routeHistory = routes;
  },
  [types.APP_PUSH_ROUTE](state, payload: QxLocation & { meta?: any }) {
    let routes = state.routeHistory.slice();

    // 限制长度.
    if (routes.length >= 10) {
      routes.shift();
    }

    // 如果之前 push 过的，去除记录.
    routes = filter(routes, (r) => {
      return get(r, "meta.id") != get(payload, "meta.id");
    });

    routes.push({
      ...payload,
      meta: payload.meta || {},
    });
    state.routeHistory = routes;
  },
  [types.APP_UPDATE_ROUTE_META](state, payload: { id: string; payload: any }) {
    // find the route first.
    const routes = state.routeHistory || [];
    let route;
    // 从后向前开始查找.
    for (let i = routes.length; i--; i >= 0) {
      route = routes[i];
      if (get(route, "meta.id") === payload.id) {
        break;
      }
    }

    if (route) {
      route.meta = {
        ...route.meta,
        ...payload.payload,
      };
    }
  },
  [types.APP_SET_DIALOG](state, payload) {
    const { type, options, ts, resolve, reject } = payload;

    state.dialog.type = type;
    state.dialog.ts = ts;
    state.dialog.options = options;
    state.dialog.resolve = resolve || null;
    state.dialog.reject = reject || null;
  },
  [types.APP_SET_MODALS](state, payload) {
    state.modalStore.list = payload || [];
  },
  [types.APP_INC_MODAL_ID](state) {
    state.modalStore.id += 1;
  },
  [types.APP_SET_SWITCH](
    state,
    payload: { name: keyof AppState["switchs"]; value: boolean | number },
  ) {
    // 因为switchs的值包含多种类型，而我们这里
    // 动态的设置，相当于有一定的风险，会把错误的值类型赋值给某个字段
    // 所以 ts 会接受不了。
    const { value, name } = payload;

    if (name === "chat") {
      state.switchs.chat = value as number;
    } else {
      state.switchs[name] = value as boolean;
    }
  },
  [types.APP_SET_PLATFORM_ENV](
    state,
    payload: {
      isMiniApp: boolean;
    },
  ) {
    state.isMiniApp = payload.isMiniApp;
  },
};

export default mutations;
