import isEmptyString from "@dqjs/libcore/lib/isEmptyString";
import { LooseObject } from "../../types/value.t";

export const SALARY = "salary";

export enum SALARY_TYPES {
  hour = "hour",
  month = "month",
  year = "year",
}

export const SALARY_TOKENS = {
  month: "M",
  hour: "H",
  year: "Y",
};

export function salaryFilter(args: LooseObject = {}) {
  if (!args[SALARY]) {
    return args;
  }

  let value = getSalaryWithDefaultType(args[SALARY]);
  args[SALARY] = value;

  return args;
}

export function getSalaryWithDefaultType(optionValue: string) {
  const DEF = `-${SALARY_TOKENS.month}`;
  const type = getSalaryTypeInner(optionValue);
  if (type) {
    return optionValue;
  }

  if (isEmptyString(optionValue)) {
    return "";
  }

  // default to month type.
  return `${optionValue}${DEF}`;
}

export function getSalaryType(optionValue: string): SALARY_TYPES {
  const DEF = SALARY_TYPES.month;

  const type = getSalaryTypeInner(optionValue);

  if (!type) {
    return DEF;
  }

  return type;
}

// '20k-30k-M'.
export function getSalaryTypeInner(optionValue?: string): SALARY_TYPES | null {
  const DEF = null;
  // default case.
  if (typeof optionValue !== "string") {
    return DEF;
  }

  const re = /-([MHY])$/;
  const matchs = optionValue.match(re);

  if (!matchs || matchs.length <= 1) {
    return DEF;
  }

  const token = matchs[1];

  if (token === SALARY_TOKENS.month) {
    return SALARY_TYPES.month;
  } else if (token === SALARY_TOKENS.hour) {
    return SALARY_TYPES.hour;
  } else if (token === SALARY_TOKENS.year) {
    return SALARY_TYPES.year;
  } else {
    return DEF;
  }
}
