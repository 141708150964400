import Vue from "vue";
import VueI18n from "vue-i18n";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";
import maxLength from "@/shared/validators/maxLength";

export default function (i18n: VueI18n) {
  // add global required validator.
  extend("required", required);
  // extend("email", email);
  // extend("confirmed", confirmed);
  // extend("min", min);
  extend("maxLength", maxLength);

  // init locale for vee-validate.
  configure({
    defaultMessage: (_, values) => {
      const v = i18n.t(`validations.${values?._rule_}`, values) as string;
      return v;
    },
  });

  Vue.component("ValidationProvider", ValidationProvider);
  Vue.component("ValidationObserver", ValidationObserver);
}
