import findIndex from "lodash/findIndex";
import isArray from "lodash/isArray";
import union from "lodash/union";
import { AdvanceSearchSeqValueItem } from "../../models/job";
import { LooseObject } from "../../types/value.t";
import { SALARY } from "../../utils/consts";
import { getSalaryWithDefaultType } from "../../utils/salary/SalaryOptionUtil";
import NIL_VALUE from "./nilValue";

export interface DynamicFormFieldItem {
  en: string;
  text: string;
  value: string;
}

/**
 * 一些需要计算进来的参数.
 */
// export const whitelist = [
//   SALARY,
//   "degree",
//   /**
//    * 社招类型.
//    */
//   "candidate_source",
//   "employment_type",
//   "city",
//   "team_name",
//   "occupation",
//   /**
//    * 自定义字段
//    */
//   "custom",
//   /**
//    * 子公司
//    */
//   "did",
// ];

export interface MatchArg {
  row: AdvanceSearchSeqValueItem[];
  selectedArray: (null | string)[];
  index: number;
  keyArrayMap: string[];
  valueGetter: (item?: AdvanceSearchSeqValueItem) => string;
}

export interface ModuleEntryFuncArg {
  /**
   * 子公司id.
   */
  did: string | null;
  selectKeep: "1" | "0";
}

/**
 * 判断链接上的薪资是不是存在数据中，不存在则返回空.
 */
export function getExistSalary(
  value: string,
  values: any[],
  keyArray: string[],
  salaryKey: string,
): string | null {
  const salaryIndex = keyArray.indexOf(salaryKey);
  if (salaryIndex === -1) {
    return null;
  }

  const found = findIndex(values, (valueItem) => {
    return valueItem[salaryIndex].value === value;
  });

  if (found === -1) {
    return null;
  }

  return value;
}

/**
 * 从链接上的query收集高级搜索的选项值.
 */
export function collectValueFromQuery(
  keys: string[],
  query: LooseObject,
  whitelist: string[],
): LooseObject {
  let values: LooseObject = {};
  let hasOwn = Object.prototype.hasOwnProperty;
  let key = "";
  let qvalue = null;

  /**
   * 将 whitelist 添加进来，因为这些参数都必须计算进来，如果链接上有的话.
   */
  keys = union(keys, whitelist);

  for (let i = 0; i < keys.length; i++) {
    key = keys[i];
    qvalue = query[key];

    if (isArray(qvalue)) {
      qvalue = qvalue[0];
    }

    if (hasOwn.call(query, key)) {
      // 兼容以前的链接，如果 salary 没带 -{TYPE} 标识字符，则默认是月薪.
      if (key === SALARY) {
        values[key] = getSalaryWithDefaultType(qvalue || "");
      } else {
        values[key] = qvalue;
      }
    } else {
      values[key] = NIL_VALUE;
    }
  }

  return values;
}
