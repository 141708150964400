import Vue from "vue";
import Cell from "./cell";
import CellGroup from "./cell-group";
import Dialog from "./dialog";
import Divider from "./divider";
import { Grid, GridItem } from "./grid";
import Picker from "./picker";
import Popup from "./popup";
import Radio from "./radio";
import Sticky from "./sticky";
import { Swipe, SwipeItem } from "./swipe";
import Tab from "./tab";
import Tabs from "./tabs";
import Toast from "./toast";
import Tree from "./tree";
import { Checkbox, CheckboxGroup } from "./checkbox";

type V = typeof Vue;

/**
 * TODO: 将样式文件放到一个文件里面。
 * 否则开发环境下样式顺序有问题。
 */
export const components: ([string, V] | V)[] = [
  ["VanTreeSelect", Tree],
  ["VanSwipe", Swipe],
  ["VanSwipeItem", SwipeItem],
  ["VanPicker", Picker],
  ["VanPopup", Popup],
  ["VanRadio", Radio],
  ["VanCell", Cell],
  ["VanCellGroup", CellGroup],
  ["VanDivider", Divider],
  ["VanTabs", Tabs],
  ["VanTab", Tab],
  ["VanGrid", Grid],
  ["VanGridItem", GridItem],
  ["VanCheckbox", Checkbox],
  ["VanCheckboxGroup", CheckboxGroup],
];

export const uses = [Toast, Dialog, Sticky];
