export enum IM_SWITCH {
  ALL_OFF = 0,
  // HR IM.
  HR_CHAT = 1,
  /**
   * 内推 IM.
   */
  REFER_CHAT = 2,
}

export function isChatSwitchOn(onValue: number, funcValue: IM_SWITCH) {
  return (funcValue & onValue) === funcValue;
}

export function isChatSwitchOff(onValue: number, funcValue: IM_SWITCH) {
  return !isChatSwitchOn(onValue, funcValue);
}

export function isReferChatOn(onValue: number) {
  return isChatSwitchOn(onValue, IM_SWITCH.REFER_CHAT);
}

export function isHrChatOn(onValue: number) {
  return isChatSwitchOn(onValue, IM_SWITCH.HR_CHAT);
}
