/**
 * https://github.com/championswimmer/vuex-persist
 * 用于同步 vuex 的一些字段到本地 localStorage。
 */
import VuexPersistence from "vuex-persist";
import State from "../State";

/**
 * 你需要指明哪些具体的字段可以缓存到本地,不要缓存所有state.
 */
const reducer = (state: State) => {
  return {
    appModule: {
      nextUrl: state.appModule.nextUrl,
      routeHistory: state.appModule.routeHistory,
    },
    userModule: {
      lastGlobalUpdate: state.userModule.lastGlobalUpdate,
    },
    jobModule: {
      bulkshare: {
        shareIds: state.jobModule.bulkshare.shareIds,
      },
    },
    privacyModule: {
      lastTs: state.privacyModule.lastTs,
    },
    employeeModule: {
      missingInfo: state.employeeModule.missingInfo,
    },
  };
};

const vuexLocal = new VuexPersistence<State>({
  // 线上很多用户依赖这个key，不要轻易修改.
  key: "_wxv3_vuex",
  reducer,
});

export default vuexLocal;
