import http, { unwrap } from "@/shared/http";
import { cross } from "@/shared/utils/url";
import { IM_SWITCH } from "../shared/models/chat";

const API_CHAT_IM_SWITCH = cross("/api/chatting/im-switch");
const API_CHAT_SWITCH = cross("/chatting/switch");
const API_CHAT_UNREAD_ALL = cross("/api/chatting/totalunread");

export async function fetchChatSwitch() {
  try {
    const sd = await unwrap<{ on: IM_SWITCH }>(http.get(API_CHAT_IM_SWITCH));
    return sd.data.on;
  } catch (Err) {
    return IM_SWITCH.ALL_OFF;
  }
}
/**
 *
 * 获取展示聊天功能开关
 */
export async function fetchShowChatSwitch() {
  try {
    const sd = await unwrap<{ on: IM_SWITCH }>(http.get(API_CHAT_SWITCH));
    return sd.data;
  } catch (Err) {
    return true;
  }
}

export async function getChatUnreadAll() {
  try {
    const sd = await unwrap<{ unread: number }>(http.get(API_CHAT_UNREAD_ALL));
    return sd.data.unread || 0;
  } catch (err) {
    return 0;
  }
}
