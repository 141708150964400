import { GetterTree } from "vuex";
import State from "../State";
import { PrivacyState, PrivacyVisibleMode, PRIVACY_AGREED_VAL } from "./model";

/**
 * 协议数据有两个地方会用到。
 *
 * 1. 某些页面上的勾选框。（主要是仟寻协议）
 * 2. 进入页面就需要展示的协议。（仟寻协议和自定义协议）
 */
const getters: GetterTree<PrivacyState, State> = {
  isQxPrivacyChecked(state: PrivacyState) {
    return state.privacyData.qx_privacy == PRIVACY_AGREED_VAL;
  },

  isCustomPrivacyChecked(state: PrivacyState) {
    const cp = state.privacyData.custom_privacy;
    if (!cp.title && !cp.etitle && !cp.content && !cp.econtent) {
      // no custom privacy.
      return true;
    }

    return cp.agree == 1;
  },

  isShowCustomPrivacyOnly(state: PrivacyState) {
    const cp = state.privacyData.custom_privacy;
    return cp.custom_only === 1 || cp.custom_only === true;
  },

  privacyMode(state: PrivacyState, getters) {
    if (state.pendingMode) {
      return state.pendingMode;
    }

    if (getters.isCustomPrivacyChecked && !getters.isShowCustomPrivacyOnly) {
      return PrivacyVisibleMode.QX_ONLY;
    }
    if (getters.isShowCustomPrivacyOnly || getters.isQxPrivacyChecked) {
      return PrivacyVisibleMode.CUSTOM_ONLY;
    }
    return PrivacyVisibleMode.ALL;
  },
};

export default getters;
