/**
 * 一些页面是不登录也可以访问的。
 *
 * 假如某个路由不要求登录，那么在该路由下的所有 http 请求都应该忽略后端
 * 的需要登录的请求。因为如果不忽略，就会进行登录跳转。
 */
import { HttpClient } from "@/shared/types/http.t";
import { AxiosRequestConfig } from "axios";
import { isRouteNeedAuth } from "../../../router/utils";
import store from "../../../store";

function configRequestInterceptor(
  config: AxiosRequestConfig & {
    requireAuth?: boolean;
  },
): AxiosRequestConfig {
  const needAuth = isRouteNeedAuth(store.state.routeModule);
  config.requireAuth =
    typeof config.requireAuth === "boolean" ? config.requireAuth : needAuth;
  return config;
}

export default function interceptor(client: HttpClient) {
  client.interceptors.request.use(configRequestInterceptor);
}
