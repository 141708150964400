import { Module } from "vuex";
import State from "../State";
import * as actions from "./actions";
import { MapState } from "./model";
import mutations from "./mutations";

const mapModule: Module<MapState, State> = {
  namespaced: true,

  state: {
    /**
     * 当前选择的某个地点，可能是用户的真实地址。
     * 比如 选择城市，用户可能默认选择当前的地址，也可能选择其它城市。
     */
    currentLocation: {
      coord: undefined,
      address: "",
      addressComponent: {},
      adcode: undefined,
    },
    /**
     * 当前用户的真实地址。
     */
    userCurrentLocation: {
      coord: undefined,
      address: "",
      addressComponent: {},
      adcode: undefined,
    },
    isFetchingCurrentLocation: false,
    fetchLocationFail: false,
  },

  actions,
  mutations,
};

export default mapModule;
