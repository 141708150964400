export type BoolValue = boolean | number | string | undefined | null;

export default {
  t(val: BoolValue) {
    return val == 1 || val === true;
  },

  f(val: BoolValue) {
    return val == 0 || val === undefined || val === null;
  },

  zero(val: BoolValue) {
    return val === 0 || val === "0";
  },

  F: 0 as const,
  T: 1 as const,

  False: false,
  True: true,
};

// java 端可能对 false 的值处理有问题.
