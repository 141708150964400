// import addDumbQuery from "../../shared/lib/url/addDumbQuery";
import { imageMogr2 } from "@dqjs/libcore/lib/qiniu";
import { CreateElement } from "vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { LooseObject } from "../../shared/types/value.t";
import imageUrl from "../../shared/utils/imageUrl";

@Component
export default class QxImage extends Vue {
  @Prop({ type: String, default: "div" }) tag!: string;
  @Prop(String) url!: string;
  /**
   * 图片加载失败后要显示的图片.
   */
  @Prop(String) fallback!: string;
  /**
   * 是否懒渲染图片，等图片加载后再显示
   */
  @Prop(Boolean) lazy!: boolean;

  /**
   * 是否要隐藏北京，但是不隐藏元素.
   */
  @Prop(Boolean) transparent!: boolean;

  /**
   * qiniu magic query.
   * must starts with '/'.
   *
   * `/size-limit/20k/strip`.
   */
  @Prop(String) mogr2!: string;

  isMounted = false;
  lazyUrl = "";
  imgLoaded = false;
  isDestroyed = false;
  imgError = false;
  timer_ = 0;

  get safeUrl() {
    return this.getSafeUrl(this.url);
  }

  @Watch("url")
  onUrlChange(newval?: string, oldVal?: string) {
    if (newval === oldVal) {
      return;
    }
    if (this.timer_) {
      window.clearTimeout(this.timer_);
    }

    this.timer_ = window.setTimeout(() => {
      this.updateStyle(newval);
      this.timer_ = 0;
    }, 1);
  }

  beforeDestroy() {
    this.isDestroyed = true;
  }

  async updateStyle(newval?: string) {
    if (this.lazy) {
      try {
        await this.loadImage_(newval ? this.getSafeUrl(newval) : this.safeUrl);
      } catch (err) {
        // pass.
      }
    } else {
      this.lazyUrl = this.safeUrl;
      this.imgError = false;
    }
  }

  mounted() {
    this.updateStyle();
  }

  getSafeUrl(url: string) {
    if (!url) {
      return url;
    }

    const u = imageUrl(url);
    if (typeof this.mogr2 !== "string" || !this.mogr2) {
      return u;
    }
    return imageMogr2(u, this.mogr2);
  }

  async loadImage_(url: string) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(undefined);
        this.imgError = false;
        img.onload = null;
        img.onerror = null;

        if (this.isDestroyed) return;

        this.lazyUrl = url;
        this.imgLoaded = true;
      };
      img.onerror = (/* err: any */) => {
        reject(new Error("image load error"));
        this.imgError = true;
        img.onload = null;
        img.onerror = null;

        if (this.fallback) {
          this.lazyUrl = this.fallback;
          this.imgLoaded = true;
        } else {
          this.lazyUrl = url;
        }
      };

      img.src = url;
    });
  }

  render(h: CreateElement) {
    let style = {} as LooseObject;
    if (this.lazyUrl && !this.transparent) {
      style["backgroundImage"] = `url("${this.lazyUrl}")`;
    }

    return h(
      this.tag,
      {
        style,
        staticClass: "qx-image-view",
        class: {
          "is-loading": this.lazy && !this.imgLoaded,
          "is-error": this.imgError,
        },
        on: {
          click: (e: any) => {
            this.$emit("click", e);
          },
        },
      },
      this.$slots.default,
    );
  }
}
