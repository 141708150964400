import { BlockKeys, GroupPk } from "../../../views/Mine/modules/model";

export default {
  core: {
    title: " ",
    cancel: "取消",
    notFound: "404 页面不存在",
    confirm: "确认",
    optionNoLimit: "不限",
    submit: "提交",
    loading: "正在载入...",
    plzWaitSeconds: "请稍等一下",
    loadingFailed: "加载失败, 请重试",
    noMoreItems: "没有更多了",
    notLogin: "未登录",
    needLoginToContinue: "需要登录后才能继续操作，确定要去登录吗?",
    login: "登录",
    err_invalid_phone: "请正确填写您的手机号",
    qrcodeHoldDown: "关注{company_name}官方微信",
    notice: "提示",
    noDescription: "暂无介绍",
    noSearchItems: "暂无信息",
    viewAll: "查看全部",
    companyHome: "企业主页",
    /**
     * 获取分享配置等数据出错了.
     */
    shareDataError: "无法正常分享, 请刷新页面",
    clickToCancel: "点击取消",
    personalHome: "个人主页",
    plzClickUnderWechat: "请在微信内点击",
  },
  // 消息提示类的国际化.
  message: {},
  job: {
    vacancyClosed: "该职位暂不招聘",
    vancancyNotExists: "该职位暂不招聘",
    viewApplicationStatus: "查看求职进度",
    applyJob: "投递简历",
    applyJobShort: "投递",
    poster: "海报",
    contactHr: "联系HR",
    recomAward: "推荐简历",
    referral: "内推",
    bonusReward: "内推奖金{bonus}元",
    pointReward: "积分奖励",
    redpacket: "红包活动",
    hiHrMoreJobs: "您是HR, 将职位转发给同事及经理, 请他们帮助传播",
    apply_month_limit_notice: "本月您已达到该企业社招职位投递次数上限",
    apply_month_limit_notice_school: "本月您已达到该企业校招职位投递次数上限",
    apply_try_again_notice: "下个月再试试吧",
    apply_got_notice: "我知道了",
    bulkshareMaxSelect: "最多勾选60个职位进行分享",
  },
  employee: {
    limitMsg: `您在{x}天内，最多只能推荐{y}次`,
    reachLimit: `您已触达上传限制`,
    tryReferLater: "以后再推",
    myProfile: "个人档案",
    accountSetting: "账号设置",
    [BlockKeys.referralProgress]: "推荐进度",
    [BlockKeys.radar]: "人脉雷达",
    [BlockKeys.referralHistory]: "内推浏览",
    [BlockKeys.chat]: "聊天",
    [BlockKeys.poster]: "海报",
    [BlockKeys.referralJobs]: "内推职位",
    referralJobsSetting: "内推职位设置",
    pointRewards: "积分商城",
    awardBonus: "奖金红包",
    ladder: "积分榜单",
    referralRules: "内推政策",
    requestReferral: "求推荐",

    [GroupPk.primary]: "基础设置",
    [GroupPk.referral]: "我的内推",
    [GroupPk.promote]: "奖金&政策",
    [BlockKeys.internalPolicy]: "内招政策",
    [BlockKeys.internalEntry]: "内部招聘",
  },
  validations: {
    required: `{_field_}不能为空`,
  },
  qrcode: {
    qrcodeSubscribeCopyright_usercenter: "关注仟寻，获取内推消息通知",
    qrcodeTitle_usercenter: "关注仟寻，获取内推消息通知",
  },
};
