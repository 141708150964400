//
import http, { unwrap } from "@/shared/http";
import { TRANS_METHOD } from "@/shared/utils/consts";
import { mapHandle } from "@/shared/utils/tencent-map";
import { cross } from "@/shared/utils/url";
import { TransMethods } from "@dqjs/mapcore/lib/impl/tencent/consts";
import { fetchTransInfoByMethod } from "@dqjs/mapcore/lib/impl/tencent/webService";
import get from "lodash/get";
import map from "lodash/map";
import { LbsStore } from "../shared/models/lbs";
import boolcheck from "../shared/utils/boolcheck";

// const API_NEARBY_STORE = cross("/api/stores/nearby");
const API_JOB_STORES = (pid: number | string) =>
  cross(`/api/position/lbs/${pid}`);
const API_LBS_OMS = cross("/api/switch/lbs_oms");

interface LbsStoreIn extends Omit<LbsStore, "coordinates"> {
  coordinates: {
    longitude: number;
    latitude: number;
  };
}

/**
 * 获取职位关联的店铺.
 */
export async function fetchJobStores(args: {
  pid: number | string;
  lng: number;
  lat: number;
  radius: number;
}) {
  const serverData = await unwrap<{ closest_id: number; stores: LbsStoreIn[] }>(
    http.get(API_JOB_STORES(args.pid), {
      params: {
        longitude: args.lng,
        latitude: args.lat,
        radius: args.radius,
      },
    }),
  );

  const stores = map(serverData.data.stores || [], (store) => {
    return {
      ...store,
      coordinates: {
        lng: store.coordinates.longitude,
        lat: store.coordinates.latitude,
      },
    };
  });

  return serverData.reduce({
    closest_id: serverData.data.closest_id,
    stores,
  });
}

/*
 * 获取最佳交通方式
 *
 * @param {Object<Coord>} options.origin
 * @param {Object<Coord>} options.destination
 *
 * @returns {Promise<{duration: number; method: number}>}
 */
export function fetchBestTransMethod(options: {
  origin: any;
  destination: any;
}) {
  const origin = options.origin;
  const destination = options.destination;

  if (!origin || !destination) {
    throw new Error("Invalid arguments");
  }

  return new Promise((resolve, reject) => {
    Promise.all([
      fetchTransInfoByMethod(
        {
          origin,
          destination,
          type: TransMethods.Walking,
        },
        mapHandle,
      ),
      fetchTransInfoByMethod(
        {
          origin,
          destination,
          type: TransMethods.Driving,
        },
        mapHandle,
      ),
    ]).then((results) => {
      const walkRes = get(results, [0, 0]) as { duration: number };
      const driveRes = get(results, [1, 0]) as { duration: number };

      if (!walkRes || !driveRes) {
        return reject(new Error("Invalid response from WebService"));
      }

      const walkDuration = walkRes.duration;
      const driveDuration = driveRes.duration;
      let method = null as any;
      let duration = null as any;

      if (walkDuration < driveDuration) {
        method = TRANS_METHOD.WALK;
        duration = walkDuration;
      } else {
        method = TRANS_METHOD.DRIVE;
        duration = driveDuration;
      }

      return resolve({
        method,
        duration,
      });
    }, reject);
  });
}

/**
 * 是否已开启 LBS 功能.
 */
export async function fetchLbsOmsSwitch(): Promise<true | false> {
  try {
    const sd = await unwrap<1 | 0>(http.get(API_LBS_OMS));
    const data = sd.data;

    if (boolcheck.t(data)) {
      return boolcheck.True;
    }

    return boolcheck.False;
  } catch (err) {
    return false;
  }
}
