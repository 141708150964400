import getByteLen from "@dqjs/libcore/lib/getByteLen";

export default {
  name: "maxLength",
  message: (field: any, params: any) => {
    return `字数不能大于${params.max}`;
  },

  validate(value: any, { max }: any) {
    let len = getByteLen(value);
    if (!max) {
      return true;
    }

    return len <= max;
  },

  params: [
    {
      name: "max",
      cast(value: any) {
        return parseInt(value, 10);
      },
    },
  ],
};
