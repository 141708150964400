import { env } from "@/shared/utils/env";
import axios from "axios";
import {
  ApiResult,
  HttpResponseData,
  ResponsePayloadData,
} from "../types/http.t";

const timeout: number = 5 * 60 * 1000;

const API_ENDPOINT = env.VUE_APP_API;

export default axios.create({
  baseURL: API_ENDPOINT,
  timeout,
  withCredentials: env.VUE_APP_LOCAL_ENV ? false : true,
});

/**
 * 像 amap（第三方的API）需要用这个。
 */
export const request = axios.create({
  timeout,
});

/**
 * 将 axios 的 data 直接返回.
 *
 * ```js
 * const serverData = unwrap(axiosResponse)
 * const data = serverData.data;
 * const message = serverData.message;
 * const status = serverData.status;
 * // or
 * const list = serverData.get('list', []);
 * ```
 *
 * @see ServerData
 */
export async function unwrap<T = ResponsePayloadData>(
  p: ApiResult<T>,
): Promise<HttpResponseData<T>> {
  const { data: serverData } = await p;
  return serverData;
}
