import router from "@/router";
import store from "@/store";
import { Route } from "vue-router";

export default function (to: Route) {
  const meta = to.meta || {};
  store.dispatch("privacyModule/runPrivacyCheck").then(null, () => {
    // If meta.privacyacl is true, it means
    // this page can not be accessed without agree to the privacy.
    if (!meta.privacyacl) {
      return;
    }

    if (to.name === "job.home") {
      return;
    }

    /**
     * 不同意则跳转到职位列表页.
     */
    const link = router.resolve({
      name: "job.home",
      query: {
        ...to.query,
      },
    });

    if (!link || !link.href) {
      return;
    }

    window.location.href = link.href;
  });
}
