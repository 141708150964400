import findIndex from "lodash/findIndex";
import { MutationTree } from "vuex";
import { JOB_MAX_SELECT } from "../../shared/models/job";
import types from "../mut-types";
import { JobState } from "./model";
import { defaultPageNo } from "./utils";

const mutations: MutationTree<JobState> = {
  [types.JOB_CHANGE_TAB_KEY](state, payload) {
    state.tabKey = payload;
  },
  [types.JOB_INC_FETCHING_KEY](state) {
    state.index.fetchingKey += 1;
  },
  [types.JOB_INDEX_RESET](state) {
    state.index.list = [];
    state.index.exhuasted = false;
    state.index.loading = false;
    state.index.pageNo = defaultPageNo;
    state.index.total = 0;
    state.index.selectable = false;
  },
  [types.JOB_SET_INDEX_LIST](state, payload) {
    state.index.list = state.index.list.concat(payload || []);
  },
  [types.JOB_SET_INDEX_EXHUASTED](state, payload) {
    state.index.exhuasted = !!payload;
  },
  [types.JOB_REFRESH_LAYOUT_TICK](state) {
    state.refreshLayoutTick += 1;
  },
  [types.JOB_SET_INDEX_LOADING](state, payload) {
    state.index.loading = !!payload;
  },
  [types.JOB_SET_INDEX_TOTAL](state, payload) {
    state.index.total = payload;
  },
  [types.JOB_INC_INDEX_PAGENO](state) {
    state.index.pageNo += 1;
  },
  [types.JOB_TOGGLE_SELECTABLE](state, payload: boolean) {
    state.index.selectable = payload;
  },
  // advance search
  [types.AS_SET_DATASOURCE](state, payload) {
    /**
     * 这里的数据非常大，使用 freeze 禁止 vue 对其进行响应式处理，提升页面性能。
     */
    state.adsearch.datasource = Object.freeze
      ? Object.freeze(payload)
      : payload;
  },
  [types.AS_SET_MODEL](state, payload) {
    state.adsearch.model = {
      ...payload,
    };
  },
  [types.AS_CHANGE_TS](state, payload) {
    state.adsearch.changeTs =
      typeof payload === "number" ? payload : new Date().getTime();
  },
  // ai recom jobs
  [types.AI_JOB_SET_LOADING](state, payload) {
    state.airecom.loading = payload;
  },
  [types.AI_JOB_SET_TOTAL](state, payload) {
    state.airecom.total = payload;
  },
  [types.AI_JOB_SET_LIST](state, payload) {
    state.airecom.list = state.airecom.list.concat(payload || []);
  },
  [types.AI_JOB_SET_EXHUASTED](state, payload) {
    state.airecom.exhuasted = payload;
  },
  [types.AI_JOB_INC_PAGENO](state) {
    state.airecom.pageNo += 1;
  },
  [types.AI_JOB_RESET](state) {
    state.airecom.list = [];
    state.airecom.exhuasted = false;
    state.airecom.loading = true;
    state.airecom.pageNo = defaultPageNo;
    state.airecom.total = 0;
  },
  // internal jobs
  [types.INT_JOB_SET_LOADING](state, payload) {
    state.internal.loading = payload;
  },
  [types.INT_JOB_SET_TOTAL](state, payload) {
    state.internal.total = payload;
  },
  [types.INT_JOB_SET_LIST](state, payload) {
    state.internal.list = state.internal.list.concat(payload || []);
  },
  [types.INT_JOB_SET_EXHUASTED](state, payload) {
    state.internal.exhuasted = payload;
  },
  [types.INT_JOB_INC_PAGENO](state) {
    state.internal.pageNo += 1;
  },
  [types.INT_JOB_RESET](state) {
    state.internal.list = [];
    state.internal.exhuasted = false;
    state.internal.loading = true;
    state.internal.pageNo = defaultPageNo;
    state.internal.total = 0;
  },
  // employee recom jobs
  [types.EMP_JOB_SET_LOADING](state, payload) {
    state.emprecom.loading = payload;
  },
  [types.EMP_JOB_SET_TOTAL](state, payload) {
    state.emprecom.total = payload;
  },
  [types.EMP_JOB_SET_LIST](state, payload) {
    state.emprecom.list = state.emprecom.list.concat(payload || []);
  },
  [types.EMP_JOB_SET_EXHUASTED](state, payload) {
    state.emprecom.exhuasted = payload;
  },
  [types.EMP_JOB_INC_PAGENO](state) {
    state.emprecom.pageNo += 1;
  },
  [types.EMP_TOGGLE_SELECTABLE](state, payload: boolean) {
    state.emprecom.selectable = payload;
  },
  [types.EMP_JOB_RESET](state) {
    state.emprecom.list = [];
    state.emprecom.exhuasted = false;
    state.emprecom.loading = false;
    state.emprecom.pageNo = defaultPageNo;
    state.emprecom.total = 0;
    state.emprecom.selectable = false;
  },
  // bulk share.
  [types.BULK_SHARE_SET_LOADING](state, payload: boolean) {
    state.bulkshare.loading = payload;
  },
  [types.BULK_SHARE_SET_EDIT](state, payload: boolean) {
    state.bulkshare.editMode = payload;
  },
  [types.BULK_SHARE_SET_LIST](state, payload) {
    state.bulkshare.list = payload || [];
  },
  [types.BULK_SHARE_SET_PIDS](state, payload) {
    state.bulkshare.pids = payload || [];
  },
  [types.BULK_SHARE_CLEAR](state) {
    state.bulkshare.pids = [];
    state.bulkshare.list = [];
  },
  [types.BULK_SHARE_TOGGLE_ITEM](state, pid) {
    if (!pid) {
      return;
    }

    const list = state.bulkshare.list.slice();
    // 请确保 pids 和 list 是同步的.
    const pids = state.bulkshare.pids.slice();
    const index = findIndex(pids, (id) => {
      return id == pid;
    });

    const has = index !== -1;
    if (!has) {
      pids.push(pid);
    } else {
      pids.splice(index, 1);
      const jobIndex = findIndex(list, (j) => {
        return j.id == pid;
      });

      if (jobIndex !== -1) {
        list.splice(jobIndex, 1);
      }

      state.bulkshare.list = list;
    }

    state.bulkshare.pids = pids;
  },
  [types.BULK_SHARE_SET_SHARE_ID](state, shareId: string) {
    const shareIds = state.bulkshare.shareIds || [];
    const foundIndex = findIndex(shareIds, (id) => {
      return id === shareId;
    });

    // already exists.
    if (foundIndex !== -1) {
      return;
    }

    if (state.bulkshare.shareIds.length > JOB_MAX_SELECT) {
      // too many, remove the first.
      state.bulkshare.shareIds.shift();
    }

    state.bulkshare.shareIds = [...state.bulkshare.shareIds, shareId];
  },
};

export default mutations;
