/// components
// import Home from "../../views/Home.vue";
/// end components

// other routes
import { Route } from "vue-router";
import jobRoutes from "./job";
import publicRoutes from "./public";
// end other routes

export default [
  {
    path: "/",
    name: "app.home",
    redirect: {
      name: "job.home",
    },
  },

  ...jobRoutes,

  {
    path: "/recommend/position",
    redirect: { name: "job.airecommend" },
  },

  {
    path: "/start",
    redirect: (to: Route) => {
      const query = to.query || {};
      const isReferral = query["is_referral"] == "1";

      if (isReferral) {
        return {
          name: "job.recom",
        };
      }

      return { name: "job.home" };
    },
    meta: {
      auth: false,
    },
  },

  {
    path: "/landing",
    component: () => import("@/views/Landing"),
    name: "landing",
    meta: {
      auth: false,
    },
  },

  {
    path: "/page/mine",
    name: "mine",
    component: () => import("@/views/Mine"),
    meta: {
      tabbar: true,
    },
  },

  {
    path: "/chat",
    name: "chat",
    component: () => import("@/views/Mine"),
    meta: {
      tabbar: true,
    },
  },

  {
    path: "/company",
    // name: "company",
    component: () => import("@/views/Company"),
    children: [
      {
        path: "",
        name: "company",
        component: () => import("@/views/Company/Company.vue"),
        meta: {
          tabbar: true,
          auth: false,
        },
      },
      {
        path: "team/:tid",
        name: "company.team",
        component: () => import("@/views/Company/Team.vue"),
        meta: {
          tabbar: false,
          auth: false,
        },
      },
      {
        path: "team",
        name: "company.teams",
        component: () => import("@/views/Company/Teams.vue"),
        meta: {
          tabbar: false,
          auth: false,
        },
      },
    ],
  },

  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/Mine"),
    meta: {
      tabbar: true,
    },
  },

  {
    path: "/qxapp/jobs",
    name: "massJobs",
    component: () =>
      import(/* webpackChunkName: "MassJobList" */ "@/views/MassJobList"),
    meta: {
      auth: false,
      isSassMode: false,
    },
  },

  ...publicRoutes,
];
