// import isArray from "lodash/isArray";
import each from "lodash/each";
import { Route, RouteConfig } from "vue-router";

/**
 * 默认所有路由需要认证,
 */
export function isRouteNeedAuth(route: Route) {
  const meta = route.meta || {};
  if (meta.auth === false) {
    return false;
  }

  return true;
}

/**
 * 是否需要用户信息，公司信息等数据。
 * 当前环境是否是在某个公众号实体下的。
 */
export function isRouteSassMode(route: Route) {
  const meta = route.meta || {};
  if (meta.isSassMode === false) {
    return false;
  }
  return true;
}

export function publicRoute(route: RouteConfig): RouteConfig {
  route.meta = route.meta || {};
  route.meta.auth = false;
  return route;
}

/**
 * 确保这个路由配置是可公开访问的.
 */
export function publicRoutes(routes: RouteConfig[]): RouteConfig[] {
  each(routes, (route) => {
    publicRoute(route);
  });

  return routes;
}
