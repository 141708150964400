import get from "lodash/get";
import { HttpClient } from "@/shared/types/http.t";
import { AxiosError, AxiosRequestConfig } from "axios";
import { Code } from "@/shared/utils/consts";
import store from "../../../store";

function responseErrorInterceptor(error: AxiosError) {
  const axiosConfig = error.config as AxiosRequestConfig & {
    requireAuth?: boolean;
  };
  const response = error.response;
  if (!response) {
    return Promise.reject(error);
  }

  const data = response.data || {};
  const status: number = data.status;

  if (status == Code.NEED_LOGIN && axiosConfig.requireAuth !== false) {
    // not login.
    store.dispatch("userModule/oauth", {
      confirm: false,
    });
    // abort promise.
    return new Promise(() => {
      // pass
    });
  } else if (status == Code.REDIRECT) {
    const nextUrl = get(data, "data.next_url");
    if (!nextUrl) {
      return Promise.reject(new Error("invalid response"));
    }
    window.location.href = nextUrl;
    return Promise.reject(new Error("loading"));
  }

  // change the message to our business error.
  if (data.message) {
    error.message = data.message;
  }

  return Promise.reject(error);
}

export default function interceptor(client: HttpClient) {
  client.interceptors.response.use(undefined, responseErrorInterceptor);
}
