import { env } from "@/shared/utils/env";
import { GoogleAnalytics } from "@dqjs/qxjs/lib/modules/ga";
import OurVue from "vue";
import { VueRouter } from "vue-router/types/router";
import withRouterBase from "../../lib/url/withRouterBase";

export default function (
  Vue: typeof OurVue,
  OurRouter: VueRouter,
  id: string,
  disabled?: boolean,
) {
  let gaInstance = null as GoogleAnalytics | null;

  Vue.use({
    install: function install(Vue: typeof OurVue) {
      if ((install as any).installed) return;
      (install as any).installed = true;

      if (OurVue !== Vue) {
        // eslint-disable-next-line
        console.error("multiple instances of Vue detected");
      }

      Vue.prototype.$ga = function (...args: any[]) {
        if (
          args &&
          typeof args[0] === "string" &&
          args[0] === "hook:getTagId"
        ) {
          return id;
        }

        if (!gaInstance) {
          gaInstance = new GoogleAnalytics({
            id: id,
            log: env.NODE_ENV === "development",
            spa: true,
            disabled: disabled,
          });
        }

        if (args && typeof args[0] === "string" && args[0] === "hook:init") {
          gaInstance.init();
          return;
        }
        return gaInstance.runSeq(...args);
      };

      OurRouter.afterEach((to) => {
        if (!gaInstance) {
          return;
        }

        /**
         * 如果路由信息里指明此路由不要自动记录pageview事件，那么就直接跳过。
         * 这类情况可能是因为页面的 title 是异步到达的。
         */
        if (to && to.meta && to.meta.ga === false) {
          return;
        }

        // 每次路由切换完后，发送 pageview 事件.
        // 因为页面标题可能是由 vue-meta 设置的，
        // 所以要用 timer 来尽量保证在 page title 设置之后才发送数据.
        setTimeout(() => {
          if (!gaInstance) {
            return;
          }
          // @see https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications#measure_virtual_pageviews
          gaInstance.runSeq("config", id, {
            page_path: withRouterBase(to.fullPath),
            page_location: window.location.href,
            page_title: document.title,
          });
        }, 800);
      });
    },
  });
}
