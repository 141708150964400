import Vue from "vue";
import VueI18n from "vue-i18n";

import messages from "./locale";
import { LANGS } from "./langs";

Vue.use(VueI18n);

export default new VueI18n({
  locale: LANGS.zh,
  fallbackLocale: LANGS.zh,
  messages,
  silentTranslationWarn: true,
});

const availableLocales = ["zh", "en"];

export function isValidLocale(lang: string) {
  return availableLocales.indexOf(lang) !== -1;
}

export function isEn(locale: string) {
  return locale === "en";
}
