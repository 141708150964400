import map from "lodash/map";
import uniqueId from "lodash/uniqueId";
import { MutationTree } from "vuex";
import mutTypes from "../mut-types";
import { EmployeeState } from "./model";

const mutations: MutationTree<EmployeeState> = {
  [mutTypes.EMPLOYEE_SET_MISSING_INFO_ITEMS](state, items) {
    state.missingInfo.items = map(items, (item) => {
      return {
        ...item,
        id: item.id || uniqueId("missingInfo_"),
      };
    });
  },
  [mutTypes.EMPLOYEE_SET_EMPLOYEE_MINE_DATA](state, payload) {
    state.employeeMineData = {
      ...state.employeeMineData,
      ...payload,
    };
  },
};

export default mutations;
