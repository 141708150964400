import keymirror from "keymirror";

export default keymirror({
  // app
  INIT_APP: null,
  APP_HIDE_TABBAR: null,
  APP_SET_SMS_COUNTRY_CODES: null,
  SET_GLOBAL: null,
  SET_COMPANY: null,
  SET_NEXT_URL: null,
  SET_LOCK: null,
  TOGGLE_PROGRESS_BAR: null,
  APP_LBS_OMS: null,
  APP_PUSH_ROUTE: null,
  APP_POP_ROUTE: null,
  /**
   * 更新路由的 meta 信息.
   */
  APP_UPDATE_ROUTE_META: null,
  APP_SET_DIALOG: null,
  APP_SET_MODALS: null,
  APP_INC_MODAL_ID: null,
  APP_SET_SWITCH: null,
  // job index.
  JOB_REFRESH_LAYOUT_TICK: null,
  JOB_CHANGE_TAB_KEY: null,
  JOB_INDEX_RESET: null,
  JOB_SET_INDEX_LIST: null,
  JOB_SET_INDEX_LOADING: null,
  JOB_SET_INDEX_EXHUASTED: null,
  JOB_SET_INDEX_TOTAL: null,
  JOB_INC_INDEX_PAGENO: null,
  JOB_INC_FETCHING_KEY: null,
  // ai recom jobs
  AI_JOB_SET_LIST: null,
  AI_JOB_SET_LOADING: null,
  AI_JOB_SET_EXHUASTED: null,
  AI_JOB_SET_TOTAL: null,
  AI_JOB_INC_PAGENO: null,
  AI_JOB_RESET: null,
  // internal jobs
  INT_JOB_SET_LIST: null,
  INT_JOB_SET_LOADING: null,
  INT_JOB_SET_EXHUASTED: null,
  INT_JOB_SET_TOTAL: null,
  INT_JOB_INC_PAGENO: null,
  INT_JOB_RESET: null,
  // employee reocm jobs
  EMP_JOB_RESET: null,
  EMP_JOB_SET_LIST: null,
  EMP_JOB_SET_LOADING: null,
  EMP_JOB_SET_EXHUASTED: null,
  EMP_JOB_SET_TOTAL: null,
  EMP_JOB_INC_PAGENO: null,
  EMP_TOGGLE_SELECTABLE: null,
  // advance search
  AS_SET_DATASOURCE: null,
  AS_SET_MODEL: null,
  AS_CHANGE_TS: null,
  // JOB_TOGGLE_ITEM_SELECT: null,
  JOB_TOGGLE_SELECTABLE: null,
  // bulk share
  BULK_SHARE_SET_LOADING: null,
  BULK_SHARE_SET_LIST: null,
  BULK_SHARE_SET_EDIT: null,
  BULK_SHARE_TOGGLE_ITEM: null,
  BULK_SHARE_SET_PIDS: null,
  BULK_SHARE_SET_SHARE_ID: null,
  BULK_SHARE_CLEAR: null,
  // map
  SET_CURRENT_LOCATION: null,
  SET_FETCHING_CURRENT_LOCATION: null,
  SET_FECTH_LOCATION_FAIL: null,
  SET_USER_CURRENT_LOCATION: null,
  // jd map
  JD_SET_CURRENT_STORE: null,
  JD_SET_CURRENT_DISTANCE: null,
  JD_SET_RADIUS: null,
  JD_SET_JOB_STORES: null,
  // privacy
  PRIVACY_SET_DATA: null,
  PRIVACY_TOGGLE_POPUP: null,
  PRIVACY_SET_LASTTS: null,
  PRIVACY_UPDATE_AGREE: null,
  PRIVACY_SET_PENDING_MODE: null,
  // page.
  PAGE_SET_LANDING_DATA: null,
  // comments
  COMMENT_SET_LIST: null,
  COMMENT_SET_LOADING: null,
  COMMENT_SET_EXHUASTED: null,
  COMMENT_SET_TOTAL: null,
  COMMENT_INC_PAGENO: null,
  COMMENT_RESET: null,
  COMMENT_UPDATE_LIST: null,
  // COMMENT_SET_MYLIST: null,
  // COMMENT_SET_MYLOADING: null,
  // COMMENT_SET_MYEXHUASTED: null,
  // COMMENT_SET_MYTOTAL: null,
  // COMMENT_INC_MYPAGENO: null,
  // MY_COMMENT_RESET: null,
  // COMMENT_UPDATE_MYLIST: null,
  // ----------------------------
  // employee
  EMPLOYEE_SET_MISSING_INFO_ITEMS: null,
  EMPLOYEE_SET_EMPLOYEE_MINE_DATA: null,

  // mass jobs
  MassJobIndexSetList: null,
  MassJobIndexReset: null,
  MassJobIndexIncPage: null,
  MassJobIndexSetExhuasted: null,
  MassJobIndexSetLoading: null,
  MassJobIndexIncFetchingKey: null,
  APP_SET_PLATFORM_ENV: null,
});
