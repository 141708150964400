import QxImage from "@/components/foundation/QxImage";
import QxPopup from "@/components/foundation/QxPopup";
import RootPortal from "@/components/foundation/RootPortal";
import {
  components as VanComponents,
  uses as VanUses,
} from "@/components/vant";
import MobotFloatMenuVe from "@/components/webflow/FloatMobotMenu/FloatMobotMenuVe.vue";
import AppLayout from "@/views/common/components/AppLayout.vue";
import MoseekerSeal from "@/views/common/components/MoseekerSeal.vue";
import FloatMenuDirective from "@dqjs/libvue2/lib/components/FloatMenu/FloatMenuDirective";
import QxBtn from "@dqjs/libvue2/lib/components/QxBtn/index";
import isArray from "lodash/isArray";
import Vue from "vue";
import { DirectiveFunction, DirectiveOptions } from "vue/types";
import { QxSvgIcon } from "@dqjs/icon-vue2";

type V = typeof Vue;

const components: ([string, V] | V)[] = [
  ["QxSvgIcon", QxSvgIcon],
  ["QxPopup", QxPopup],
  ["QxImage", QxImage],
  ["QxBtn", QxBtn],
  ["RootPortal", RootPortal],
  ["MoseekerSeal", Vue.extend(MoseekerSeal)],
  ["AppLayout", Vue.extend(AppLayout)],
  ["MobotFloatMenuVe", MobotFloatMenuVe],
  ...VanComponents,
];

const uses = [
  /**
   * this.$toast.clear()
   * this.$toast({ type: 'success' })
   * this.$toast.loading({})
   */
  ...VanUses,
];

const directives: [string, DirectiveOptions | DirectiveFunction][] = [
  ["float-menu", FloatMenuDirective],
];

export default function (InVue: typeof Vue) {
  components.forEach((item: [string, V] | V) => {
    let itemList: any[] = [];
    if (!isArray(item)) {
      itemList = [(item as V).name, item];
    } else {
      itemList = item;
    }

    const [name, Component] = itemList;
    InVue.component(name as string, Component as V);
  });

  uses.forEach((V) => InVue.use(V));

  directives.forEach((item) => {
    if (!isArray(item)) {
      throw new Error(
        "the directive item must be array of [name, directive] format",
      );
    }
    const [name, directive] = item;
    InVue.directive(name, directive);
  });
}
