import http, { unwrap } from "@/shared/http";
import { cross } from "@/shared/utils/url";
import { LandingState } from "@/store/pageModule/model";
import changeQuery from "@dqjs/libcore/lib/changeQuery";
import { ModuleDataItem } from "@dqjs/careerstory/lib/model";

const API_LANDING = cross("/api/navigation/page");
const API_COMPANY_HOME = cross(`/api/careerstory/company`);
const API_TEAM = (tid?: string) => cross(`/api/careerstory/team/${tid}`);
const API_TEAMS = cross(`/api/careerstory/team`);

export async function fetchLandingPage() {
  return unwrap<Partial<LandingState>>(http.get(API_LANDING));
}

export async function fetchCompanyHomeData(cid?: number | string) {
  return unwrap<{
    module: ModuleDataItem[];
    header: {
      banner: string;
      icon: string;
      slogan: string;
      description: string;
      name: string;
      type: "company" | "team";
      team_module_index: number;
    };
  }>(
    http.get(
      changeQuery(API_COMPANY_HOME, {
        did: cid,
      })
    )
  );
}

export async function fetchTeamDetailData(tid?: string) {
  return unwrap<{
    module: ModuleDataItem[];
    header: {
      banner: string;
      slogan: string;
      description: string;
      name: string;
      icon: string;
    };
  }>(http.get(API_TEAM(tid)));
}

export async function fetchTeamsData() {
  return unwrap<{
    module: ModuleDataItem[];
    header: {
      banner: string;
      icon: string;
    };
  }>(http.get(API_TEAMS));
}
