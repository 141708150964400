import http, { unwrap } from "@/shared/http";
import { cross } from "@/shared/utils/url";
const API_COMMENT_LIST = cross("/api/comments");
const API_COMMENTS_TAB_COUNT = cross("/api/comment/count");
const API_COMMENT_SWITCH = cross("/api/comment/switch");
const API_COMMENT_ITEM = (id?: string) => cross(`/api/comment/info/${id}`);

export async function fetchCommentSwitch() {
  return await unwrap(http.get(API_COMMENT_SWITCH));
}

export async function fetchComments(args: object) {
  return await unwrap(
    http.get(API_COMMENT_LIST, {
      params: args,
    }),
  );
}

export async function fetchCommentItem(id: string) {
  return await unwrap(http.get(API_COMMENT_ITEM(id)));
}

export async function deleteComment(args: object) {
  return await unwrap(
    http.delete(API_COMMENT_LIST, {
      params: args,
    }),
  );
}

export async function writeComment(body: object) {
  return await unwrap(http.post(API_COMMENT_LIST, body));
}

/**
 * 公司、团队、职位详情页获取精选留言总数
 */
export async function getCommentsTabCount(args: object) {
  return await unwrap(
    http.get(API_COMMENTS_TAB_COUNT, {
      params: args,
    }),
  );
}
