import { env } from "@/shared/utils/env";

/**
 * vue router 的 fullPath 不带 base，但是 ga 统计需要完整的 pathname。
 */
export default function withRouterBase(
  url: string,
  base: string | undefined = env.VUE_APP_ROUTER_BASE,
) {
  if (typeof url !== "string" || typeof base !== "string") {
    return url || "";
  }

  if (!base) {
    return url;
  }

  const baseWithTrail = base[base.length - 1] === "/" ? base : base + "/";

  if (url.indexOf(baseWithTrail) === 0) {
    return url;
  }

  return baseWithTrail + (url[0] === "/" ? url.substr(1) : url);
}
