import http, { unwrap } from "@/shared/http";
import { cross } from "@/shared/utils/url";
import assign from "lodash/assign";
import { UserState } from "../store/userModule/model";

const API_COMPANY = cross("/api/v2/company");
const API_COMPANY_TEAM = cross("/api/company/team");

export async function fetchCompany() {
  return unwrap<UserState["company"]>(http.get(API_COMPANY));
}

export async function fetchCompanyTeam() {
  return unwrap(http.get(API_COMPANY_TEAM));
}

/**
 * 获取某个公司的信息.
 */
export async function fetchCompanyInfo(id?: string | number) {
  const sd = await unwrap<UserState["company"]>(
    http.get("/api/company/info", {
      params: {
        company_id: id,
        did: id,
      },
      requireAuth: false,
    }),
  );

  const data = sd.data;
  data.parent = data.parent ? data.parent : assign({}, data);
  return sd.reduce(data);
}
