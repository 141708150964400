import TreeSelect from "vant/lib/tree-select";

import "vant/lib/tree-select/style";
import "./tree.scss";

export default TreeSelect;

export interface TreeItem {
  text: string;
  id: number | string;
  disabled?: boolean;
}

export interface TreeGroup {
  text: string;
  badge?: number;
  dot?: boolean;
  className?: string;
  children: TreeItem[];
}
