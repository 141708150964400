import { isSandbox as isSandboxCache } from "@/shared/utils/env";

/**
 * @deprecated use `{@/shared/utils/env}.isSandbox`
 *
 * 判断是不是在沙盒环境。
 */
export default function isSandbox(): boolean {
  return isSandboxCache();
}
