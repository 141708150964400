import toSafeNumber from "@dqjs/libcore/lib/toSafeNumber";
import { Module } from "vuex";
import { GLOBAL_DATA_EXPIRE_SECONDS } from "../../shared/utils/consts";
import State from "../State";
import * as actions from "./actions";
import { UserState } from "./model";
import mutations from "./mutations";

const userModule: Module<UserState, State> = {
  namespaced: true,

  state: {
    account: {
      id: 0,
      name: "",
      email: "",
      mobile_display: "",
      bind_status: 0,
      headimg: "",
    },
    company: {
      abbreviation: "",
      banner: [],
      position_banner: [],
      industry: "",
      name: "",
      logo: "",
      id: 0,
      scale_name: "",
      feature: [],
      stale: false,
      position_banner_v2: {
        type: "image",
        image: {
          url: "",
        },
        video: {
          url: "",
          type: null,
        },
      },
      parent: {
        position_banner_v2: {
          type: "image",
          image: {
            url: "",
          },
          video: {
            url: "",
            type: null,
          },
        },
        abbreviation: "",
        banner: [],
        position_banner: [],
        industry: "",
        name: "",
        logo: "",
        id: 0,
        scale_name: "",
        feature: [],
      },
    },
    wechat: {
      subscribed: false,
    },
    lastGlobalUpdate: 0,
  },

  getters: {
    hasLogin(state): boolean {
      return !!state.account.id;
    },
    isGlobalExpired(state, getters) {
      if (!getters.hasLogin) {
        return true;
      }

      const lastGlobalUpdate = toSafeNumber(state.lastGlobalUpdate) || 0;
      const now = new Date().getTime();

      return (now - lastGlobalUpdate) / 1000 >= GLOBAL_DATA_EXPIRE_SECONDS;
    },
  },

  mutations,
  actions,
};

export default userModule;
