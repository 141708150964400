import { QxMapCoord } from "@dqjs/mapcore/lib/shared";

export interface StoreItem {
  coordinates: {
    latitude: number;
    lat: number;
    lng: number;
    longitude: number;
  };
  latitude: number;
  longitude: number;
  lat?: number;
  lng?: number;
  id: number;
  logo: string;
  name: string;
  address: string;
  [key: string]: any;
}

export function storeItemToQxMapCoord(storeItem: StoreItem): QxMapCoord {
  const coords = storeItem.coordinates || {
    latitude: storeItem.latitude,
    longitude: storeItem.longitude,
  };
  const lat = coords.latitude || coords.lat;
  const lng = coords.longitude || coords.lng;

  return {
    ...storeItem,
    // make sure this is at bottom.
    lat: lat,
    lng: lng,
  };
}

export function storeItemsToQxMapCoord(storeItems: StoreItem[]): QxMapCoord[] {
  return storeItems.map(storeItemToQxMapCoord);
}
